import * as React from 'react';
import { useRef, useEffect } from "react";
import * as d3 from "d3";
import { scaleTime, scaleBand, scaleOrdinal } from "d3-scale"
import { axisBottom, axisLeft } from "d3-axis";
import { Tableau10 } from "../../utils/colours";
import { D3DragEvent, Simulation, SimulationNodeDatum, SimulationLinkDatum } from 'd3';
import color from '@material-ui/core/colors/lightBlue';


export interface NetworkGraphProps {
    data: NetworkGraphData | null;
    applianceData: any;
    height: number;
    width: number;
    location?: string;
    acsis: number;
    fallData?: any;
}

export interface NetworkGraphData {
    directed: boolean;
    multigraph: boolean;
    graph: any;
    links: NetworkGraphLink[];
    nodes: NetworkGraphNode[];
    site: number;
}

export interface NetworkGraphNode extends SimulationNodeDatum {
    id: string;
}

export interface NetworkGraphLink extends SimulationLinkDatum<NetworkGraphNode> {
    source: string;
    target: string;
}

export function NetworkGraph(props: NetworkGraphProps) {
    const d3Container = useRef(null);

    useEffect(() => {

        if (props.data && props.applianceData && d3Container.current) {

            const svg: any = d3.select(d3Container.current);

            svg.selectAll('*').remove();
            let svgWidth = 0;
            let svgHeight = 0;
            svgWidth = svg.node().getBoundingClientRect().width;
            svgHeight = svg.node().getBoundingClientRect().height;

            const margin = { left: 30, right: 30, top: 30, bottom: 30 };
            const width = svgWidth - margin.left - margin.right;
            const height = svgHeight - margin.top - margin.bottom;

            let links: NetworkGraphLink[] = props.data.links.map(d => Object.create(d));
            let nodes: NetworkGraphNode[] = props.data.nodes.map(d => Object.create(d));

            if (props.applianceData != null && props.applianceData.appliances != null)
                for (let app in props.applianceData.appliances) {
                    links.push({
                        source: props.applianceData.appliances[app].location,
                        target: app
                    });
                    nodes.push({
                        id: app
                    });
                }

            let colour = d3.scaleOrdinal()
                .domain(nodes.map(n => n.id))
                .range(Tableau10);

            const simulation = d3.forceSimulation<NetworkGraphNode>(nodes)
                .force("link", d3.forceLink(links).distance(d => {
                    console.log(nodes[(d.target as any).index].id.toUpperCase());
                    return nodes[(d.target as any).index].id.includes('|') ? (width / 10) : (width / 7)//150 : 200;
                }).id((d: any) => d.id))
                .force("charge", d3.forceManyBody().strength(-400))
                .force("center", d3.forceCenter(width / 2, height / 2));

            const dragstarted = function (event: any, d: any) {
                if (!event.active) simulation.alphaTarget(0.3).restart();
                d.fx = d.x;
                d.fy = d.y;
            }

            const dragged = function (event: any, d: any) {
                d.fx = event.x;
                d.fy = event.y;
            }

            const dragended = function (event: any, d: any) {
                if (!event.active) simulation.alphaTarget(0);
                d.fx = null;
                d.fy = null;
            }

            const link = svg.append("g")
                .attr("stroke", "#999")
                .attr("stroke-opacity", 0.6)
                .selectAll("line")
                .data(links)
                .join("line")
                .attr("stroke-width", 3);

            const node = svg.append("g")
                .selectAll("circle")
                .data(nodes)
                .join("g")
                .call(d3.drag()
                    .on("start", dragstarted)
                    .on("drag", dragged)
                    .on("end", dragended) as any);

            node.append("circle")
                .attr("stroke", "#fff")
                .attr("stroke-width", 1.5)
                .attr("r", (d: any) => d.id.includes('|') ? (width / 100) : (width / 50))// 15 : 30)
                .attr("fill", (d: any) => colour(d.id) as any);

            node.append("text")
                .classed("network-text", true)
                .attr("x", props.fallData != null ? 16: 32)
                .attr("y", "0.31em")
                .text((d: any) => d.id.split('|').length > 1 ? d.id.split('|')[1] : d.id)
                .attr("stroke", "black")
                // .attr("font-size", (d: any) => d.id.includes('|') ? (width / 70) : (width / 56));
                .attr("font-size", (d: any) => d.id.includes('|') ? '100%' : '120%');

            if (props.fallData != null) {
                node.append("text")
                    .text((d: any) => (props.fallData[d.id] != null ? props.fallData[d.id] : ""))
                    // .attr("font-size", (d: any) => 10)
                    .attr("font-size", (d: any) => '100%')
                    .attr("text-anchor", (d: any) => "middle")
                    .attr("y", '0.3em');
            }

            node.filter((d: any) => {
                if (props.acsis == 4026983 || props.location == 'Fridge') { return false;}
                console.log(props.location, d.id)
                console.log(props.location != null && ((d.id.split('|').length > 1 ? d.id.split('|')[1] : d.id) == props.location))
                return props.location != null && ((d.id.split('|').length > 1 ? d.id.split('|')[1] : d.id) == props.location)
            })
        
                .append('g').attr("transform", "translate(-8, -18) scale(0.08)").append('path').attr('fill', '#fff')
                .attr('d', "M96 0c35.346 0 64 28.654 64 64s-28.654 64-64 64-64-28.654-64-64S60.654 0 96 0m48 144h-11.36c-22.711 10.443-49.59 10.894-73.28 0H48c-26.51 0-48 21.49-48 48v136c0 13.255 10.745 24 24 24h16v136c0 13.255 10.745 24 24 24h64c13.255 0 24-10.745 24-24V352h16c13.255 0 24-10.745 24-24V192c0-26.51-21.49-48-48-48z");
                

            simulation.on("tick", () => {
                link
                    .attr("x1", (d: any) => (d.source as any).x)
                    .attr("y1", (d: any) => (d.source as any).y)
                    .attr("x2", (d: any) => (d.target as any).x)
                    .attr("y2", (d: any) => (d.target as any).y);

                node.attr("transform", (d: any) => `translate(${d.x},${d.y})`);
            });
        }

    }, [props.data, props.applianceData, props.location, d3Container.current]);

    // let viewBox = "0 0 " + props.width + " " + props.height;

    if (props.data == null) {
        return (
            <div className="network-graph-info">
                <p>Loading...</p>
            </div>
        );
    } else if (props.data.nodes == []) {
        return (
            <div className="network-graph-info">
                <p>No data found.</p>
            </div>
        );
    } else {
        return (
            <svg
                className="network-graph"
                // viewBox={`0 0 ${props.width} ${props.height}`}
                ref={d3Container}
            />
        );
    }
}

export default NetworkGraph;