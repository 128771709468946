import * as React from 'react';
import './ImageCircle.css';
import { Link } from "@reach/router";
export enum ImageCircleBorder {
    Grey,
    Green,
    Orange,
    Red
}

export interface ImageCircleProps {
    imageURL: string;
    altText: string;
    border?: ImageCircleBorder;
    acsisID?: number;
    size?: number;
}

export function ImageCircle(props: ImageCircleProps) {
    let wrapperClass = 'image-circle__wrapper';

    console.log(props.border);

    switch (props.border) {
        case ImageCircleBorder.Grey:
            wrapperClass += ' image-circle__wrapper--border-grey';
            break;
        case ImageCircleBorder.Green:
            wrapperClass += ' image-circle__wrapper--border-green';
            break;
        case ImageCircleBorder.Orange:
            wrapperClass += ' image-circle__wrapper--border-orange';
            break;
        case ImageCircleBorder.Red:
            wrapperClass += ' image-circle__wrapper--border-red';
            break;
        default:
            wrapperClass += ' image-circle__wrapper--border-grey';
            break;
    }
    if (props.acsisID != null) {
        return (
            <Link to={`/dashboard/${props.acsisID}`}>
                <div className={wrapperClass}>
                    <img className="image-circle__image" src={props.imageURL} alt={props.altText} style={props.size != null ? {width: props.size, height: props.size,} : undefined}/>
                </div>
            </Link>
    
        );
    } else {
        return (
            <div className={wrapperClass}>
                <img className="image-circle__image" src={props.imageURL} alt={props.altText} style={props.size != null ? {width: props.size, height: props.size,} : undefined}/>
            </div>
    
        );
    }
    
}
        
export default ImageCircle;
