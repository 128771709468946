import moment from "moment";
import { generateSentences } from '../sentenceGenerator';
export function fallFactGenerator(eventArray, personalInformation) {
    let basic = null;
    let urgent = null;
    let fallsInLastWeek = [0, 0];
    let fallsInLast2Weeks = [0, 0];
    let fallsInLast3Weeks = [0, 0];
    let fallsInLast4Weeks = [0, 0];
    let fallsInPreviousMonth = [0, 0];
    let fallsIn2ndPreviousMonth = [0, 0];
    eventArray.map(record => {
        let position = record.RuleID == 9002 ? 0 : 1;
        if (moment(record.StartDateTime) > moment().subtract(3, "month")) {
            fallsIn2ndPreviousMonth[position]++;
        }
        ;
        if (moment(record.StartDateTime) > moment().subtract(2, "month")) {
            fallsInPreviousMonth[position]++;
        }
        ;
        if (moment(record.StartDateTime) > moment().subtract(4, "week")) {
            fallsInLast4Weeks[position]++;
        }
        ;
        if (moment(record.StartDateTime) > moment().subtract(3, "week")) {
            fallsInLast3Weeks[position]++;
        }
        ;
        if (moment(record.StartDateTime) > moment().subtract(2, "week")) {
            fallsInLast2Weeks[position]++;
        }
        ;
        if (moment(record.StartDateTime) > moment().subtract(1, "week")) {
            fallsInLastWeek[position]++;
        }
        ;
    });
    let recentFallLongLie = fallsInLast2Weeks[1] > 0 ? true : false;
    let averageFallsPerMonth = (fallsIn2ndPreviousMonth[0] - fallsInLast2Weeks[0]) > 0 ? (fallsIn2ndPreviousMonth[0] - fallsInLast3Weeks[0]) / 2.25 : null;
    let weeksAgoValue = null;
    let returnedArray = [null, null];
    if (fallsInLast4Weeks[0] > 0) {
        let fallsInLastMonthArray = [[0, fallsInLastWeek], [1, fallsInLast2Weeks], [2, fallsInLast3Weeks], [3, fallsInLast4Weeks]];
        let index = (fallsInLastMonthArray.reduce((previous, next) => {
            if (previous[1][0] < next[1][0]) {
                return next;
            }
            return previous;
        }, [0, [0, 0]]))[0];
        weeksAgoValue = [index + 1, fallsInLastMonthArray[index][1][0]];
    }
    if (!averageFallsPerMonth && weeksAgoValue) {
        let a = 'fallen ' + (weeksAgoValue[1] == 1 ? 'once' : weeksAgoValue[1] + ' times');
        let b = (weeksAgoValue[0] == 1 ? '7 days' : weeksAgoValue[0] + ' weeks');
        let c = 'falls less than once per month';
        basic = generateSentences(personalInformation.Gender, personalInformation.Name.split(" ")[0], a, b, 1, c);
    }
    else if (!weeksAgoValue && averageFallsPerMonth != null) {
        let a = 'not fallen';
        let b = '4 weeks';
        let c;
        if (averageFallsPerMonth < 1) {
            c = 'falls less than once per month';
        }
        else if (Math.floor(averageFallsPerMonth) == 1) {
            c = 'falls once per month';
        }
        else {
            c = 'falls ' + Math.floor(averageFallsPerMonth) + ' times per month';
        }
        basic = generateSentences(personalInformation.Gender, personalInformation.Name.split(" ")[0], a, b, 1, c);
    }
    else if (weeksAgoValue != null && averageFallsPerMonth != null) {
        let a = 'fallen ' + (weeksAgoValue[1] == 1 ? 'once' : weeksAgoValue[1] + ' times');
        let b = (weeksAgoValue[0] == 1 ? '7 days' : weeksAgoValue[0] + ' weeks');
        let c;
        if (averageFallsPerMonth < 1) {
            c = 'falls less than once per month';
        }
        else if (Math.floor(averageFallsPerMonth) == 1) {
            c = 'falls once per month';
        }
        else {
            c = 'falls ' + Math.floor(averageFallsPerMonth) + ' times per month';
        }
        basic = generateSentences(personalInformation.Gender, personalInformation.Name.split(" ")[0], a, b, 1, c);
    }
    if (fallsIn2ndPreviousMonth[0] == 0 && fallsIn2ndPreviousMonth[1] == 0) {
        basic = 'No falls have been detected in the last 3 months.';
    }
    return { fallBasic: basic, fallUrgent: urgent };
}
