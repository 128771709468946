import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

import moment, { Moment } from 'moment';

import { selectAll } from 'd3';
import { Console } from 'console';

import { lowerSliderDrag, lowerSliderDragEnd, upperSliderDrag, upperSliderDragEnd } from './helpers'

export interface dateSelectorProps {
    sliderPos: moment.Moment;
    setSliderPos: React.Dispatch<React.SetStateAction<moment.Moment>>;
    label: string;
    dataInputObject: dataInputObject | null;
    pingUpdate: React.Dispatch<React.SetStateAction<number>>;
    pingCounter: number;
}

export interface sliderProps {
    lowerSliderPos: moment.Moment;
    setLowerSliderPos: React.Dispatch<React.SetStateAction<moment.Moment>>;
    upperSliderPos: moment.Moment;
    setUpperSliderPos: React.Dispatch<React.SetStateAction<moment.Moment>>;
    dataInputObject: dataInputObject | null;
    minimumDays: number;
    maximumDays: number;
    pingLowerUpdate: number;
    pingUpperUpdate: number;
}

export interface dataInputObject {
    globalMinDate: string;
    globalMaxDate: null | string;
    dataIndicators: dataIndicatorRecord[];
}

export interface dataIndicatorRecord {
    startDateTime: string;
    endDateTime: string;
}

function Slider(props: sliderProps) {
    const d3Container = useRef(null);

    const [startDate, setStartDate] = useState<moment.Moment>(moment());
    const [endDate, setEndDate] = useState<moment.Moment>(moment());
    const [minimumDate, setMinimumDate] = useState<moment.Moment>(moment());
    const [maximumDate, setmaximumDate] = useState<moment.Moment>(moment());

    const [isInitialised, setInitialised] = useState(false);

    let minimumSpan = props.minimumDays; // In Days?
    let maximumSpan = props.maximumDays; // In Days?

    let svgWidth = 0;
    let svgHeight = 0;

    useEffect(() => {
        if (props.dataInputObject != null) {
            console.log(props.dataInputObject);
            setStartDate(moment(props.dataInputObject.globalMaxDate !== null ? props.dataInputObject.globalMaxDate : undefined));
            // setEndDate(moment().subtract(props.maximumDays * 3, 'days'));
            setEndDate(moment(props.dataInputObject.globalMinDate));
            setMinimumDate(moment(props.dataInputObject.globalMinDate));
            setmaximumDate(moment(props.dataInputObject.globalMaxDate !== null ? props.dataInputObject.globalMaxDate : undefined));
        }
    }, [props.dataInputObject])

    function drawSlider() {

        if (props.dataInputObject === null) {
            return;
        }

        // Clear and resize the canvas
        const canvas: any = d3.select(d3Container.current);
        svgWidth = canvas.node().getBoundingClientRect().width;
        svgHeight = canvas.node().getBoundingClientRect().height;

        canvas.selectAll('*').remove();

        const MARGIN = { LEFT: 50, RIGHT: 50, TOP: 15, BOTTOM: 5 };
        const WIDTH = svgWidth - MARGIN.LEFT - MARGIN.RIGHT;
        const HEIGHT = svgHeight - MARGIN.TOP - MARGIN.BOTTOM;

        // Is the slider hasnt yet been initialised, set the slider posaitions to their starting points >> will be the base range
        if (isInitialised === false) {
            props.setLowerSliderPos(moment().subtract(minimumSpan, 'days'));
            setInitialised(true);
        }

        let numberOfTicks = WIDTH / 50;
        console.log(`NUMBER OF TICKS: ${numberOfTicks}`)

        const svg = d3.select(d3Container.current)
            .attr('width', WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
            .attr('height', HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
            .append('g')
            .attr('transform', `translate(${MARGIN.LEFT}, ${MARGIN.TOP})`)
            .attr('id', 'canvas')

        // x scale for time
        let x = d3.scaleTime()
            .domain([endDate.toDate(), startDate.toDate()])
            .range([0, WIDTH])


        // format the data points array
        let dataIndicators: [number, number][] = [];
        for (let i = 0; i < props.dataInputObject.dataIndicators.length; i++) {
            dataIndicators.push([x(moment(props.dataInputObject.dataIndicators[i].startDateTime).toDate()), x(moment(props.dataInputObject.dataIndicators[i].endDateTime).toDate())])
        }


        let minimumSpanInPixels = x(moment().toDate()) - x(moment().subtract(minimumSpan, 'days').toDate());
        let maximumSpanInPixels = x(moment().toDate()) - x(moment().subtract(maximumSpan, 'days').toDate());

        // BUILD FRAME FOR SLIDERS
        let frame = svg.append('g')
            .attr('position', 'relative')
        frame.append('g')
            .attr("transform", "translate(0,5)")
            .call(d3.axisBottom(x)
                .ticks(numberOfTicks)
                .tickSizeOuter(0)
            )
            .selectAll("text")
            .attr("y", 12)
            .attr("x", 4)
            .attr("transform", "rotate(45)")
            .style("text-anchor", "start");

        frame.append('rect')
            .attr('width', WIDTH + 6)
            .attr('height', 6)
            .attr("transform", "translate(-3,0)")
            .attr('fill', '#aaaaaa')
            .attr('rx', 3)

        appendDataIndicators(dataIndicators, frame, x(endDate), x(startDate))

        // Slider components
        let selectedIndicator = frame.append('rect')
            .attr('width', x(props.upperSliderPos.toDate()) - x(props.lowerSliderPos.toDate()))
            .attr('x', x(props.lowerSliderPos.toDate()))
            .attr('height', 7)
            .attr("transform", "translate(-3,-0.5)")
            .attr('fill', '#94aae1')
            .attr('opacity', 0.5)
            .attr('rx', 3)

        let lowerSlider = frame.append('circle')
            .attr('r', '7')
            .attr("transform", `translate(0,2.5)`)
            .attr("cx", x(props.lowerSliderPos.toDate()))
            .attr('fill', '#f6f6f6')
            .attr('stroke', '#333333')
            .attr('stroke-width', 1.5)
            .attr('class', 'lowerSlider')
        // Handle drag events for the lower slider
        canvas.selectAll('.lowerSlider')
            .call(d3.drag()
                .on('start', () => {
                    lowerSlider.attr('fill', '#d0d4d4');
                })
                .on('drag', (event: any) => { lowerSliderDrag(event, x, selectedIndicator, lowerSlider, upperSlider, props.upperSliderPos, minimumSpanInPixels, maximumSpanInPixels, WIDTH) })
                .on('end', (event: any) => { lowerSliderDragEnd(event, x, lowerSlider, props.setLowerSliderPos, props.upperSliderPos, props.setUpperSliderPos, minimumSpanInPixels, maximumSpanInPixels, WIDTH) }));


        let upperSlider = frame.append('circle')
            .attr('r', '7')
            .attr("transform", "translate(0,2.5)")
            .attr("cx", x(props.upperSliderPos.toDate()))
            .attr('fill', '#f6f6f6')
            .attr('stroke', '#333333')
            .attr('stroke-width', 1.5)
            .attr('class', 'upperSlider')
        // Handle drag events for the upper slider
        canvas.selectAll('.upperSlider')
            .call(d3.drag()
                .on('start', () => {
                    upperSlider.attr('fill', '#d0d4d4');
                })
                .on('drag', (event: any) => { upperSliderDrag(event, x, selectedIndicator, lowerSlider, upperSlider, props.lowerSliderPos, minimumSpanInPixels, maximumSpanInPixels, WIDTH) })
                .on('end', (event: any) => { upperSliderDragEnd(event, x, upperSlider, props.setLowerSliderPos, props.lowerSliderPos, props.setUpperSliderPos, minimumSpanInPixels, maximumSpanInPixels, WIDTH) })
            );

        canvas.on('wheel.zoom', (event: any) => { handleScroll(x, drawSlider, event, minimumDate, maximumDate, startDate, endDate, setStartDate, setEndDate, props.lowerSliderPos, props.upperSliderPos, minimumSpanInPixels, maximumSpanInPixels)})

    }

    useEffect(() => {
        drawSlider()
    });

    useEffect(() => {
        if (isInitialised === false || startDate == null || endDate == null) {
            return;
        }
        if (props.upperSliderPos < props.lowerSliderPos) {
            console.log('caught 1')
            console.log(maximumDate.diff(props.lowerSliderPos, 'hours'))
            if (maximumDate.diff(props.lowerSliderPos, 'hours') <= props.minimumDays * 24) {
                console.log(props.lowerSliderPos.diff(maximumDate, 'hours'))
                props.setUpperSliderPos(maximumDate);
                props.setLowerSliderPos(maximumDate.clone().subtract(props.minimumDays, 'days'));
                setStartDate(maximumDate);
            } else {
                props.setUpperSliderPos(props.lowerSliderPos.clone().add(props.minimumDays, 'days'));
                setStartDate(props.lowerSliderPos.clone().add(props.minimumDays, 'days'));
            }
        }
        if (props.upperSliderPos.diff(props.lowerSliderPos, 'hours') >= props.maximumDays * 24) {
            console.log('caught 2')
            props.setUpperSliderPos(props.lowerSliderPos.clone().add(props.maximumDays, 'days'));
            setStartDate(props.lowerSliderPos.clone().add(props.maximumDays, 'days'));
        };
        if (props.upperSliderPos.diff(props.lowerSliderPos, 'hours') <= props.minimumDays * 24) {
            console.log('caught 3')
            if (maximumDate.diff(props.upperSliderPos, 'hours') < props.minimumDays * 24) {
                props.setUpperSliderPos(maximumDate);
                props.setLowerSliderPos(maximumDate.clone().subtract(props.minimumDays, 'days'));
                setStartDate(maximumDate);
            } else {
                props.setUpperSliderPos(props.lowerSliderPos.clone().add(props.minimumDays, 'days'));
                setStartDate(props.lowerSliderPos.clone().add(props.minimumDays, 'days'));
            }
        };
        if (props.lowerSliderPos <= endDate) {
            console.log('caught 4')
            // todo: SORT THIS
            setEndDate(props.lowerSliderPos);
        };
        // if (props.lowerSliderPos >= startDate) {
        //     console.log('caught 5')
        //     // todo: SORT THIS
        //     setStartDate(props.upperSliderPos);
        // };
        drawSlider()
    }, [props.pingLowerUpdate]);

    useEffect(() => {
        if (isInitialised === false || startDate == null || endDate == null) {
            return;
        }
        if (props.upperSliderPos < props.lowerSliderPos) {
            console.log('caught 1')
            if (props.upperSliderPos.diff(minimumDate, 'hours') <= props.minimumDays * 24) {
                props.setUpperSliderPos(minimumDate.clone().add(props.minimumDays, 'days'));
                props.setLowerSliderPos(minimumDate);
                setEndDate(minimumDate);
            } else {
                props.setLowerSliderPos(props.upperSliderPos.clone().subtract(props.minimumDays, 'days'));
                setEndDate(props.upperSliderPos.clone().subtract(props.minimumDays, 'days'));
            }
        }

        if (props.upperSliderPos.diff(props.lowerSliderPos, 'hours') >= props.maximumDays * 24) {
            console.log('caught 2')
            props.setLowerSliderPos(props.upperSliderPos.clone().subtract(props.maximumDays, 'days'));
            setStartDate(props.upperSliderPos);
        };

        if (props.upperSliderPos.diff(props.lowerSliderPos, 'hours') <= props.minimumDays * 24) {
            console.log('caught 3')
            if (maximumDate.diff(props.upperSliderPos, 'hours') < props.minimumDays * 24) {
                props.setUpperSliderPos(maximumDate);
                props.setLowerSliderPos(maximumDate.clone().subtract(props.minimumDays, 'days'));
                setStartDate(maximumDate);
            } else {
                props.setUpperSliderPos(props.lowerSliderPos.clone().add(props.minimumDays, 'days'));
                setStartDate(props.lowerSliderPos.clone().add(props.minimumDays, 'days'));
            }
        };

        if (props.upperSliderPos >= startDate) {
            console.log('caught 4')
            // todo: SORT THIS
            setStartDate(props.upperSliderPos);
        };


        drawSlider()
    }, [props.pingUpperUpdate]);

    return (
        <svg className="slider" ref={d3Container} />
    )
}

function handleScroll(
    x: any,
    drawSlider: any,
    event: any,
    minimumDate: moment.Moment,
    maximumDate: moment.Moment,
    startDate: moment.Moment,
    endDate: moment.Moment,
    setStartDate: React.Dispatch<React.SetStateAction<moment.Moment>>,
    setEndDate: React.Dispatch<React.SetStateAction<moment.Moment>>,
    lowerSliderPos: moment.Moment,
    upperSliderPos: moment.Moment,
    minimumSpanInPixels: number,
    maximumSpanInPixels: number, 
) {

    if (minimumDate == undefined || maximumDate == undefined) {
        console.log('undefined error')
        return;
    }
    let hourRange = startDate.diff(endDate, 'hours');
    let scrollDirection: 1 | -1 | 0 = 0;
    if (event.deltaY > 0) {
        scrollDirection = 1;
    } else if (event.deltaY < 0) {
        scrollDirection = -1;
    }
    if (scrollDirection === 0) {
        return;
    }

    let totalDelta = 0;
    if (hourRange > 8760) { // year >> 3 months
        totalDelta = 2190;
    } else if (hourRange > 4380) { // 6 months >> 1 months
        totalDelta = 730;
    } else if (hourRange > 2190) { // 3 months >> 10 days
        totalDelta = 240;
    } else if (hourRange > 730) { // 1 months >> 3 days
        totalDelta = 72;
    } else if (hourRange > 168) { // 1 week >> 1 day
        totalDelta = 24;
    } else if (hourRange > 24) { // 1 day >> 3 hours
        totalDelta = 3;
    } else {
        totalDelta = 1 // else 1 hour
    }

    let totalDataInPixels = x(moment()) - x(moment().subtract(totalDelta, 'hours'));
    console.log('scroll event first')
    // Twat, your datetimes are the wrong way round, this needs to be sorted
    let { min, max } = scrollHandler(x(minimumDate), x(maximumDate), x(endDate), x(startDate), x(lowerSliderPos), x(upperSliderPos), scrollDirection, totalDataInPixels, minimumSpanInPixels * 2, maximumSpanInPixels * 3)

    setEndDate(moment(x.invert(min)))

    setStartDate(moment(x.invert(max)))

    drawSlider()
}

function scrollHandler(
    globalMinimum: number,
    globalMaximum: number,
    localMinimum: number,
    localMaximum: number,
    selectedMinimum: number,
    selectedMaximum: number,
    scrollDirection: 1 | -1,
    delta: number,
    minimumRange: number,
    maximumRange: number,
) {
    let selectionCenter = selectedMinimum + ((selectedMaximum - selectedMinimum) / 2)
    console.log('----')
    console.log(`Scroll direction: ${scrollDirection === 1 ? 'IN' : 'OUT'}`);
    console.log(`Target scroll amount: ${delta}`)
    console.log(`globalMinimum: ${globalMinimum}`);
    console.log(`localMinimum: ${localMinimum}`);
    console.log(`selectedMinimum: ${selectedMinimum}`);
    console.log(`   selected center: ${selectionCenter}`)
    console.log(`selectedMaximum: ${selectedMaximum}`);
    console.log(`localMaximum: ${localMaximum}`);
    console.log(`globalMaximum: ${globalMaximum}`);

    console.log('scroll event')

    let selectedRage = selectedMaximum - selectedMinimum;
    let localRange = localMaximum - localMinimum
    let localCenter = localMinimum + (localRange / 2);
    let localCenterBufferLower = localCenter - (localRange * 0.1);
    let localCenterBufferUpper = localCenter + (localRange * 0.1);

    let uncheckedLocalMinimumDelta = 0;
    let uncheckedLocalMaximumDelta = 0;

    let localMinimumMultiplier = 1;
    let localMaximumMultiplier = 1;

    let globalMaxRange = globalMaximum - globalMinimum;

    if (scrollDirection === 1) {
        localMinimumMultiplier = 1;
        localMaximumMultiplier = -1;
        if (selectionCenter < localCenterBufferLower) {
            uncheckedLocalMaximumDelta = delta;

        } else if (selectionCenter > localCenterBufferUpper) {
            uncheckedLocalMinimumDelta = delta;

        } else if (selectionCenter >= localCenterBufferLower && selectionCenter <= localCenterBufferUpper) {
            uncheckedLocalMinimumDelta = delta / 2;
            uncheckedLocalMaximumDelta = delta / 2;

        } else {
            console.warn('Uncaught on scroll in position catch')
        }
    } else {
        localMinimumMultiplier = -1;
        localMaximumMultiplier = 1;
        if (selectionCenter < localCenterBufferLower) {
            uncheckedLocalMinimumDelta = delta;

        } else if (selectionCenter > localCenterBufferUpper) {
            uncheckedLocalMaximumDelta = delta;

        } else if (selectionCenter >= localCenterBufferLower && selectionCenter <= localCenterBufferUpper) {
            uncheckedLocalMinimumDelta = delta / 2;
            uncheckedLocalMaximumDelta = delta / 2;

        } else {
            console.warn('Uncaught on scroll out position catch')
        }
    }


    if (localMaximum + (uncheckedLocalMaximumDelta * localMaximumMultiplier) > globalMaximum) {
        // If right spills, get overflow, check if it can be added to right, return the maximum amounts
        let maximumDeltaMax = globalMaximum - localMaximum;
        let remainingDelta = uncheckedLocalMaximumDelta - maximumDeltaMax;
        uncheckedLocalMaximumDelta = maximumDeltaMax;

        if (localMinimum + ((uncheckedLocalMinimumDelta + remainingDelta) * localMinimumMultiplier) < globalMinimum) {
            let maximumDeltaMin = localMinimum - globalMinimum;
            uncheckedLocalMinimumDelta = maximumDeltaMin;
        } else {
            uncheckedLocalMinimumDelta += remainingDelta;
        }

    } else if (localMinimum + (uncheckedLocalMinimumDelta * localMinimumMultiplier) < globalMinimum) {
        // If left spills, get overflow, check if it can be added to right, return the maximum amounts
        let maximumDeltaMin = localMinimum - globalMinimum;
        let remainingDelta = uncheckedLocalMinimumDelta - maximumDeltaMin;
        uncheckedLocalMinimumDelta = maximumDeltaMin;

        if (localMaximum + ((uncheckedLocalMaximumDelta + remainingDelta) * localMaximumMultiplier) > globalMaximum) {
            let maximumDeltaMax = globalMaximum - localMaximum;
            uncheckedLocalMaximumDelta = maximumDeltaMax;
        } else {
            uncheckedLocalMaximumDelta += remainingDelta;
        }


    }

    if ((localMaximum + (uncheckedLocalMaximumDelta * localMaximumMultiplier)) - (localMinimum + (uncheckedLocalMinimumDelta * localMinimumMultiplier)) >= globalMaxRange && scrollDirection === -1) {
        console.log('maximum zoom out reached')
        return {
            min: localMinimum,
            max: localMaximum
        }
    }
    console.log(`minimum range: ${minimumRange} current target range ${((localMaximum + (uncheckedLocalMaximumDelta * localMaximumMultiplier)) - (localMinimum + (uncheckedLocalMinimumDelta * localMinimumMultiplier)))}`)
    if ((localMaximum + (uncheckedLocalMaximumDelta * localMaximumMultiplier)) - (localMinimum + (uncheckedLocalMinimumDelta * localMinimumMultiplier)) <= minimumRange && scrollDirection === 1) {
        console.log('minimum zoom in reached')
        return {
            min: localMinimum,
            max: localMaximum
        }
    }

    if ((localMaximum + (uncheckedLocalMaximumDelta * localMaximumMultiplier)) - (localMinimum + (uncheckedLocalMinimumDelta * localMinimumMultiplier)) <= selectedRage * 1.2 && scrollDirection === 1) {
        console.log('zoom in blocked')
        return {
            min: localMinimum,
            max: localMaximum
        }
    }

    console.log(`${globalMinimum} ${localMinimum + (uncheckedLocalMinimumDelta * localMinimumMultiplier)} ${localMaximum + (uncheckedLocalMaximumDelta * localMaximumMultiplier)} ${globalMaximum}`)
    return {
        min: localMinimum + (uncheckedLocalMinimumDelta * localMinimumMultiplier),
        max: localMaximum + (uncheckedLocalMaximumDelta * localMaximumMultiplier)
    }

}

function appendDataIndicators(
    data: [number, number][],
    frame: d3.Selection<SVGGElement, unknown, null, undefined>,
    localMin: number,
    localMax: number
) {
    for (let i = 0; i < data.length; i++) {
        let start = data[i][0];
        let end = data[i][1];
        let plot = false;
        if (start >= localMin && end <= localMax) {
            plot = true;
        } else if (start < localMin && end > localMin && end <= localMax) {
            start = localMin;
            plot = true;
        } else if (start >= localMin && start < localMax && end > localMax) {
            end = localMax + 6;
            plot = true;
        } else if (start <= localMin && end >= localMax) {
            start = localMin;
            end = localMax + 6;
            plot = true;
        }

        if (plot === true) {
            frame.append('rect')
                .attr('width', (end - start))
                .attr('height', 6)
                .attr('x', start)
                .attr("transform", "translate(-3,0)")
                .attr('fill', '#568f56')
                .attr('rx', 3)
        }

    }
}







export default Slider