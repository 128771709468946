import * as React from 'react';
import { RouteComponentProps } from "@reach/router";
import { StackedAreaChart } from '../charts/StackedAreaChart';
import { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import moment from "moment";
import { TimeFrame } from '../TimeFrame';
import './Falls.css';
import NetworkGraph, { NetworkGraphData } from '../charts/NetworkGraph';

import { useMediaQuery } from 'react-responsive'
import DateSelectionSlider, { dataInputObject } from '../dateSelectionSlider/Slider';
import DateSelectorInput from '../dateSelectionSlider/DateSelectorInput';
import { authProvider } from '../../authProvider';

export interface FallProps extends RouteComponentProps {
    acsisID: number;
    sliderData: dataInputObject | null,
}

export interface FallData {
    dateTime: string;
    hour: number;
    location: string;
    illumination: number;
}

export function Falls(props: FallProps) {

    const [hasError, setErrors] = useState(false);
    const [fallData, setFallData] = useState<FallData[]>([]);
    const [network, setNetwork] = useState<NetworkGraphData | null>(null);
    const [appliances, setAppliances] = useState<any | null>(null);

    const [startDate, setStartDate] = useState(moment().subtract(30, 'days'));
    const [endDate, setEndDate] = useState(moment());
    const [lowerSliderChange, setLowerSliderChange] = useState(0);
    const [upperSliderChange, setUpperSliderChange] = useState(0);
    const [isSliderLocked, setSliderLocked] = useState(false);

    console.log('FallTriggered');
    useEffect(() => {
        console.log('useEffect');

        if (startDate == null || endDate == null) {
            return;
        }

        async function fetchData() {
            console.log('start fetch');

            if (startDate == null || endDate == null) {
                return;
            }

            setSliderLocked(true);

            const res = await fetch(`/api/fall?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            res
                .json()
                .then((res: FallData[]) => {
                    console.log(res);
                    setFallData(res);

                })
                .catch(err => setErrors(err));

            const networkRes = await fetch(`/api/network?acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            const applianceRes = await fetch(`/api/applianceLocation?acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            await Promise.all([
                networkRes.json().then((res: NetworkGraphData) => {
                    console.log(res);
                    setNetwork(res);
                }).catch(err => setErrors(err)),
                applianceRes.json().then((res: any) => {
                    console.log(res);
                    setAppliances(res);
                }).catch(err => setErrors(err))
            ]);
            setSliderLocked(false);
        }
        fetchData();
    }, [startDate, endDate]);

    let graphData = null;
    let pieChart1 = null;
    let pieChart2 = null;
    let pieChart3 = null;
    let locationData = null;
    let total: number[] = [];
    let min, max, avg;
    if (fallData && fallData.length > 0) {

        //let times = fallData.reduce((v: { [key: number]: number }, d) => {
        let times = fallData.reduce((v, d) => {
            let h = moment(d.dateTime).hour().toString() + ':00';
            if (v[h] != null) {
                v[h]++;
            } else {
                v[h] = 1;
            }

            return v;
        //}, {} as { [key: number]: number})
        }, {} as any)

        let locations = fallData.reduce((v, d) => {
            if (v[d.location] == null) {
                v[d.location] = 1;
            } else {
                v[d.location]++;
            }
            return v;
        }, {} as any)
        locationData = locations;

        let illuminations = fallData.reduce((v, d) => {
            let level = 'Dark';
            if (d.illumination > 50) {
                level = 'Dim';
            }
            if (d.illumination > 200) {
                level = 'Bright';
            }
            if (v[level] == null) {
                v[level] = 1;
            } else {
                v[level]++;
            }
            return v;
        }, {} as any)

        let timeArray = Object.keys(times).map(i => {
            return { id: i, label: i, value: times[i as any] };
        });


        let locationArray = Object.keys(locations).map(i => {
            return { id: i, label: i, value: locations[i] };
        });

        let illuminationArray = Object.keys(illuminations).map(i => {
            return { id: i, label: i, value: illuminations[i] };
        });

        let colourScheme = ["#61cdbb", "#f47560"];

        let illuminanceColorScheme = ['#007D87', '#5ECBF4', '#FF7800'];

        timeArray.sort((a, b) => {
            if (a.label < b.label) {
                return -1
            } 
            return 1
        })
        pieChart1 = <ResponsivePie
            data={timeArray}
            colors={[
                '#f8b195',
                '#ea9c97',
                '#d58b99',
                '#bb7d9a',
                '#9c7298',
                '#7a6791',
                '#575e86',
                '#355375',
            ]}
            margin={{ top: 30, right: 20, bottom: 30, left: 20 }}
            innerRadius={0.4}
            padAngle={0.7}
            cornerRadius={3}
            enableSlicesLabels={false}
        />;

        pieChart2 = <ResponsivePie
            data={locationArray}
            margin={{ top: 30, right: 20, bottom: 30, left: 20 }}
            innerRadius={0.4}
            padAngle={0.7}
            cornerRadius={3}
            enableSlicesLabels={false}
        />;

        pieChart3 = <ResponsivePie
            data={illuminationArray}
            colors={(d) => {
                if (d.id === 'Dark') {
                    return '#355375';
                } else if (d.id === 'Dim') {
                    return '#c06c84';
                } else if (d.id === 'Bright') {
                    return '#f8b195';
                }
                return 'red'
            }}
            margin={{ top: 30, right: 20, bottom: 30, left: 20 }}
            innerRadius={0.4}
            padAngle={0.7}
            cornerRadius={3}
            enableSlicesLabels={false}
        />;
    }

    return (
        <div className="dashboard-falls">
            <div className="dashboardFalls__header shadow corners">
                <div className="dashboardFalls__titleTextContainer">
                    <h1>Falls</h1>
                </div>
                <div className="dashboardFalls__sliderContainer" id="sliderContainer">
                    {
                        useMediaQuery({
                            query: '(max-width: 1050px)'
                        }) === false ?
                            <DateSelectionSlider lowerSliderPos={startDate} setLowerSliderPos={setStartDate} upperSliderPos={endDate} setUpperSliderPos={setEndDate} dataInputObject={props.sliderData} minimumDays={20} maximumDays={500} pingLowerUpdate={lowerSliderChange} pingUpperUpdate={upperSliderChange} />
                            :
                            null
                    }
                    <div className="dashboardFalls__entryContainer">
                        <DateSelectorInput sliderPos={startDate} setSliderPos={setStartDate} label="Start DateTime" pingUpdate={setLowerSliderChange} pingCounter={lowerSliderChange} dataInputObject={props.sliderData} />
                        <DateSelectorInput sliderPos={endDate} setSliderPos={setEndDate} label="End DateTime" pingUpdate={setUpperSliderChange} pingCounter={upperSliderChange} dataInputObject={props.sliderData} />
                    </div>
                    {
                        isSliderLocked === true ?
                            <div className="dashboardFalls__lock corners" style={{ height: document.getElementById('sliderContainer')?.clientHeight }} />
                            :
                            null
                    }
                </div>
            </div>
            <div className="dashboardFalls__content">
                <div className="dashboardFalls__networkContainer corners shadow">
                    <NetworkGraph acsis={props.acsisID} data={network} applianceData={appliances} width={451} height={200} fallData={locationData} />
                </div>

                <div className="dashboardFalls__roomPieContainer corners shadow" style={{position: 'relative'}}>
                    <h3 style={{marginLeft: 15}}>Fall timings</h3>
                    <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0 }}>
                        {pieChart1}
                    </div>
                </div>

                <div className="dashboardFalls__timePieContainer corners shadow" style={{position: 'relative'}}>
                    <h3 style={{marginLeft: 15}}>Fall locations</h3>
                    <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0  }}>
                        {pieChart2}
                    </div>
                </div>

                <div className="dashboardFalls__lightPieContainer corners shadow" style={{position: 'relative'}}>
                    <h3 style={{marginLeft: 15}}>Fall light levels</h3>
                    <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0  }}>
                        {pieChart3}
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Falls;
