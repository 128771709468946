import * as React from 'react';
import { RouteComponentProps } from "@reach/router";
import { StackedAreaChart } from '../charts/StackedAreaChart';
import { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import * as d3 from "d3";
import { rollup } from "d3-array";
import moment from "moment";
import { TimeFrame } from '../TimeFrame';
import { MedicationChart } from '../charts/MedicationChart'
import './Medication.css';
import { authProvider } from '../../authProvider';


export interface MedicationProps extends RouteComponentProps {
    acsisID: number;
}

// This is actually just appliance data, so we should label it as such
export interface MedicationData {
    startDateTime: string;
    endDateTime: string;
    location: string;
    married: string;
    duration: number;
    time: string;
    date: string;
    inWindow: boolean;
}

export interface MedicationWindowData {
    startTime: string;
    endTime: string;
}

export function Medication(props: MedicationProps) {
    const [hasError, setErrors] = useState(false);
    const [medication, setMedication] = useState<MedicationData[]>([]);
    const [medicationWindow, setMedicationWindow] = useState<any>({windows:[]});

    const [timeFrame, setTimeFrame] = useState({ startDate: moment().subtract(30, 'days'), endDate: moment() });
    //const [timeFrame, setTimeFrame] = useState({ startDate: moment('2019-09-22'), endDate: moment('2019-09-29') });

    console.log('MobTriggered');
    useEffect(() => {
        console.log('useEffect');
        async function fetchData() {
            console.log('start fetch');

            if (timeFrame.startDate == null || timeFrame.endDate == null) {
                return;
            }

            const medicationRes = await fetch(`/api/medication?startDate=${timeFrame.startDate.toISOString()}&endDate=${timeFrame.endDate.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            const medicationWindowRes = await fetch(`/api/serviceUser/${props.acsisID}/medicationWindow?startDate=${timeFrame.startDate.toISOString()}&endDate=${timeFrame.endDate.toISOString()}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});

            await Promise.all([
                medicationRes.json().then((medicationRes: MedicationData[]) => {
                    console.log(medicationRes);
                    setMedication(medicationRes);
                }).catch(err => setErrors(err)),
                medicationWindowRes.json().then((medicationWindowRes: MedicationWindowData[]) => {
                    console.log(medicationWindowRes);
                    setMedicationWindow(medicationWindowRes);
                }).catch(err => setErrors(err))
            ]);

        }
        fetchData();
    }, [timeFrame]);

    console.log(medication);

    let colourScheme = ["#80b918", "#faa307", "#dc2f02"];

    let graphData = null;
    let pieChart = null;
    let barChart = null;
    if (medication && medication.length > 0) {

        // TODO: Look into why this could have happened that we got a null, we may need to look at DateTime instead or filter the SQL more strictly
        let processedMedication = medication.filter(d => d.startDateTime != null).map(d => {
            d.date = d.startDateTime.split('T')[0];
            d.time = d.startDateTime.split('T')[1].replace('Z', '');

            d.inWindow = false;

            for (let window of medicationWindow.windows) {
                if (d.time >= window.startTime && d.time <= window.endTime) {
                    d.inWindow = true;
                }
            }

            return d;
        }) as MedicationData[];

        //@ts-ignore
        let barDataMap = rollup(processedMedication, v => v.length, d => d.date, d => d.inWindow);
        let barData: any = [];
        let tickCount = 0;

        barDataMap.forEach((d:any, k:any) => {
            console.log(d);
            console.log(d.has(false));
            console.log(d.has(true));
            console.log(d.key);
            let inWindow = d.has(true) ? d.get(true) : 0;
            let outOfWindow = d.has(false) ? d.get(false) : 0;
            //  Get the number of not taken medications using the previous values, clamped at 0
            console.log(medicationWindow.windows.length, inWindow, outOfWindow, medicationWindow.windows.length - inWindow - outOfWindow)

            let notTaken = Math.max(0, (medicationWindow.windows.length - inWindow - outOfWindow));
            barData.push({
                date: k,
                inWindow,
                outOfWindow,
                notTaken
            });

            tickCount = Math.max(tickCount, inWindow + outOfWindow + notTaken + 1);
        });

        let pieData = processedMedication.reduce((acc, d) => {
            if (d.inWindow) {
                acc.inWindow++;
            } else {
                acc.outOfWindow++;
            }
            return acc;
        }, {
            inWindow: 0,
            outOfWindow: 0,
            notTaken: 0
        });

        let days = timeFrame.endDate.diff(timeFrame.startDate, 'days');
        console.log("days", days);
        let totalWindows = days * medicationWindow.windows.length;

        pieData.notTaken = Math.max(0,(totalWindows - pieData.inWindow - pieData.outOfWindow));

        console.log(pieData);
        console.log(barData);
        console.log(processedMedication);

        graphData = <MedicationChart data={processedMedication} windowData={medicationWindow.windows} height={100} width={200} acsis={props.acsisID} />;

        let pieDataArr = [
            {
                id: "Accessed during window",
                label: "Accessed during window",
                value: pieData.inWindow,
                color: "#00FF00"
            }, {
                id: "Accessed outside window",
                label: "Accessed outside window",
                value: pieData.outOfWindow,
                color: "#FFFF00"
            }, {
                id: "Not accessed",
                label: "Not accessed",
                value: pieData.notTaken,
                color: "#FF0000"
            },

        ];

        

        pieChart = <ResponsivePie
            data={pieDataArr}
            margin={{ top: 20, right: 20, bottom: 50, left: 20 }}
            colors={colourScheme}
            innerRadius={0.4}
            padAngle={0.7}
            cornerRadius={3}
            enableRadialLabels={false}
            /*legends={[
                {
                    itemsSpacing: 5,
                    anchor: 'bottom-left',
                    direction: 'column',
                    translateY: 25,
                    itemWidth: 100,
                    itemHeight: 18,
                    itemTextColor: '#999',
                    symbolSize: 18,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000'
                            }
                        }
                    ]
                }
            ]}*/
        />;
        
        barChart = <ResponsiveBar
            data={barData}
            indexBy="date"
            keys={["inWindow", "outOfWindow", "notTaken"]}
            margin={{ top: 20, right: 20, bottom: 50, left: 50 }}
            axisBottom={{ legend: "Date", legendOffset: 40, format: d => moment(d).format('DD/MM'), tickRotation: 0, legendPosition: "middle" }}
            axisLeft={{ legend: "Medication accessed", legendOffset: -30, tickRotation: -50, tickValues: tickCount}}
            colors={colourScheme}

        />;
    }

    return (
        <div className="dashboard-medication">
            <div className="dashboard-medication__title"><h1>Medication Adherence</h1><div><h2 style={{ display: "inline" }}>Date range </h2><div style={{ display: "inline-block" }}><TimeFrame timeFrame={timeFrame} setTimeFrame={(tf: any) => setTimeFrame(tf)} /></div></div></div>
            <div className="dashboard-medication__content" style={{ height: "500px" }}>

                <div className="dashboard-medication__graph shadow corners">
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                            {graphData}
                        </div>
                    </div>
                </div>

                <div className="dashboard-medication__piechart shadow corners">
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                            {pieChart}
                        </div>
                    </div>
                </div>

                <div className="dashboard-medication__legend shadow corners">
                    <h3>Legend</h3>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}><div className="legend-orb" style={{ width: '20px', height: '20px', borderRadius: '10px', backgroundColor: colourScheme[0], display: 'inline-block', marginRight: '5px' }}></div> Taken during window</div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}><div className="legend-orb" style={{ width: '20px', height: '20px', borderRadius: '10px', backgroundColor: colourScheme[1], display: 'inline-block', marginRight: '5px' }}></div> Taken outside window</div>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}><div className="legend-orb" style={{ width: '20px', height: '20px', borderRadius: '10px', backgroundColor: colourScheme[2], display: 'inline-block', marginRight: '5px' }}></div> Not taken</div>
                </div>

                <div className="dashboard-medication__barchart shadow corners">
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                            {barChart}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Medication;
