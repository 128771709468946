import { RouteComponentProps, Router, navigate, Link } from "@reach/router";
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Coronavirus } from './Coronavirus';
import { DashboardHome } from './dashboardPages/DashboardHome';
import { ImageCircle, ImageCircleBorder } from './ImageCircle';
import { Mobility } from './dashboardPages/Mobility';
import { Network } from './dashboardPages/Network';
import { Sleep } from './dashboardPages/Sleep';
import { Medication } from './dashboardPages/Medication';
import './ServiceUserDashboard.css';
import { MobilityIndex } from "./dashboardPages/MobilityIndex";
import ToiletFrequency from "./dashboardPages/ToiletFrequency";
import PhoneIcon from '@material-ui/icons/Phone';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';
import WalkingWithPurpose from "./dashboardPages/WalkingWithPurpose";
import Falls from "./dashboardPages/Falls"; 
import { Temperature } from "./dashboardPages/Temperature";
import { Light } from "./dashboardPages/Light";
import { ServiceUserSettings } from "./dashboardPages/ServiceUserSettings";
import moment from "moment";
import SettingsIcon from '@material-ui/icons/Settings';
import { authProvider } from '../authProvider';

import {inputType as RiskScoreDataPoint} from './settingsPages/riskScore/inputs'
import ServiceUserSummary from './ServiceUserSummary/summary';

import {dataInputObject} from '../components/dateSelectionSlider/Slider'

export interface ServiceUserDashboardProps extends RouteComponentProps {
    acsisID?: number;
}

export interface sliderData {
    availabilityRecords: availabilityRecord[]
    globalMinDate: string;
    globalMaxDate: null | string;
}

export interface availabilityRecord {
    key: string;
    value: dataIndicatorRecord[];
}

export interface dataIndicatorRecord {
    startDateTime: string;
    endDateTime: string;
}

export function ServiceUserDashboard(props: ServiceUserDashboardProps) {

    const [hasError, setErrors] = useState(false);
    const [serviceUserDetails, setServiceUserDetails] = useState<any>({});

    const [sliderDataMobilityIndex, setSliderDataMobilityIndex] = useState<dataInputObject | null>(null);
    const [sliderDataSleep, setSliderDataSleep] = useState<dataInputObject | null>(null);
    const [sliderDataWalkingWithPurpose, setSliderDataWalkingwithpurpose] = useState<dataInputObject | null>(null);
    const [sliderDataFalls, setSliderDataFalls] = useState<dataInputObject | null>(null);
    const [sliderDataToilet, setSliderDataToilet] = useState<dataInputObject | null>(null);
    const [sliderDataMobility, setSliderDataMobility] = useState<dataInputObject | null>(null);

    
    const [riskScoreInfo, setRiskScoreInfo] = useState<{
        scores: RiskScoreDataPoint[];
        totalScore: number
    } | null>(null);

    useEffect(() => {
        console.log('useEffect');
        async function fetchData() {  
            console.log('start fetch');

            const riskScoreRes = await fetch(`/api/serviceUser/${props.acsisID}/riskScore`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});

            await Promise.all([
                riskScoreRes.json().then((riskScoreRes: { acsis: number, scores: RiskScoreDataPoint[], riskScore: number }) => {
                    console.log(riskScoreRes);
                    if (riskScoreRes.scores == null) {
                        console.log("Editable Risk Score")
                    } else {
                        console.log("Risk scores have been found, not editable")
                        setRiskScoreInfo({scores: riskScoreRes.scores, totalScore: riskScoreRes.riskScore})
                    }
                }).catch(err => {
                    setErrors(err)
                })
            ]);

        }
        fetchData();
    }, []);

    useEffect(() => {
        async function fetchSliderData() {
            console.log('Fetching slider data')
            const res = await fetch('/api/SliderDataAvailability?acsis=' + props.acsisID, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}})
            console.log(res)
            res.json().then((res: sliderData) => {
                console.log('DataAvailability res unmarhsalled, setting state for each viz');
                console.log(res.availabilityRecords)
                let index = res.availabilityRecords.findIndex(x => x.key === 'MobilityIndex');
                if (index !== -1) {
                    console.log('\n\n\n' + res.availabilityRecords[index] )
                    setSliderDataMobilityIndex({
                        globalMinDate: res.globalMinDate,
                        globalMaxDate: res.globalMaxDate,
                        dataIndicators: res.availabilityRecords[index].value
                    });
                }
                index = res.availabilityRecords.findIndex(x => x.key === 'Sleep');
                if (index !== -1) {
                    console.log('\n\n\n' + res.availabilityRecords[index] )
                    setSliderDataSleep({
                        globalMinDate: res.globalMinDate,
                        globalMaxDate: res.globalMaxDate,
                        dataIndicators: res.availabilityRecords[index].value
                    });
                }
                index = res.availabilityRecords.findIndex(x => x.key === 'WalkingWithPurpose');
                if (index !== -1) {
                    console.log('\n\n\n' + res.availabilityRecords[index] )
                    setSliderDataWalkingwithpurpose({
                        globalMinDate: res.globalMinDate,
                        globalMaxDate: res.globalMaxDate,
                        dataIndicators: res.availabilityRecords[index].value
                    });
                }
                index = res.availabilityRecords.findIndex(x => x.key === 'Falls');
                if (index !== -1) {
                    console.log('\n\n\n' + res.availabilityRecords[index] )
                    setSliderDataFalls({
                        globalMinDate: res.globalMinDate,
                        globalMaxDate: res.globalMaxDate,
                        dataIndicators: res.availabilityRecords[index].value
                    });
                }
                index = res.availabilityRecords.findIndex(x => x.key === 'Toilet');
                if (index !== -1) {
                    console.log('\n\n\n toilet' + res.availabilityRecords[index] )
                    setSliderDataToilet({
                        globalMinDate: res.globalMinDate,
                        globalMaxDate: res.globalMaxDate,
                        dataIndicators: res.availabilityRecords[index].value
                    });
                }
                index = res.availabilityRecords.findIndex(x => x.key === 'Mobility');
                if (index !== -1) {
                    console.log('\n\n\n mobility' + res.availabilityRecords[index])
                    setSliderDataMobility({
                        globalMinDate: res.globalMinDate,
                        globalMaxDate: res.globalMaxDate,
                        dataIndicators: res.availabilityRecords[index].value
                    });
                }
            }).catch((err) => {
                console.log(err);
            })

        }

        fetchSliderData()

    }, [])

    useEffect(() => {

        async function fetchData() {
            console.log('start fetch');
            const res = await fetch(`/api/serviceUsers/` + props.acsisID, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            res
                .json()
                .then((res: any) => {
                    console.log(res);

                    setServiceUserDetails(res);
                })
                .catch(err => setErrors(err));
        }
        fetchData();


    }, []);

    if (props.acsisID == null) {
        return (
            <h2>Sorry, but you need to provide an ACSIS ID to view a dashboard.</h2>
        );
    }

    let address1 = '';
    address1 += serviceUserDetails.streetNumber != null ? serviceUserDetails.streetNumber + ' ' : '';
    address1 += serviceUserDetails.street != null ? serviceUserDetails.street : '';
    address1 += address1 != '' ? ',' : '';

    let address2 = '';
    address2 += serviceUserDetails.town != null ? serviceUserDetails.town + ' ' : '';
    address2 += address2 != '' ? ',' : '';

    let address3 = '';
    address3 += serviceUserDetails.postcode != null ? serviceUserDetails.postcode : '';

    function navigateToSettings() {
        navigate(`/dashboard/${props.acsisID}/settings`);
    }

    return (
        <div className="service-user-dashboard">
            <div className="service-user-dashboard__sidebar shadow corners">
                <div className="service-user-dashboard__sidebarHeader">
                    <div className="service-user-dashboard__sidebarHeaderImageContainer">
                        <ImageCircle acsisID={props.acsisID} imageURL={process.env.PUBLIC_URL + "/UserImages/" + (serviceUserDetails.gender == 'F' ? 'female-solid.svg' : 'male-solid.svg')} altText={serviceUserDetails.firstName + ' ' + serviceUserDetails.LastName} border={1} size={75}/>
                    </div>
                    <div className="service-user-dashboard__sidebarHeaderTextContainer" >
                        <p>
                            <span className="service-user-dashboard__sidebarTextHeader">{serviceUserDetails.firstName + ' ' + serviceUserDetails.lastName}</span><br />
                            <div style={{height: 5}}/>
                            <span className="service-user-dashboard__sidebarTextBody">{(serviceUserDetails.gender == 'F' ? 'Female' : (serviceUserDetails.gender == 'M' ? 'Male' : 'Unspecified'))}</span><br />
                            <span className="service-user-dashboard__sidebarTextBody">{moment().diff(serviceUserDetails.dateOfBirth, 'years')} years old</span><br />
                            <span className="service-user-dashboard__sidebarTextBody">{serviceUserDetails.phoneNumber != null ? handlePhoneNumber(serviceUserDetails.phoneNumber) : 'Phone number not provided'}</span>
                        </p>
                    </div>
                </div>
                <div className="service-user-dashboard__sidebarBody">
                    <div className="service-user-dashboard__sidebarSpacer"/>
                        <ServiceUserSummary data={riskScoreInfo}/>
                    <div className="service-user-dashboard__sidebarSpacer"/>
                </div>
                <div className="service-user-dashboard__sidebarFooter">
                    <p style={{paddingLeft: '7.5%'}}>
                        <span className="service-user-dashboard__sidebarTextSubHeader">Acsis ID: </span>
                        <span className="service-user-dashboard__sidebarTextBody">{props.acsisID}</span> <br />
                        <span className="service-user-dashboard__sidebarTextSubHeader">Referral pathway: </span>
                        <span className="service-user-dashboard__sidebarTextBody">Hospital</span> <br />
                        <div style={{height: 5}}/>
                        <a style={{cursor:'pointer'}}>Contact information</a> <br />
                        <Link to={`/dashboard/${props.acsisID}/settings`} style={{textDecoration: 'none', color: 'black'}}>
                            <span className="service-user-dashboard__sidebarTextSubHeader" style={{cursor:'pointer'}}>Settings</span>
                        </Link>
                        
                    </p>
                </div>
            </div>
            
            <div className="service-user-dashboard__content">
                <Router>
                    <DashboardHome path="/" acsis={props.acsisID} name={serviceUserDetails.firstName + ' ' + serviceUserDetails.lastName} gender={(serviceUserDetails.gender == 'F' ? 'Female' : 'Male')}/>
                    <Mobility path="mobility" acsisID={props.acsisID} sliderData={sliderDataMobility} /> 
                    <Network path="network" acsisID={props.acsisID} />
                    <Sleep path="sleep" acsisID={props.acsisID} sliderData={sliderDataSleep}/>
                    <MobilityIndex path="mobilityIndex" acsisID={props.acsisID} sliderData={sliderDataMobilityIndex}/>
                    <ToiletFrequency path="toiletFrequency" acsisID={props.acsisID} sliderData={sliderDataToilet}/>
                    <WalkingWithPurpose path="walk" acsisID={props.acsisID} sliderData={sliderDataWalkingWithPurpose}/>
                    <Falls path="falls" acsisID={props.acsisID} sliderData={sliderDataFalls}/>
                    <Medication path="medication" acsisID={props.acsisID} />
                    <Temperature path="temperature" acsisID={props.acsisID} />
                    <Light path="light" acsisID={props.acsisID} />
                    <ServiceUserSettings path="settings/*" acsisID={props.acsisID} />
                    <Coronavirus path="coronavirus" />
                </Router>
            </div>
        </div>
    );
}

function handlePhoneNumber(number: string) {
    let x = String(number);
    let xSplit = x.split('');
    if (xSplit[0] === '0') {
        xSplit[0] = '+44 '
    }
    return xSplit.join('')
}

export default ServiceUserDashboard;
