import React, { useEffect, useRef, useState } from 'react';
import {inputType as RiskScoreDataPoint} from '../settingsPages/riskScore/inputs';
import * as d3 from 'd3';

import './summary.css';

export interface serviceUserSummaryProps {
    children?: any;
    data: {scores: RiskScoreDataPoint[]; totalScore: number;} | null;
}

export function ServiceUserSummary(props: serviceUserSummaryProps) {
    const [riskFactors, setRiskFactors] = useState<JSX.Element[]>([])
    useEffect(() => {
        if (props.data === null) {
            setRiskFactors([
                <SingleRiskIndicator score={0} name={'Risk of falls'}/>,
                <SingleRiskIndicator score={0} name={'Mobility'}/>,
                <SingleRiskIndicator score={0} name={'Risk of UTI'}/>,
                <SingleRiskIndicator score={0} name={'Social Isolation'}/>,
                <SingleRiskIndicator score={0} name={'Physical Impairment'}/>,
                <SingleRiskIndicator score={0} name={'Sensory Impairment'}/>,
                <SingleRiskIndicator score={0} name={'Cognitive Impairment'}/>,
            ])
            return;
        } else {
            let data = props.data
            console.log(`Risk score: ${props.data.totalScore}`)

            let singleRiskFactors = [];
    
            let fallsKey = ['Fall'];
            let fallsTotal = 0;
            let fallsMax = 0;
            fallsKey.forEach(x => {
                let i = data.scores.findIndex(y => y.name === x);
                if (i === -1) {
                    console.log('Unable to find key ' + x)
                } else {
                    fallsTotal += data.scores[i].value;
                    fallsMax += 4;
                }
            })
            let fallsPercentage = (fallsTotal / fallsMax) * 100
            if (fallsPercentage === 0) {
                fallsPercentage += 5
            }
            singleRiskFactors.push(
                <SingleRiskIndicator score={fallsPercentage} name={'Risk of falls'}/>
            )

            let moblityKey = ['Mobility', 'Fall', 'WWP'];
            let moblityTotal = 0;
            let moblityMax = 0;
            moblityKey.forEach(x => {
                let i = data.scores.findIndex(y => y.name === x);
                if (i === -1) {
                    console.log('Unable to find key ' + x)
                } else {
                    moblityTotal += data.scores[i].value;
                    moblityMax += 4;
                }
            })
            let moblityPercentage = (moblityTotal / moblityMax) * 100
            if (moblityPercentage === 0) {
                moblityPercentage += 5
            }
            singleRiskFactors.push(
                <SingleRiskIndicator score={moblityPercentage} name={'Mobility'}/>
            )

            let utiKey = ['UTI'];
            let utiTotal = 0;
            let utiMax = 0;
            utiKey.forEach(x => {
                let i = data.scores.findIndex(y => y.name === x);
                if (i === -1) {
                    console.log('Unable to find key ' + x)
                } else {
                    utiTotal += data.scores[i].value;
                    utiMax += 4;
                }
            })
            let utiPercentage = (utiTotal / utiMax) * 100
            if (utiPercentage === 0) {
                utiPercentage += 5
            }
            singleRiskFactors.push(
                <SingleRiskIndicator score={utiPercentage} name={'Risk of UTI'}/>
            )

            let selfCareKey = ['UTI', 'Personal Care', 'Nutrition and Hydration', 'Medication Management', 'Addiction'];
            let selfCareTotal = 0;
            let selfCareMax = 0;
            selfCareKey.forEach(x => {
                let i = data.scores.findIndex(y => y.name === x);
                if (i === -1) {
                    console.log('Unable to find key ' + x)
                } else {
                    selfCareTotal += data.scores[i].value;
                    selfCareMax += 4;
                }
            })
            let selfCarePercentage = (selfCareTotal / selfCareMax) * 100
            if (selfCarePercentage === 0) {
                selfCarePercentage += 5
            }
            singleRiskFactors.push(
                <SingleRiskIndicator score={selfCarePercentage} name={'Self Care'}/>
            )

            let socialKey = ['Social Isolation 1', 'Social Isolation 2', 'Social Isolation 3'];
            let socialTotal = 0;
            let socialMax = 3;
            socialKey.forEach(x => {
                let i = data.scores.findIndex(y => y.name === x);
                if (i === -1) {
                    console.log('Unable to find key ' + x)
                } else {
                    socialTotal -= data.scores[i].value;
                    // socialMax += 1;
                }
            });
            let socialPercentage = 0;
            if (socialTotal === 3) {
                socialPercentage = 5
            } else {
                socialPercentage = ((socialTotal / socialMax) * 100) + 100
            }
            if (socialPercentage === 0) {
                socialPercentage += 5
            }
            singleRiskFactors.push(
                <SingleRiskIndicator score={socialPercentage} name={'Social Isolation'}/>
            )

            let physicalPercentage = 5;
            let physicalIndex = data.scores.findIndex(y => y.name === 'Physical Decline');
            if (physicalIndex !== -1) {
                
                if (data.scores[physicalIndex].value === 0) {
                    let total = 0;
                    let max = 8;
                    for (let i=0; i<data.scores[physicalIndex].children.length; i++) {
                        total += data.scores[physicalIndex].children[i].value;
                    }
                    physicalPercentage = (total/max * 100);
                    if (physicalPercentage === 0) {
                        physicalPercentage = 5
                    }
                }
            }
            singleRiskFactors.push(
                <SingleRiskIndicator score={physicalPercentage} name={'Physical impairment'}/>
            )

            let sensoryPercentage = 5;
            let sensoryIndex = data.scores.findIndex(y => y.name === 'Sensory Decline');
            if (physicalIndex !== -1) {
                
                if (data.scores[sensoryIndex].value === 0) {
                    let total = 0;
                    let max = 8;
                    for (let i=0; i<data.scores[sensoryIndex].children.length; i++) {
                        total += data.scores[sensoryIndex].children[i].value;
                    }
                    sensoryPercentage = (total/max * 100);
                    if (sensoryPercentage === 0) {
                        sensoryPercentage = 5
                    }
                }
            }
            singleRiskFactors.push(
                <SingleRiskIndicator score={sensoryPercentage} name={'Sensory impairment'}/>
            )

            let cognitivePercentage = 5;
            let cognitiveIndex = data.scores.findIndex(y => y.name === 'Cognitive Decline');
            if (physicalIndex !== -1) {
                
                if (data.scores[cognitiveIndex].value === 0) {
                    let total = 0;
                    let max = 8;
                    for (let i=0; i<data.scores[cognitiveIndex].children.length; i++) {
                        total += data.scores[cognitiveIndex].children[i].value;
                    }
                    cognitivePercentage = (total/max * 100);
                    if (cognitivePercentage === 0) {
                        cognitivePercentage = 5
                    }
                }
            }
            singleRiskFactors.push(
                <SingleRiskIndicator score={cognitivePercentage} name={'Cognitive impairment'}/>
            )

            let otherPercentage = 5;
            let otherIndex = data.scores.findIndex(y => y.name === 'Other Decline');
            if (physicalIndex !== -1) {
                
                if (data.scores[otherIndex].value === 0) {
                    let total = 0;
                    let max = 8;
                    for (let i=0; i<data.scores[otherIndex].children.length; i++) {
                        total += data.scores[otherIndex].children[i].value;
                    }
                    otherPercentage = (total/max * 100);
                    if (otherPercentage === 0) {
                        otherPercentage = 5
                    }
                    singleRiskFactors.push(
                        <SingleRiskIndicator score={otherPercentage} name={'Other impairment'}/>
                    )
                }
            }


            setRiskFactors(singleRiskFactors)
        }

        

        
    }, [props.data])

    return (
        <>
            <div className="totalRiskScoreIndicator">
                {/* <TotalRiskScoreIndicator score={null}/> */}
                <TotalRiskScoreIndicator score={props.data === null ? null : props.data.totalScore}/>
                
            </div>
            {riskFactors}

        </>
    )
};

interface pieData {
    name: string;
    value: number;
}

function SingleRiskIndicator(props: {score: number | null, name: string}) {
    var colorScale = d3.scaleLinear<string>()
    .domain([0, 50, 100])
    .range(['#009900', '#FFBF00', '#BB0000'])

    let score = props.score;
    if (props.score === 100) {
        score = 99;
    } else if (score === null) {
        score = 0
    }

    const d3Container = useRef(null);
    let svgWidth = 120;
    let svgHeight = 120;

    let MARGIN = { LEFT: 10, RIGHT: 0, TOP: 5, BOTTOM: 5 };
    let WIDTH = svgWidth - MARGIN.LEFT - MARGIN.RIGHT;
    let HEIGHT = svgHeight - MARGIN.TOP - MARGIN.BOTTOM;

    function drawIndicator() {

        var colorScale = d3.scaleLinear<string>()
        .domain([0, 50, 100])
        .range(['#009900', '#FFBF00', '#BB0000'])

        const canvas: any = d3.select(d3Container.current);
        svgWidth = canvas.node().getBoundingClientRect().width;
        svgHeight = 40;

        MARGIN = { LEFT: 20, RIGHT: 20, TOP: 20, BOTTOM: 5 };
        WIDTH = svgWidth - MARGIN.LEFT - MARGIN.RIGHT;
        HEIGHT = svgHeight - MARGIN.TOP - MARGIN.BOTTOM;

        // Clear any svg components from the canvas as the pie will be re-drawn multiple times as the state data changes
        canvas.selectAll("*").remove()

        // Create a fresh canvas 
        const svg = d3.select(d3Container.current)
        .attr('width', WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
        .attr('height', HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
        .append('g')
        .attr('transform', `translate(${MARGIN.LEFT}, ${MARGIN.TOP})`)
        .attr('fill', 'blue')
        .attr('id', 'canvas')

        svg.append('rect')
        .attr('width', WIDTH)
        .attr('height', 10)
        .attr('rx', 5)
        .attr('fill', '#a1a9a8')

        if (props.score !== null) {
            svg.append('rect')
            .attr('width', (WIDTH / 100 * props.score))
            .attr('height', 10)
            .attr('rx', 5)
            .attr('fill', colorScale(props.score))
        }

        svg.append('text')
        .text(props.name)
        .attr('font-size', 18)
        .attr('fill', '#403D3D')
        .attr('font-weight', '300')
        .attr('transform', 'translate(0, -6)')
        

    }

    useEffect(() => {
        drawIndicator()
    })

    return (
        <svg className="singleRiskScoreIndicator" ref={d3Container}/>
    )
}

function TotalRiskScoreIndicator(props: {score: number | null}) {

    var colorScale = d3.scaleLinear<string>()
    .domain([0, 50, 100])
    .range(['#009900', '#FFBF00', '#BB0000'])

    let score = props.score;
    if (props.score === 100) {
        score = 99;
    } else if (score === null) {
        score = 0
    }

    const d3Container = useRef(null);
    let svgWidth = 120;
    let svgHeight = 120;

    let MARGIN = { LEFT: 5, RIGHT: 5, TOP: 5, BOTTOM: 5 };
    let WIDTH = svgWidth - MARGIN.LEFT - MARGIN.RIGHT;
    let HEIGHT = svgHeight - MARGIN.TOP - MARGIN.BOTTOM;

    function drawIndicator() {

        if (score === null) {
            return;
        }

        const canvas: any = d3.select(d3Container.current);

        MARGIN = { LEFT: 5, RIGHT: 5, TOP: 5, BOTTOM: 5 };
        WIDTH = svgWidth - MARGIN.LEFT - MARGIN.RIGHT;
        HEIGHT = svgHeight - MARGIN.TOP - MARGIN.BOTTOM;

        // Clear any svg components from the canvas 
        canvas.selectAll("*").remove()

        // Create a fresh canvas 
        const svg = d3.select(d3Container.current)
            .attr('width', WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
            .attr('height', HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
            .append('g')
            .attr('transform', `translate(${(WIDTH + MARGIN.LEFT + MARGIN.RIGHT) / 2}, ${(HEIGHT + MARGIN.TOP + MARGIN.BOTTOM) / 2})`)
            .attr('fill', 'blue')
            .attr('id', 'canvas')
        
        var pie = d3.pie<pieData>()
        .sort(null)
        .value((d: any) => d.value)


        let pieData: pieData[] = [
            {
                name: 'bottom spacing',
                value: 25
            },
            {
                name: 'score',
                value: (75 / 100) * score
            },
            {
                name: 'unfilledScore',
                value: 75 - ((75 / 100) * score)
            },
        ];

        console.log(`\n\n\n${(75 / 100) * score}\n\n\n${75 - ((75 / 100) * score)}\n\n\n`)
        console.log(25 + ((75 / 100) * score) + (75 - ((75 / 100) * score)))

        let colours = ['white', colorScale(score), '#a1a9a8']

        var arcs = pie(pieData);

        var arc = d3.arc<any>()
            .innerRadius((HEIGHT - 20) / 2)
            .outerRadius((HEIGHT) / 2)

        svg.append("g")
            .attr("stroke", "white")
            .attr('stroke-width', 0)
            .selectAll("path")
            .data(arcs)
            .join("path")
            .attr("fill", (d: any, i: number) => colours[i])
            .attr("d", arc)
            .attr('transform', 'rotate(135)')
            
        svg.append('text')
            .text('Risk Score')
            .attr('font-size', 15)
            .attr('fill', '#403D3D')
            .attr('font-weight', '300')
            .attr('text-anchor', 'middle')
            .attr('transform', `translate(0, ${(HEIGHT + MARGIN.TOP + MARGIN.BOTTOM) / 2 -50})`)
        
        svg.append('text')
            .text(props.score !== null ? score : '-')
            .attr('font-size', 30)
            .attr('fill', '#403D3D')
            .attr('font-weight', '600')
            .attr('text-anchor', 'middle')
            .attr('transform', `translate(0, ${(HEIGHT + MARGIN.TOP + MARGIN.BOTTOM) / 2 -15})`)
    }

    useEffect(() => {
        drawIndicator()
    })


    return (
        <svg className="totalRiskScoreIndicator" ref={d3Container}/>
    )
}


export default ServiceUserSummary;