import React, {useRef, useEffect, useState} from 'react';
import * as d3 from 'd3';
import moment from 'moment';

import './ganttMain.css'

export interface applianceLocRecord {
    appliance: string;
    location: string;
    position: number;
    total: number;
}

export interface ganttProps {
    motionData: motionDataPoint[] | null;
    domainArray: string[] | null;
    applianceLocations: applianceLocRecord[] | null;
    applianceData: startEndDatapoint[] | null;
    bounds: [Date, Date] | null;
}

export interface startEndDatapoint {
    startDateTime: string;
    endDateTime: string;
    location: string;
}

export interface motionDataPoint {
    startDateTime: string;
    endDateTime: string;
    location: string;
    inactivityArray: {
        startDateTime: string;
        endDateTime: string;
    }[]
}

export interface applianceLocationRecord {
    appliances: {
        [key: string]: {
            location: string
        }
    }
}

export interface networkRecord {
    nodes: {
        id: string;
    }[];
    links: {
            source: string;
            target: string;
    }[];
}

interface branchData {
    dateTime: string;
    y: number;
    height: number;
}

const colorArray = [
    "#396EA2",
    "#FF7D00",
    "#F24149",
    "#55B1AA",
    "#329A3A",
    "#F2C218",
    "#B06B99",
    "#FF8C9B",
]

export function GanttChart(props: ganttProps) {
    const d3Container = useRef(null);

    const [indicatorHidden, setIndicatorHidden] = useState(true);
    const [indicatorHiddenAppliance, setIndicatorHiddenAppliance] = useState(true);
    const [toolTipText, setToolTipText] = useState<[string, string]>(['', '']);
    const [toolTipTextAppliance, setToolTipTextAppliance] = useState<[string, string, string]>(['', '', '']);
    const [toolTipCoords, setToolTipCoords] = useState<[number, number]>([0, 0]);

    const [isDrawing, setIsDrawing] = useState(false);

    let svgWidth = 0;
    let svgHeight = 0;
    
    let MARGIN = { LEFT: 225, RIGHT: 0, TOP: 50, BOTTOM: 0 };
    let WIDTH = svgWidth - MARGIN.LEFT - MARGIN.RIGHT;
    let HEIGHT = svgHeight - MARGIN.TOP - MARGIN.BOTTOM;

    let timeout = false;

    useEffect(() => {
        let delta = 1000;
        function initDraw() {
            
            // let resizeStart = new Date();
            if (timeout === false) {
                timeout = true;
                setTimeout(drawGantt, delta)
            }
        }
        initDraw()
        window.addEventListener('resize', initDraw);
        return () => {
            window.removeEventListener('resize', initDraw);
        }
    }, [props.motionData, props.domainArray, props.bounds, props.applianceData, props.applianceLocations]);

    useEffect(() => {
        drawGantt()
    }, [props.motionData, props.domainArray, props.bounds, props.applianceData, props.applianceLocations])

    useEffect(() => {

        const canvas: any = d3.select(d3Container.current);
        canvas.selectAll('.tooltip').remove()
        let key = canvas.append('g')
        .attr('class', 'tooltip')
        .attr('transform', `translate(${MARGIN.LEFT + toolTipCoords[0]}, ${MARGIN.TOP + toolTipCoords[1]})`)
        key.append('rect')
        .attr('width', 180)
        .attr('height', 60)
        .attr('fill', 'white')
        .attr('visibility', indicatorHidden === true ? 'hidden' : 'visible')
        .attr('rx', 5)
        key.append('text')
        .text(toolTipText[0])
        .attr('color', 'black')
        .attr('transform', 'translate(90, 20)')
        .attr('font-family', "Montserrat-Regular, Montserrat")
        .attr('visibility', indicatorHidden === true ? 'hidden' : 'visible')
        .attr('text-anchor', 'middle')
        key.append('text')
        .text(toolTipText[1])
        .attr('color', 'black')
        .attr('transform', 'translate(90, 50)')
        .attr('font-family', "Montserrat-Regular, Montserrat")
        .attr('visibility', indicatorHidden === true ? 'hidden' : 'visible')
        .attr('text-anchor', 'middle')

    })

    useEffect(() => {

        const canvas: any = d3.select(d3Container.current);
        canvas.selectAll('.tooltipAppliance').remove()
        let key = canvas.append('g')
        .attr('class', 'tooltipAppliance')
        .attr('transform', `translate(${MARGIN.LEFT + toolTipCoords[0]}, ${MARGIN.TOP + toolTipCoords[1]})`)
        key.append('rect')
        .attr('width', 180)
        .attr('height', 90)
        .attr('fill', 'white')
        .attr('visibility', indicatorHiddenAppliance === true ? 'hidden' : 'visible')
        .attr('rx', 5)
        key.append('text')
        .text(toolTipTextAppliance[0])
        .attr('color', 'black')
        .attr('transform', 'translate(90, 20)')
        .attr('font-family', "Montserrat-Regular, Montserrat")
        .attr('visibility', indicatorHiddenAppliance === true ? 'hidden' : 'visible')
        .attr('text-anchor', 'middle')
        key.append('text')
        .text(toolTipTextAppliance[1])
        .attr('color', 'black')
        .attr('transform', 'translate(90, 50)')
        .attr('font-family', "Montserrat-Regular, Montserrat")
        .attr('visibility', indicatorHiddenAppliance === true ? 'hidden' : 'visible')
        .attr('text-anchor', 'middle')
        key.append('text')
        .text(toolTipTextAppliance[2])
        .attr('color', 'black')
        .attr('transform', 'translate(90, 80)')
        .attr('font-family', "Montserrat-Regular, Montserrat")
        .attr('visibility', indicatorHiddenAppliance === true ? 'hidden' : 'visible')
        .attr('text-anchor', 'middle')

    })

    function drawGantt() {
        console.log('in')
        if (isDrawing === true) {
            console.log('Already drawing')
            return;
        }
        setIsDrawing(true);
        timeout = false;
        const canvas: any = d3.select(d3Container.current);
        let dimensions = canvas.node().getBoundingClientRect()
        svgWidth = Math.floor(dimensions.width);
        svgHeight = Math.floor(dimensions.height);

        MARGIN = { LEFT: 225, RIGHT: 0, TOP: 50, BOTTOM: 0 };
        WIDTH = svgWidth - MARGIN.LEFT - MARGIN.RIGHT;
        HEIGHT = svgHeight - MARGIN.TOP - MARGIN.BOTTOM - 4;

        if (props.motionData === null || props.applianceData == null || props.motionData.length === 0 || props.bounds == null) {
            console.log('OUT');
            setIsDrawing(false);
            console.log(`Data: ${props.motionData !== null && props.motionData.length !== 0}`)
            console.log(`applianceData: ${props.applianceData !== null}`)
            console.log(`domainArray: ${props.domainArray !== null}`)
            console.log(`bounds: ${props.bounds !== null}`)
            console.log(`applianceLocations: ${props.applianceLocations !== null}`)
            return;
        }

        // Clear any svg components from the canvas as the pie will be re-drawn multiple times as the state data changes
        canvas.selectAll("*").remove()

        // Create a fresh canvas 
        const svg = d3.select(d3Container.current)
            .attr('width', WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
            .attr('height', HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
            .append('g')
            .attr('transform', `translate(${MARGIN.LEFT}, ${MARGIN.TOP})`)
            .attr('fill', 'blue')
            .attr('id', 'canvas')

        // Page is set and scaled ready for drawing
        let domainArray: string[] = props.domainArray == null ? [] : props.domainArray;
        if (props.domainArray == null) {
            domainArray = []
            props.motionData.forEach(record => {
                if (!JSON.stringify(domainArray).includes(record.location)) {
                    domainArray.push(record.location);
                }
            });
        }

        // Set up Y scale
        let y = d3.scaleBand()
        .domain(domainArray)
        .rangeRound([0, HEIGHT]);

        buildFrame(canvas, y, MARGIN, domainArray, WIDTH, HEIGHT, props.motionData[0].startDateTime, props.motionData[props.motionData.length -1].endDateTime)

        // Set up X scale
        var x = d3.scaleTime()
        .domain([props.bounds[0], props.bounds[1]])
        .range([0, WIDTH]);


        let xTicks = svg.append('g')
        .attr("transform", `translate(0,${0})`)
        .call(
            d3.axisTop(x)
            .tickFormat((x: any) => moment(x).format(handleTickFormat(props.motionData)))
        )
        xTicks.selectAll('text').attr('font-family', "Montserrat-Regular, Montserrat")


        //
        //PLOT TRACKS START
        //

        domainArray.forEach(domain => {
            let index = domainArray.findIndex(x => x === domain);
            if (index === -1) {
                index = domainArray.length;
            }
            if (index >= colorArray.length) {
                index = colorArray.length
            }
            let y0 = y(domain)!
            svg.append('rect')
            .attr('x', 0)
            .attr('y', y0)
            .attr('width', WIDTH)
            .attr('height', Math.floor(y.bandwidth()))
            .attr('fill', colorArray[index])
            .attr("opacity", 0.15)
            .attr("class", "ganttConnection");
        })


        //
        //PLOT MOBILITY START
        //

        // calculate the branch locations
        let branchData: branchData[] = [];
        for (let i=1; i<props.motionData.length; i++) {
            let yVal1 = y(props.motionData[i].location)!;
            let yVal2 = y(props.motionData[i-1].location)!;
            let height = yVal1 - yVal2;
            if (height < 0) {
                branchData.push({
                    y: yVal1,
                    height: height * -1, 
                    dateTime: props.motionData[i].startDateTime
                })
            } else {
                branchData.push({
                    y: yVal2,
                    height, 
                    dateTime: props.motionData[i].startDateTime
                })
            }
        }

        branchData.forEach(branch => {
            appendVerticalConnector(svg, x, y, branch.y, branch.height, branch.dateTime)
        })

        props.motionData.forEach(record => {
            appendBar(svg, x, y, domainArray, record.location, record.startDateTime, record.endDateTime, true, setIndicatorHidden, setToolTipCoords, WIDTH, HEIGHT, setToolTipText);
            if (!record.inactivityArray || record.inactivityArray.length === 0) {
                appendBar(svg, x, y, domainArray, record.location, record.startDateTime, record.endDateTime, false, setIndicatorHidden, setToolTipCoords, WIDTH, HEIGHT, setToolTipText);
                return;
            }
            appendBar(svg, x, y, domainArray, record.location, record.startDateTime, record.inactivityArray[0].startDateTime, false, setIndicatorHidden, setToolTipCoords, WIDTH, HEIGHT, setToolTipText)
            for (let i=1; i<record.inactivityArray.length; i++) {
                appendBar(svg, x, y, domainArray, record.location, record.inactivityArray[i-1].endDateTime, record.inactivityArray[i].startDateTime, false, setIndicatorHidden, setToolTipCoords, WIDTH, HEIGHT, setToolTipText)
            }
            appendBar(svg, x, y, domainArray, record.location, record.inactivityArray[record.inactivityArray.length - 1].endDateTime, record.endDateTime, false, setIndicatorHidden, setToolTipCoords, WIDTH, HEIGHT, setToolTipText)
        })

        if (props.applianceLocations != null) {
            for (let i=0; i<props.applianceData.length; i++) {
                appendAppliance(svg, x, y, domainArray, props.applianceLocations, WIDTH, HEIGHT, props.applianceData[i], setIndicatorHiddenAppliance, setToolTipCoords, setToolTipTextAppliance)
            }
        }

        
        setIsDrawing(false);
    }

    return (
        <svg id="ganttChart" ref={d3Container} />
    )
}


function appendAppliance(svg: d3.Selection<SVGGElement, unknown, null, undefined>, x: d3.ScaleTime<number, number, never>, y: d3.ScaleBand<string>, domainArray: string[], applianceLoc: applianceLocRecord[], WIDTH: number, HEIGHT: number, record: startEndDatapoint, setIndicatorHidden: React.Dispatch<React.SetStateAction<boolean>>, setTooltipCoords: React.Dispatch<React.SetStateAction<[number, number]>>, setTooltipText: React.Dispatch<React.SetStateAction<[string, string, string]>>) {
    let index = applianceLoc.findIndex(x => {
        return x.appliance === record.location
    });
    if (index === -1) {
        console.log('NOT FOUND')
        return;
    }
    let barHeight = Math.floor(y.bandwidth() * 0.5);
    let applianceBarHeight = barHeight * 0.18
    console.log(applianceLoc[index].location)
    let rect = svg.append('rect')
    .attr('x', x(Date.parse(record.startDateTime)))
    .attr('y', Math.floor(y(applianceLoc[index].location)! + ((y.bandwidth() / 2) - (barHeight * 0.02 + (applianceLoc[index].position * 0.2)))))
    .attr('width', x(Date.parse(record.endDateTime)) - x(Date.parse(record.startDateTime)))
    .attr('height', applianceBarHeight)
    .attr('fill', '#651fff')
    .attr('stroke-width', 1)
    .attr('stroke', '#fff')
    .attr('rx', 3)
    .attr("class", "appliance bar")
    .on('mouseover', (event) => {
        rect
        .attr('stroke-width', 3)
        .attr('stroke', '#1184e8')
        let xy = d3.pointer(event)
        if (xy[1] > HEIGHT * 0.2) {
            xy[1] = xy[1] - 60
        } else {
            xy[1] = xy[1] + 20
        }
        if (xy[0] > WIDTH / 2) {
            xy[0] = xy[0] - 200
        }
        setTooltipCoords(xy)
        setIndicatorHidden(false)
        setTooltipText([record.location, moment(record.startDateTime).format('YYYY/MM/DD HH:MM:SS'), moment(record.endDateTime).format('YYYY/MM/DD HH:MM:SS')])
    })
    .on('mousemove', (event) => {
        let xy = d3.pointer(event)
        if (xy[1] > HEIGHT * 0.2) {
            xy[1] = xy[1] - 60
        } else {
            xy[1] = xy[1] + 20
        }
        if (xy[0] > WIDTH / 2) {
            xy[0] = xy[0] - 200
        }
        setTooltipCoords(xy)
        setTooltipText([record.location, moment(record.startDateTime).format('YYYY/MM/DD HH:MM:SS'), moment(record.endDateTime).format('YYYY/MM/DD HH:MM:SS')])
    })
    .on('mouseleave', () => {
        rect
        .attr('stroke-width', 1)
        .attr('stroke', '#fff')
        setIndicatorHidden(true)
    })
    
    
}


function handleTickFormat(motionData: motionDataPoint[] | null): string {
    if (!motionData) {
        return 'MMM DD HH:mm'
    }
    let startObj = moment(motionData[0].startDateTime);
    let endObj = moment(motionData[motionData.length - 1].startDateTime);
    let deltaMinutes = endObj.diff(startObj, 'minute');
    if (deltaMinutes < 14440) {
        return 'MM/DD HH:mm'
    } else if (deltaMinutes < 14440 * 3) {
        return 'MMM DD'
    } else {
        return 'MMM DD'
    }
}

function appendBar(svg: d3.Selection<SVGGElement, unknown, null, undefined>, x: d3.ScaleTime<number, number, never>, y: d3.ScaleBand<string>, domainArray: string[], loc: string, start: string, end: string, background: boolean, setIndicatorHidden: React.Dispatch<React.SetStateAction<boolean>>, setTooltipCoords: React.Dispatch<React.SetStateAction<[number, number]>>, WIDTH: number, HEIGHT: number, setTooltipText: React.Dispatch<React.SetStateAction<[string, string]>>) {
    let index = domainArray.findIndex(x => x === loc);
    if (index === -1) {
        index = domainArray.length;
    }
    if (index >= colorArray.length) {
        index = colorArray.length
    }
    
    let rect = svg
    .append('rect')
    .attr('x', x(moment(start)))
    .attr('y', y(loc)! + ((y.bandwidth() / 2) - ((y.bandwidth() * 0.5)) / 2))
    .attr('width', x(moment(end)) - x(moment(start)))
    .attr('height', y.bandwidth() * 0.5)
    .attr('fill', background === false ? colorArray[index] : '#fff')
    .attr('opacity', background === false ? 1 : 0.5)
    .attr('stroke-width', background === false ? 1 : 0)
    .attr('stroke', '#707070')
    .attr('rx', 3)
    .attr("class", "ganttBar")
    .on('mouseover', (event) => {
        rect
        .attr('stroke-width', 3)
        .attr('stroke', '#1184e8')
        let xy = d3.pointer(event)
        if (xy[1] > HEIGHT * 0.2) {
            xy[1] = xy[1] - 60
        } else {
            xy[1] = xy[1] + 20
        }
        if (xy[0] > WIDTH / 2) {
            xy[0] = xy[0] - 200
        }
        setTooltipCoords(xy)
        setIndicatorHidden(false)
        setTooltipText([moment(start).format('YYYY/MM/DD HH:MM:ss'), moment(end).format('YYYY/MM/DD HH:MM:ss')])
    })
    .on('mousemove', (event) => {
        let xy = d3.pointer(event)
        if (xy[1] > HEIGHT * 0.2) {
            xy[1] = xy[1] - 60
        } else {
            xy[1] = xy[1] + 20
        }
        if (xy[0] > WIDTH / 2) {
            xy[0] = xy[0] - 200
        }
        setTooltipCoords(xy)
        setTooltipText([moment(start).format('YYYY/MM/DD HH:MM:ss'), moment(end).format('YYYY/MM/DD HH:MM:ss')])
    })
    .on('mouseleave', () => {
        rect
        .attr('stroke-width', background === false ? 1 : 0)
        .attr('stroke', '#707070')
        setIndicatorHidden(true)
    })
}

function appendVerticalConnector(svg: d3.Selection<SVGGElement, unknown, null, undefined>, x: d3.ScaleTime<number, number, never>, y: d3.ScaleBand<string>, yVal: number, height: number, dateTime: string) {    
    svg
    .append('rect')
    .attr('x', x(moment(dateTime)) - 0.5)
    .attr('y', yVal + ((y.bandwidth() / 2)))
    .attr('width', 1)
    .attr('height', height)
    .attr('fill', '#707070')
    .attr("class", "ganttConnection");
}

export default GanttChart;

function buildFrame(canvas: d3.Selection<SVGGElement, unknown, null, undefined>, y: d3.ScaleBand<string>, MARGIN: any, domainArray: string[], WIDTH: number, HEIGHT: number, startDateTime: string, endDateTime: string) {
    for (let i=0; i<domainArray.length; i++) {
        canvas.append('rect')
        .attr('x', 0)
        .attr('y', MARGIN.TOP + (i * Math.floor(y.bandwidth())))
        .attr('width', MARGIN.LEFT)
        .attr('height', y.bandwidth())
        .attr('fill', '#F8F8F8')
        .attr('opacity', 1)
        .attr('stroke-width', 0)
        .attr('stroke', '#707070')
        .attr("class", "ganttBar");
    }
    // date container
    canvas.append('rect')
    .attr('x', -1)
    .attr('y', -0.5)
    .attr('width', MARGIN.LEFT + 1)
    .attr('height', MARGIN.TOP + 0.5)
    .attr('fill', '#F8F8F8')
    .attr('stroke-width', 0)
    .attr('stroke', 'black')

    canvas.append('line')
    .attr('x1', MARGIN.LEFT)
    .attr('x2', MARGIN.LEFT)
    .attr('y1', 0)
    .attr('y2', MARGIN.TOP)
    .style("stroke", "black")
    .style("stroke-width", 1)

    canvas.append('line')
    .attr('x1', 0)
    .attr('x2', MARGIN.LEFT)
    .attr('y1', MARGIN.TOP + 0.5)
    .attr('y2', MARGIN.TOP + 0.5)
    .style("stroke", "black")
    .style("stroke-width", 1)

    for (let i=0; i<domainArray.length; i++) {
        canvas.append('line')
        .attr('x1', 0)
        .attr('x2', WIDTH + MARGIN.LEFT)
        .attr('y1', MARGIN.TOP + ((i+1) * y.bandwidth()))
        .attr('y2', MARGIN.TOP + ((i+1) * y.bandwidth()))
        .style("stroke-dasharray","5,5")
        .style("stroke", "#707070")

        canvas.append('text')
        .text(domainArray[i])
        .attr('text-anchor', 'middle')
        .attr('dx', MARGIN.LEFT / 2)
        .attr('dy', MARGIN.TOP + 5 + ((i+1) * (y.bandwidth()) - (y.bandwidth() / 2)))
        .attr('font-size', '100%')
        .attr('font-family', "Montserrat-Regular, Montserrat")
    }

    canvas.append('line')
    .attr('x1', MARGIN.LEFT)
    .attr('x2', MARGIN.LEFT)
    .attr('y1', MARGIN.TOP + 0.5)
    .attr('y2', HEIGHT + MARGIN.TOP)
    .style("stroke-dasharray","5,5")
    .style("stroke", "#707070")

    let start = moment(startDateTime).startOf('day').toISOString();
    let end = moment(endDateTime).startOf('day').toISOString();
    let text = ''
    if (start === end) {
        text = moment(startDateTime).format('YYYY/MM/DD');
    } else {
        text = `${moment(startDateTime).format('YYYY/MM/DD')} - ${moment(endDateTime).format('YYYY/MM/DD')}`
    }

    canvas.append('text')
    .text(text)
    .attr('text-anchor', 'middle')
    .attr('dx', MARGIN.LEFT / 2)
    .attr('dy', MARGIN.TOP - 10)
    .attr('font-size', '100%')
    .attr('font-family', "Montserrat-Regular, Montserrat")
}
