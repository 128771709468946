import moment from 'moment';

export function lowerSliderDrag(
    event: any,
    x: d3.ScaleTime<number, number, never>,
    selectedIndicator: d3.Selection<SVGRectElement, unknown, null, undefined>,
    lowerSlider: d3.Selection<SVGCircleElement, unknown, null, undefined>,
    upperSlider: d3.Selection<SVGCircleElement, unknown, null, undefined>,
    upperSliderPos: moment.Moment,
    minimumSpanInPixels: number,
    maximumSpanInPixels: number,
    WIDTH: number
) {

    if (event.x + minimumSpanInPixels >= WIDTH) {
        // If position is at, or beyond, the left bound of the slider frame, do nothing
        return;
    } else if (event.x <= 0) {
        // If position is at, or beyond, the right bound of the slider frame, do nothing
        return;
    }
    let upperSliderPosPixels = x(upperSliderPos.toDate())
    // Resize and re-center the selected window indicator bar
    selectedIndicator.attr('width', upperSliderPosPixels - event.x)
    selectedIndicator.attr('x', event.x)
    if (event.x > upperSliderPosPixels - minimumSpanInPixels) {
        // If we are within the minimum span, then push the upper slider
        selectedIndicator.attr('width', minimumSpanInPixels)
        selectedIndicator.attr('x', event.x)
        lowerSlider.attr('cx', event.x);
        upperSlider.attr('cx', event.x + minimumSpanInPixels);
        return
    } else if (event.x < upperSliderPosPixels - maximumSpanInPixels) {
        // If we are outisde the maximum span, then pull the upper slider
        selectedIndicator.attr('width', maximumSpanInPixels)
        selectedIndicator.attr('x', event.x)
        lowerSlider.attr('cx', event.x);
        upperSlider.attr('cx', event.x + maximumSpanInPixels);
        return
    } else {
        // Just move the slider
        lowerSlider.attr('cx', event.x);
        return
    }
}

export function lowerSliderDragEnd(
    event: any,
    x: d3.ScaleTime<number, number, never>,
    lowerSlider: d3.Selection<SVGCircleElement, unknown, null, undefined>,
    setLowerSliderPos: React.Dispatch<React.SetStateAction<moment.Moment>>,
    upperSliderPos: moment.Moment,
    setUpperSliderPos: React.Dispatch<React.SetStateAction<moment.Moment>>,
    minimumSpanInPixels: number,
    maximumSpanInPixels: number,
    WIDTH: number,
) {
    if (event.x + minimumSpanInPixels >= WIDTH) {
        // Prevent spilling off the right
        setLowerSliderPos(moment(x.invert(WIDTH - minimumSpanInPixels)));
        setUpperSliderPos(moment(x.invert(WIDTH)));
        return;
    } else if (event.x <= 0) {
        // Prevent spilling off the right
        setLowerSliderPos(moment(x.invert(0)));
        if (maximumSpanInPixels < x(upperSliderPos.toDate())) {
            // If the upper slider has been dragged at all, set its value
            setUpperSliderPos(moment(x.invert(maximumSpanInPixels)));;
        }
        return
    } else if (event.x < x(upperSliderPos.toDate()) - maximumSpanInPixels) {
        // If the upper slider has been dragged and event.x is not 0, set its value
        setLowerSliderPos(moment(x.invert(event.x)));
        setUpperSliderPos(moment(x.invert(event.x + maximumSpanInPixels)));
        return;
    } else if (event.x > x(upperSliderPos.toDate()) - minimumSpanInPixels) {
        // Push the upper slider if required by the defined minimum span
        setLowerSliderPos(moment(x.invert(event.x)));
        setUpperSliderPos(moment(x.invert(event.x + minimumSpanInPixels)));
        return
    } else {
        // Just move the slider
        setLowerSliderPos(moment(x.invert(event.x)));
        return;
    }
}

export function upperSliderDrag(
    event: any,
    x: d3.ScaleTime<number, number, never>,
    selectedIndicator: d3.Selection<SVGRectElement, unknown, null, undefined>,
    lowerSlider: d3.Selection<SVGCircleElement, unknown, null, undefined>,
    upperSlider: d3.Selection<SVGCircleElement, unknown, null, undefined>,
    lowerSliderPos: moment.Moment,
    minimumSpanInPixels: number,
    maximumSpanInPixels: number,
    WIDTH: number,
) {

    if (event.x - minimumSpanInPixels <= 0) {
        // If position is at, or beyond, the left bound of the slider frame, do nothing
        return;
    } else if (event.x >= WIDTH) {
        // If position is at, or beyond, the right bound of the slider frame, do nothing
        return;
    }

    // Resize and re-center the selected window indicator bar
    selectedIndicator.attr('width', event.x - x(lowerSliderPos.toDate()))

    if (event.x - minimumSpanInPixels < x(lowerSliderPos.toDate())) {
        // If we are within the minimum span, then push the upper slider
        selectedIndicator.attr('width', minimumSpanInPixels)
        selectedIndicator.attr('x', event.x - minimumSpanInPixels)
        upperSlider.attr('cx', event.x);
        lowerSlider.attr('cx', event.x - minimumSpanInPixels);
    } else if (event.x - maximumSpanInPixels > x(lowerSliderPos.toDate())) {
        // If we are outisde the maximum span, then pull the upper slider
        selectedIndicator.attr('width', maximumSpanInPixels)
        selectedIndicator.attr('x', event.x - maximumSpanInPixels)
        lowerSlider.attr('cx', event.x - maximumSpanInPixels);
        upperSlider.attr('cx', event.x);
        return
    } else {
        // Just move the slider
        upperSlider.attr('cx', event.x);

    }
}

export function upperSliderDragEnd(
    event: any,
    x: d3.ScaleTime<number, number, never>,
    upperSlider: d3.Selection<SVGCircleElement, unknown, null, undefined>,
    setLowerSliderPos: React.Dispatch<React.SetStateAction<moment.Moment>>,
    lowerSliderPos: moment.Moment,
    setUpperSliderPos: React.Dispatch<React.SetStateAction<moment.Moment>>,
    minimumSpanInPixels: number,
    maximumSpanInPixels: number,
    WIDTH: number,
) {
    if (event.x < minimumSpanInPixels) {
        // Prevent the slider form falling off the edge of the slider after release
        setLowerSliderPos(moment(x.invert(0)));
        setUpperSliderPos(moment(x.invert(minimumSpanInPixels)));
        console.log(1)
        return;
    } else if (event.x >= WIDTH) {
        // Prevent spilling off the right
        setUpperSliderPos(moment(x.invert(WIDTH)));
        console.log(2)
        if (WIDTH - maximumSpanInPixels > x(lowerSliderPos.toDate())) {
            console.log('event')
            console.log(3)
            // If the lower slider has been dragged at all, set its value
            setLowerSliderPos(moment(x.invert(WIDTH - maximumSpanInPixels)));
        }
        return
    } else if (event.x - maximumSpanInPixels > x(lowerSliderPos.toDate())) {
        console.log(4)
        // If the lower slider has been dragged and event.x is not WIDTH, set its value
        setLowerSliderPos(moment(x.invert(event.x - maximumSpanInPixels)));
        setUpperSliderPos(moment(x.invert(event.x)));
    } else if (event.x - minimumSpanInPixels < x(lowerSliderPos)) {
        // Push the lower slider if required by the defined minimum span
        console.log(5)
        setLowerSliderPos(moment(x.invert(event.x - minimumSpanInPixels)))
        // setLowerSliderPos(moment(x.invert(-100)))
        setUpperSliderPos(moment(x.invert(event.x)))
        return
    } else {
        // Just move the slider
        console.log(6)
        setUpperSliderPos(moment(x.invert(event.x)))
    }
}