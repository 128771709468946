import * as React from 'react';
import { RouteComponentProps } from "@reach/router";
import { StackedAreaChart } from '../charts/StackedAreaChart';
import { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import moment from "moment";
import { TimeFrame } from '../TimeFrame';
import './ToiletFrequency.css';

import { useMediaQuery } from 'react-responsive'
import DateSelectionSlider, { dataInputObject } from '../dateSelectionSlider/Slider';
import DateSelectorInput from '../dateSelectionSlider/DateSelectorInput';

import { authProvider } from '../../authProvider';

export interface ToiletFrequencyProps extends RouteComponentProps {
    acsisID: number;
    sliderData: dataInputObject | null,
}

export interface ToiletFrequencyData {
    date: string;
    dayCount: number;
    nightCount: number;
}

export interface ProcessedSleepData {
    x: string;
    y: number;
}

export function ToiletFrequency(props: ToiletFrequencyProps) {

    moment.locale('en-gb');

    const [hasError, setErrors] = useState(false);
    const [toiletFreq, setToiletFreq] = useState<ToiletFrequencyData[]>([]);

    // const [timeFrame, setTimeFrame] = useState({ startDate: moment().subtract(30, 'days'), endDate: moment() });
    //const [timeFrame, setTimeFrame] = useState({ startDate: moment('2019-09-22'), endDate: moment('2019-09-29') });

    const [startDate, setStartDate] = useState(moment().subtract(15, 'days'));
    const [endDate, setEndDate] = useState(moment());
    const [lowerSliderChange, setLowerSliderChange] = useState(0);
    const [upperSliderChange, setUpperSliderChange] = useState(0);
    const [isSliderLocked, setSliderLocked] = useState(false);

    console.log('MobTriggered');
    useEffect(() => {
        console.log('useEffect');

        if (startDate == null || endDate == null) {
            return;
        }

        async function fetchData() {
            console.log('start fetch');
            if (startDate == null || endDate == null) {
                return;
            }
            const res = await fetch(`/api/toiletFrequency?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            res
                .json()
                .then((res: any[]) => {
                    console.log(res);
                    /*
                    let objArray = [{
                        id: "Sleep",
                        color: "hsl(161, 70%, 50%)",
                        //data: res.map((d: ToiletFrequencyData) => ({ x: d.startDateTime.split('T')[0], y: d.duration }))
                    }];*/

                    let toiletFreq: ToiletFrequencyData[]  = [];

                    for (var i = 0; i < res.length; i++) {
                        let dateTime = moment(res[i].dateTime);
                        
                        if (toiletFreq.length > 0 && toiletFreq[toiletFreq.length - 1].date == dateTime.format('DD/MM/YY')) {
                            if (dateTime.hours() < 6 || dateTime.hours() > 10) {
                                toiletFreq[toiletFreq.length - 1].nightCount++;
                            } else {
                                toiletFreq[toiletFreq.length - 1].dayCount++;
                            }
                        } else {
                            if (dateTime.hours() < 6 || dateTime.hours() > 10) {
                                toiletFreq.push({
                                    date: dateTime.format('DD/MM/YY'),
                                    dayCount: 0,
                                    nightCount: 1
                                });
                            } else {
                                toiletFreq.push({
                                    date: dateTime.format('DD/MM/YY'),
                                    dayCount: 1,
                                    nightCount: 0
                                });
                            }
                        }
                    }

                    setToiletFreq(toiletFreq);

                })
                .catch(err => setErrors(err));
        }
        fetchData();
    }, [startDate, endDate]);

    let graphData = null;
    let pieChart = null;
    let total: number[] = [];
    let min, max, avg;
    if (toiletFreq && toiletFreq.length > 0) {

        let pieData = [];

        let keys: string[] = [];
        if (toiletFreq[0].dayCount != null && toiletFreq[0].nightCount != null) {
            keys.push("dayCount", "nightCount");

            let dayCount = toiletFreq.reduce((v, d) => v + d.dayCount, 0);
            if (dayCount > 0) {
                pieData.push({
                    id: "Day",
                    label: "Day",
                    value: dayCount,
                    color: "hsl(119, 70%, 50%)"
                });
            }

            let nightCount = toiletFreq.reduce((v, d) => v + d.nightCount, 0);
            if (nightCount > 0) {
                pieData.push({
                    id: "Night",
                    label: "Night",
                    value: nightCount,
                    color: "hsl(193, 70%, 50%)"
                });
            }

            total = toiletFreq.map(d => d.dayCount + d.nightCount);
        }

        min = Math.round(total.reduce((v, d) => d < v ? d : v, total[0]));
        max = Math.round(total.reduce((v, d) => d > v ? d : v, total[0]));
        avg = Math.round(total.reduce((v, d) => d + v, 0) / total.length * 10) / 10;
        console.log(total, min, max, avg)

        let colourScheme = ["#f8b195", '#355375'];

        pieChart = <ResponsivePie
            data={pieData}
            margin={{ top: 20, right: 20, bottom: 50, left: 20 }}
            colors={colourScheme}
            innerRadius={0.4}
            padAngle={0.7}
            cornerRadius={3}
            enableRadialLabels={true}
            enableSlicesLabels={false}
        />;

        graphData = <ResponsiveBar
            data={toiletFreq}
            indexBy="date"
            keys={keys}
            margin={{ top: 20, right: 20, bottom: 50, left: 50 }}
            axisBottom={{ legend: "Date", legendOffset: 40, format: d => moment(d, 'DD/MM/YY').format('DD/MM'), tickRotation: -50, legendPosition: "middle" }}
            axisLeft={{ legend: "Toilet usage frequency", legendOffset: -30}}
            colors={colourScheme}
            tooltip={d => (<span>{(d.id == 'dayCount' ? 'Day' : 'Night') + ': ' + d.value + ' hours'}</span>)}
            labelTextColor={(d) => {
                console.log(d)
                if (d.color !== "#f8b195") {
                    return 'white'
                } else {
                    return 'black'
                }
            }}
        />;
    }

    return (
        <div className="dashboard-toilet-frequency">
            <div className="dashboardFalls__header shadow corners">
                <div className="dashboardFalls__titleTextContainer">
                    <h1>Toilet<br />Frequency</h1>
                </div>
                <div className="dashboardFalls__sliderContainer" id="sliderContainer">
                    {
                        useMediaQuery({
                            query: '(max-width: 1050px)'
                        }) === false ?
                            <DateSelectionSlider lowerSliderPos={startDate} setLowerSliderPos={setStartDate} upperSliderPos={endDate} setUpperSliderPos={setEndDate} dataInputObject={props.sliderData} minimumDays={15} maximumDays={30} pingLowerUpdate={lowerSliderChange} pingUpperUpdate={upperSliderChange} />
                            :
                            null
                    }
                    <div className="dashboardFalls__entryContainer">
                        <DateSelectorInput sliderPos={startDate} setSliderPos={setStartDate} label="Start DateTime" pingUpdate={setLowerSliderChange} pingCounter={lowerSliderChange} dataInputObject={props.sliderData} />
                        <DateSelectorInput sliderPos={endDate} setSliderPos={setEndDate} label="End DateTime" pingUpdate={setUpperSliderChange} pingCounter={upperSliderChange} dataInputObject={props.sliderData} />
                    </div>
                    {
                        isSliderLocked === true ?
                            <div className="dashboardFalls__lock corners" style={{ height: document.getElementById('sliderContainer')?.clientHeight }} />
                            :
                            null
                    }
                </div>
            </div>            
            <div className="dashboard-toilet-frequency__content" style={{height: "500px"}}>

                <div className="dashboard-toilet-frequency__barchart shadow corners">
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                            {graphData}
                        </div>
                    </div>
                </div>

                <div className="dashboard-toilet-frequency__piechart shadow corners">
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                            {pieChart}
                        </div>
                    </div>
                </div>
                <div className="dashboard-toilet-frequency__stats-panel shadow corners"><span>Fewest: {min}</span></div>
                <div className="dashboard-toilet-frequency__stats-panel shadow corners"><span>Average: {avg}</span></div>
                <div className="dashboard-toilet-frequency__stats-panel shadow corners"><span>Most: {max}</span></div>
            </div>
        </div>
    );
}

export default ToiletFrequency;
