import moment from "moment";
import { generateSentences } from '../sentenceGenerator';
export function medicationFactGenerator(eventArray, personalInformation) {
    let basic = null;
    let urgent = null;
    let returnedArray = [null, null];
    let last24hrsNoMed = false;
    let last48hrsNoMed = false;
    let last72hrsNoMed = false;
    let missedInLastWeek = 0;
    let missedInLast2Months = 0;
    eventArray.map(record => {
        if (record.RuleID == 4002 && moment(record.StartDateTime) > moment().subtract(24, "hour")) {
            last24hrsNoMed = true;
        }
        else if (record.RuleID == 4003 && moment(record.StartDateTime) > moment().subtract(24, "hour")) {
            last24hrsNoMed = true;
            last48hrsNoMed = true;
        }
        else if (record.RuleID == 4004 && moment(record.StartDateTime) > moment().subtract(24, "hour")) {
            last24hrsNoMed = true;
            last48hrsNoMed = true;
            last72hrsNoMed = true;
        }
        else if (record.RuleID == 4001) {
            missedInLast2Months++;
            if (moment(record.StartDateTime) > moment().subtract(7, "day")) {
                missedInLastWeek++;
            }
        }
    });
    let x = null;
    if (last72hrsNoMed) {
        x = 72;
    }
    else if (last48hrsNoMed) {
        x = 48;
    }
    else if (last24hrsNoMed) {
        x = 24;
    }
    if (x) {
        let a = 'not accessed ' + (personalInformation.Gender == "Male" ? 'his' : 'her') + ' medication box';
        let b = x + ' hours';
        urgent = generateSentences(personalInformation.Gender, personalInformation.Name.split(" ")[0], a, b, 0);
    }
    if (missedInLast2Months != 0) {
        let averagePerWeek = missedInLast2Months > 0 ? missedInLast2Months / 8 : 0;
        let c = 'misses ' + (personalInformation.Gender == "Male" ? 'his' : 'her') + ' medication windows';
        if (averagePerWeek < 1) {
            c = c + ' less than once per week';
        }
        else if (Math.floor(averagePerWeek) == 1) {
            c = c + ' once per week';
        }
        else {
            c = c + ' ' + Math.floor(averagePerWeek) + ' times per week';
        }
        let a;
        if (missedInLastWeek == 0) {
            a = 'did not miss any medication windows';
        }
        else if (missedInLastWeek == 1) {
            a = 'missed one medication window';
        }
        else {
            a = 'missed ' + missedInLastWeek + ' medication windows';
        }
        let b = '7 days';
        if (averagePerWeek == 0) {
            b = '8 weeks';
        }
        basic = generateSentences(personalInformation.Gender, personalInformation.Name.split(" ")[0], a, b, 1, c);
    }
    else {
        basic = (personalInformation.Gender == "Male" ? 'He' : 'She') + ' has not missed any medication windows in the last 2 months.';
    }
    return { medicationBasic: basic, medicationUrgent: urgent };
}
