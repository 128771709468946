export function paragraphGenerator(sentenceBranches, personalInfo) {
    let paragraphIntermediate = [];
    let namedSentence = false;
    let paragraph = '';
    for (let i = 0; i < sentenceBranches.length; i++) {
        if (sentenceBranches[i] == null) {
            continue;
        }
        let splitSentence = sentenceBranches[i].split(" ");
        if (namedSentence == false) {
            // Add the name into the first scentence
            if (splitSentence[0] == "In") {
                let index = -1;
                for (let j = 0; j < splitSentence.length; j++) {
                    if (splitSentence[j] == "he" || splitSentence[j] == 'she') {
                        index = j;
                        break;
                    }
                }
                splitSentence[index] = personalInfo.Name.split(" ")[0];
            }
            else {
                splitSentence[0] = personalInfo.Name.split(" ")[0];
            }
            paragraphIntermediate.push(splitSentence.join(" "));
            namedSentence = true;
            continue;
        }
        paragraphIntermediate.push(splitSentence.join(" "));
    }
    paragraphIntermediate.map(sentence => {
        paragraph = paragraph + sentence + ' ';
    });
    return paragraph;
}
