import * as React from 'react';
import { RouteComponentProps } from "@reach/router";
import { StackedAreaChart } from '../charts/StackedAreaChart';
import { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import moment from "moment";
import { TimeFrame } from '../TimeFrame';
import './Sleep.css';
import { ResponsiveLine } from '@nivo/line';
import * as d3 from "d3";
import { group } from "d3-array";
import { authProvider } from '../../authProvider';


export interface TemperatureProps extends RouteComponentProps {
    acsisID: number;
}

export function Temperature(props: TemperatureProps) {
    const [hasError, setErrors] = useState(false);
    const [temperature, setTemperature] = useState<{id: string, color: any, data:any[]}[]>([]);

    //const [timeFrame, setTimeFrame] = useState({ startDate: moment().subtract(30, 'days'), endDate: moment() });
    const [timeFrame, setTimeFrame] = useState({ startDate: moment().subtract(1, 'days'), endDate: moment() });

    console.log('MobTriggered');
    useEffect(() => {
        console.log('useEffect');
        async function fetchData() {
            console.log('start fetch');
            const temperatureRes = await fetch(`/api/temperature?startDate=${timeFrame.startDate.toISOString()}&endDate=${timeFrame.endDate.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            temperatureRes
                .json()
                .then((res: any[]) => {
                    
                    /*
                    let objArray = [{
                        id: "Sleep",
                        color: "hsl(161, 70%, 50%)",
                        data: res.map((d: SleepData) => ({ x: d.startDateTime.split('T')[0], y: d.duration }))
                    }];
                    */
                    let mapped: any[] = [];
                    group(res, v => v.location).forEach((v, k) => {
                        let result = {
                            id: k,
                            color: "hsl(335, 70%, 50%)",
                            data: v.map(d => ({
                                x: new Date(d.startDateTime),
                                y: d.measValue
                            }))
                        };

                        mapped.push(result);
                    });

                    console.log(mapped);
                    mapped.forEach(v => v.data.push({ x: timeFrame.endDate.toDate(), y: v.data[v.data.length - 1].y }));
                    console.log(mapped);
                    setTemperature(mapped);

                })
                .catch(err => setErrors(err));
        }
        fetchData();
    }, [timeFrame]);

    let graphData = null;
    console.log('Temp length', temperature.length, temperature);
    if (temperature.length > 0) {
        graphData = <ResponsiveLine
            data={temperature}
            xScale={{
                type: 'time'
            }}
            yScale={{
                type: 'linear', min: 'auto', max: 'auto'
            }}
            axisBottom={{
                legend: "Date", legendOffset: 40, tickSize: 5, tickPadding: 5, format: d => moment(d).format('HH:mm DD/MM')
            }}
            axisLeft={{ legend: "Temperature (°C)", legendOffset: -30, tickSize: 5, tickPadding: 5 }}
            margin={{ top: 20, right: 120, bottom: 50, left: 50 }}
            enableSlices="x"
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />;
    }

    console.log(graphData);

    return (
        <div className="dashboard-toilet-frequency">
            <div className="dashboard-toilet-frequency__title"><h1>Temperature</h1><div><h2 style={{ display: "inline" }}>Date range </h2><div style={{ display: "inline-block" }}><TimeFrame timeFrame={timeFrame} setTimeFrame={(tf: any) => setTimeFrame(tf)} /></div></div></div>

            <div className="shadow corners" style={{ height: '600px', backgroundColor: '#fff' }}>
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                            {graphData}
                        </div>
                    </div>
                </div>
        </div>
    );
}

export default Temperature;
