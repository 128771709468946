import * as React from 'react';
import { RouteComponentProps } from "@reach/router";
import { StackedAreaChart } from '../charts/StackedAreaChart';
import { useState, useEffect } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { ResponsiveStream } from '@nivo/stream'

import moment from "moment";
import './Sleep.css';

import '../charts/GanttChart.css';
import "../charts/CommonGraph.css";
import Modal from 'react-modal';
import { GanttChart, GanttChartData } from '../charts/GanttChart';
import { NetworkGraphData } from '../charts/NetworkGraph';

import { useMediaQuery } from 'react-responsive'
import DateSelectionSlider, { dataInputObject } from '../dateSelectionSlider/Slider';
import DateSelectorInput from '../dateSelectionSlider/DateSelectorInput';
import { authProvider } from '../../authProvider';


export interface SleepProps extends RouteComponentProps {
    acsisID: number;
    sliderData: dataInputObject | null,
}

export interface SleepData {
    startDateTime: string;
    endDateTime: string;
    duration: number;
}

export interface SleepFrequencyData {
    dateTime: string;
    sleepDay: number;
    sleepNight: number;
}

export interface ProcessedSleepData {
    x: string;
    y: number;
}

export interface ContextModalProps {
    target: any;
    setTarget: Function;
    acsis: number;
}

export function GanttWrapper(props: { target: any; acsisID: number }) {

    const [hasError, setErrors] = useState(false);
    const [mobility, setMobility] = useState<GanttChartData[] | null>(null);
    const [light, setLight] = useState<GanttChartData[] | null>(null);
    const [temperature, setTemperature] = useState<GanttChartData[] | null>(null);
    const [appliance, setAppliance] = useState<GanttChartData[] | null>(null);
    const [door, setDoor] = useState<GanttChartData[] | null>(null);
    const [network, setNetwork] = useState<NetworkGraphData | null>(null);
    const [toggles, setToggles] = useState<any>({});
    const [applianceLocations, setApplianceLocations] = useState<any | null>(null);


    const [timeFrame, setTimeFrame] = useState({ startDate: moment(props.target).add(1, 'day').subtract(12, 'hours'), endDate: moment(props.target).add(1, 'day').add(12, 'hours') });

    useEffect(() => {
        console.log('useEffect');
        async function fetchData() {
            let fixedStart = moment(timeFrame.startDate);
            let fixedEnd = moment(timeFrame.endDate);

            const mobilityRes = fetch(`/api/mobility?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            const lightRes = fetch(`/api/light?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}}`);
            const temperatureRes = fetch(`/api/temperature?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            const applianceRes = fetch(`/api/appliance?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            const doorRes = fetch(`/api/door?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            const networkRes = fetch(`/api/network?acsis=${props.acsisID}`);
            const applianceLocRes = fetch(`/api/applianceLocation?acsis=${props.acsisID}`);

            Promise.all([
                mobilityRes.then(res => res.json()).then((mobilityRes: GanttChartData[]) => {
                    console.log(mobilityRes);
                    setMobility(mobilityRes);
                }).catch(err => setErrors(err)),
                lightRes.then(res => res.json()).then((lightRes: GanttChartData[]) => {
                    console.log(lightRes);
                    setLight(lightRes);
                }).catch(err => setErrors(err)),
                temperatureRes.then(res => res.json()).then((temperatureRes: GanttChartData[]) => {
                    console.log("Finished temp fetch");
                    console.log(temperatureRes);
                    setTemperature(temperatureRes);
                }).catch(err => setErrors(err)),
                applianceRes.then(res => res.json()).then((applianceRes: GanttChartData[]) => {
                    console.log("Finished appliance fetch");
                    console.log(applianceRes);
                    setAppliance(applianceRes);
                }).catch(err => setErrors(err)),
                doorRes.then(res => res.json()).then((doorRes: GanttChartData[]) => {
                    console.log("Finished door fetch");
                    console.log(doorRes);
                    setDoor(doorRes);
                }).catch(err => setErrors(err)),
                networkRes.then(res => res.json()).then((res: NetworkGraphData) => {
                    console.log(res);
                    setNetwork(res);
                }).catch(err => setErrors(err)),
                applianceLocRes.then(res => res.json()).then((res: any) => {
                    console.log(res);
                    setApplianceLocations(res);
                }).catch(err => setErrors(err))
            ]);
        }
        fetchData();
    }, [timeFrame]);

    return <GanttChart live={false} setLive={() => { }} toggles={toggles} timeFrame={timeFrame} setTimeFrame={setTimeFrame} data={mobility} lightData={light} temperatureData={temperature} applianceData={appliance} doorData={door} network={network} applianceLocations={applianceLocations} width={1600} height={800} />
}

export function ContextModal(props: ContextModalProps) {
    return (
        <Modal style={{ overlay: { backgroundColor: 'rgba(200, 200, 200, 0.75)' }, content: { inset: '100px', border: 'none', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px inset' } }} isOpen={props.target != null} contentLabel="Event context" onRequestClose={() => { props.setTarget(null) }}>
            <h1>Sleep data for selected day</h1>
            <h2>Surrounding activity</h2>
            {props.target != null && <GanttWrapper acsisID={props.acsis} target={props.target} />}
        </Modal>
    );
}

export function Sleep(props: SleepProps) {
    const [hasError, setErrors] = useState(false);
    const [sleep, setSleep] = useState<SleepFrequencyData[]>([]);
    const [dayAndNight, setDayAndNight] = useState<any | null>(null);

    const [modalTarget, setModalTarget] = useState<any | null>(null);

    const [timeFrame, setTimeFrame] = useState({ startDate: moment().subtract(30, 'days'), endDate: moment() });
    //const [timeFrame, setTimeFrame] = useState({ startDate: moment('2019-09-22'), endDate: moment('2019-09-29') });

    const [startDate, setStartDate] = useState(moment().subtract(15, 'days'));
    const [endDate, setEndDate] = useState(moment());
    const [lowerSliderChange, setLowerSliderChange] = useState(0);
    const [upperSliderChange, setUpperSliderChange] = useState(0);
    const [isSliderLocked, setSliderLocked] = useState(false);

    console.log('MobTriggered');
    useEffect(() => {
        console.log('useEffect');
        async function fetchData() {
            console.log('start fetch');
            if (startDate == null || endDate == null) {
                return;
            }
            setSliderLocked(true);
            const res = await fetch(`/api/sleep?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});//&acsis=${props.acsisID}`);
            res
                .json()
                .then((res: SleepFrequencyData[]) => {
                    console.log(res);
                    /*
                    let objArray = [{
                        id: "Sleep",
                        color: "hsl(161, 70%, 50%)",
                        data: res.map((d: SleepData) => ({ x: d.startDateTime.split('T')[0], y: d.duration }))
                    }];
                    */
                    for (var i = 0; i < res.length; i++) {
                        res[i].sleepDay = Math.round((res[i].sleepDay / 60) / 6) / 10;
                        res[i].sleepNight = Math.round((res[i].sleepNight / 60) / 6) / 10;

                    }
                    setSleep(res);

                })
                .catch(err => setErrors(err));

            const res2 = await fetch(`/api/dayAndNightActivity?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});//&acsis=${props.acsisID}`);
            res2
                .json()
                .then((res: any[]) => {
                    console.log(res);
                    /*
                    let objArray = [{
                        id: "Sleep",
                        color: "hsl(161, 70%, 50%)",
                        data: res.map((d: SleepData) => ({ x: d.startDateTime.split('T')[0], y: d.duration }))
                    }];
                    */
                    let mappedRes = res[0].data.map((d: any, i: any) => {

                        return {
                            "Active Minutes": d[0].value,
                            "Room Transitions": d[1].value,
                            "Room Scope": d[2].value,
                            "Door Use": d[3].value
                        };
                    })
                    setDayAndNight(mappedRes);
                })
                .catch(err => setErrors(err));
            setSliderLocked(false);
        }
        fetchData();
    }, [startDate, endDate]);

    let graphData = null;
    let pieChart = null;
    let areaChart = null;
    let total: number[] = [];
    let min, max, avg;
    if (sleep && sleep.length > 0) {

        let pieData = [];

        let keys: string[] = [];
        if (sleep[0].sleepDay != null && sleep[0].sleepNight != null) {
            keys.push("sleepNight", "sleepDay");

            let awakeCount = sleep.reduce((v, d) => v + d.sleepDay, 0);
            if (awakeCount > 0) {
                pieData.push({
                    id: "Day",
                    label: "Day",
                    value: Math.round(awakeCount),
                    color: "hsl(119, 70%, 50%)"
                });
            }

            let asleepCount = sleep.reduce((v, d) => v + d.sleepNight, 0);
            if (asleepCount > 0) {
                pieData.push({
                    id: "Night",
                    label: "Night",
                    value: Math.round(asleepCount),
                    color: "hsl(193, 70%, 50%)"
                });
            }

            total = sleep.map(d => d.sleepDay + d.sleepNight);
            /*total.reduce((v, d) => {
                if (v[d]) {
                    v[d]++;
                } else {
                    v[d] = 0;
                }
            }, []);*/

        }

        min = Math.round(total.reduce((v, d) => d < v ? d : v, total[0]));
        max = Math.round(total.reduce((v, d) => d > v ? d : v, total[0]));
        avg = Math.round(total.reduce((v, d) => d + v, 0) / total.length * 10) / 10;
        console.log(total, min, max, avg)

        let colourScheme = ['#355375', "#f8b195"];

        pieChart = <ResponsivePie
            data={pieData}
            margin={{ top: 50, right: 30, bottom: 50, left: 30 }}
            colors={[colourScheme[1], colourScheme[0]]}
            innerRadius={0.4}
            padAngle={0.7}
            cornerRadius={3}
            enableRadialLabels={true}
            enableSlicesLabels={false}
        />;

        graphData = <ResponsiveBar
            data={sleep}
            indexBy="dateTime"
            keys={keys}
            labelTextColor={(d) => {
                console.log(d)
                if (d.color !== "#f8b195") {
                    return 'white'
                } else {
                    return 'black'
                }
            }}
            margin={{ top: 20, right: 20, bottom: 50, left: 50 }}
            axisBottom={{ legend: "Date", legendOffset: 40, format: d => moment(d).format('DD/MM'), tickRotation: -50, legendPosition: "middle" }}
            axisLeft={{ legend: "Hours slept", legendOffset: -30 }}
            colors={colourScheme}
            onClick={d => setModalTarget(d.data.dateTime)}
            tooltip={d => (<span>{(d.id == 'sleepDay' ? 'Day' : 'Night') + ': ' + d.value + ' hours'}</span>)}
        />;
    }

    if (dayAndNight != null && dayAndNight != []) {
        areaChart = <ResponsiveStream
            data={dayAndNight}
            keys={['Time spent active', 'Room transitions', 'Span of rooms visited', 'Door usage']}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: 36,
                format: (d: any) => ((d as number % 3) == 0 ? moment(startDate).set("hour", 0).set("minute", 0).set("second", 0).add(d as number * 20, 'minute').format('HH:mm') : '')
            }}
            offsetType="none"
            axisLeft={{ orient: 'left', tickSize: 5, tickPadding: 5, tickRotation: 0, legend: '', legendOffset: -40 }}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    translateX: 100,
                    itemWidth: 80,
                    itemHeight: 20,
                    itemTextColor: '#999999',
                    symbolSize: 12,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000000'
                            }
                        }
                    ]
                }
            ]}
            margin={{ top: 10, right: 150, bottom: 20, left: 25 }}
            
        />
    }

    return (
        <div className="dashboard-toilet-frequency">
            {/* <div className="dashboard-toilet-frequency__title"><h1>Sleep</h1><div><h2 style={{ display: "inline" }}>Date range </h2><div style={{ display: "inline-block" }}><TimeFrame timeFrame={timeFrame} setTimeFrame={(tf: any) => setTimeFrame(tf)} /></div></div></div> */}
            <div className="dashboardSleep__header shadow corners">
                <div className="dashboardSleep__titleTextContainer">
                    <h1>Sleep</h1>
                </div>
                <div className="dashboardSleep__sliderContainer" id="sliderContainer">
                    {
                        useMediaQuery({
                            query: '(max-width: 1050px)'
                        }) === false ?
                            <DateSelectionSlider lowerSliderPos={startDate} setLowerSliderPos={setStartDate} upperSliderPos={endDate} setUpperSliderPos={setEndDate} dataInputObject={props.sliderData} minimumDays={15} maximumDays={30} pingLowerUpdate={lowerSliderChange} pingUpperUpdate={upperSliderChange} />
                            :
                            null
                    }
                    <div className="dashboardSleep__entryContainer">
                        <DateSelectorInput sliderPos={startDate} setSliderPos={setStartDate} label="Start DateTime" pingUpdate={setLowerSliderChange} pingCounter={lowerSliderChange} dataInputObject={props.sliderData} />
                        <DateSelectorInput sliderPos={endDate} setSliderPos={setEndDate} label="End DateTime" pingUpdate={setUpperSliderChange} pingCounter={upperSliderChange} dataInputObject={props.sliderData} />
                    </div>
                    {
                        isSliderLocked === true ?
                            <div className="dashboardSleep__lock corners" style={{ height: document.getElementById('sliderContainer')?.clientHeight }} />
                            :
                            null
                    }
                </div>
            </div>
            
            
            <div className="dashboard-toilet-frequency__content" style={{ height: "500px" }}>

                <div className="dashboard-toilet-frequency__barchart shadow corners">
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                            {graphData}
                        </div>
                    </div>
                </div>

                <div className="dashboard-toilet-frequency__piechart shadow corners">
                    <span style={{fontSize: '120%'}}>Sleep distrubution</span>
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                            {pieChart}
                        </div>
                    </div>
                </div>
                <div className="dashboard-sleep__stats-panel shadow corners"><span>Shortest: {min}<br />Average: {avg}<br />Longest: {max}</span></div>
                <div className="dashboard-sleep__bottom-graph shadow corners">
                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                        <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                            {areaChart}
                        </div>
                    </div>
                </div>
            </div>
            <ContextModal setTarget={setModalTarget} target={modalTarget} acsis={props.acsisID} />
        </div>
    ); 
}

export default Sleep;
