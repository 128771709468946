import * as React from 'react';
import { RouteComponentProps } from "@reach/router";
import { StackedAreaChart } from '../charts/StackedAreaChart';
import { useState, useEffect, useRef } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import moment from "moment";
import { TimeFrame } from '../TimeFrame';
import './Sleep.css';
import { ResponsiveLine } from '@nivo/line';
import * as d3 from "d3";
import { group } from "d3-array";
import { scaleTime, scaleBand, scaleOrdinal, axisBottom, axisLeft } from 'd3';
import { GanttChartProcessedData } from '../charts/GanttChart';
import { authProvider } from '../../authProvider';


export interface LightProps extends RouteComponentProps {
    acsisID: number;
}

export function Light(props: LightProps) {
    const [hasError, setErrors] = useState(false);
    const [light, setLight] = useState<{ id: string, color: any, data: any[] }[]>([]);
    const d3Container = useRef(null);
    const [firstDraw, setFirstDraw] = useState(true);

    //const [timeFrame, setTimeFrame] = useState({ startDate: moment().subtract(30, 'days'), endDate: moment() });
    const [timeFrame, setTimeFrame] = useState({ startDate: moment().subtract(1, 'days'), endDate: moment() });

    console.log('MobTriggered');
    useEffect(() => {
        console.log('useEffect');
        async function fetchData() {
            console.log('start fetch');
            const lightRes = await fetch(`/api/light?startDate=${timeFrame.startDate.toISOString()}&endDate=${timeFrame.endDate.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            lightRes
                .json()
                .then((res: any[]) => {
                    
                    /*
                    let objArray = [{
                        id: "Sleep",
                        color: "hsl(161, 70%, 50%)",
                        data: res.map((d: SleepData) => ({ x: d.startDateTime.split('T')[0], y: d.duration }))
                    }];
                    */
                    let mapped: any[] = [];
                    group(res, v => v.location).forEach((v, k) => {
                        let result = {
                            id: k,
                            color: "hsl(335, 70%, 50%)",
                            data: v.map(d => ({
                                x: new Date(d.startDateTime),
                                y: d.measValue
                            }))
                        };

                        mapped.push(result);
                    });

                    console.log(mapped);
                    mapped.forEach(v => v.data.push({ x: timeFrame.endDate.toDate(), y: v.data[v.data.length - 1].y }));
                    console.log(mapped);
                    setLight(mapped);





                    if (res && d3Container.current) {
                        const svg = d3.select(d3Container.current);
                        const margin = { left: 100, top: 10, right: 10, bottom: 20 };
                        const width = 1400;
                        const height = 400;

                        console.log(res);

                        // TODO: Replace this with a better update method
                        //svg.selectAll('*').remove();

                        let startDate, endDate;

                        startDate = timeFrame.startDate.toDate() as Date;
                        endDate = timeFrame.endDate.toDate() as Date;


                        let startDates: Date[] = [];
                        let endDates: Date[] = [];

                        let preparedLightData: GanttChartProcessedData[] = [];
                        if (res) {
                            preparedLightData = res.map((d, i) => {

                                if (d.startDateTime == null || d.endDateTime == null) {
                                    return null;
                                }
                                let start = new Date(d.startDateTime);
                                let end = new Date(d.endDateTime);

                                // TODO: Decide if this is needed for light data.
                                startDates.push(start);
                                endDates.push(end);

                                return {
                                    startDateTime: start,
                                    endDateTime: end,
                                    location: d.location,
                                    duration: d.duration,
                                    measValue: d.measValue
                                };
                            }).filter(d => d ? true : false) as GanttChartProcessedData[];
                        }

                        console.log("preparedLightData", preparedLightData);


                        let x = scaleTime()
                            .domain([startDate, endDate])
                            .range([margin.left, width - margin.right])
                            .clamp(true);

                        // New yEntries to accomodate doors
                        //let yArray = d3.map(props.data, d => d.location).keys();            

                        //let yArray = new Map(preparedLightData.map(d => d.location)).keys();

                        let yEntries = new Set(preparedLightData.map(d => d.location));

                        //let yEntries = d3.map(props.data, d => d.location).keys();
                        let y = scaleBand()
                            .domain(yEntries)
                            .range([height - margin.bottom, margin.top]);
                        //let yColour = scaleOrdinal(Tableau10)
                        let yColour = scaleOrdinal(['#f1faee', '#a8dadc'])
                            .domain(yEntries);

                        let xAxis = (g: any) => g
                            .attr("transform", `translate(0,${height - margin.bottom})`)
                            .transition().duration(1000)
                            .call(axisBottom(x).ticks(width / 80).tickSizeOuter(0));

                        let yAxis = (g: any) => g
                            .attr("transform", `translate(${margin.left},0)`)
                            .call(axisLeft(y).tickSize(0));

                        if (firstDraw) {
                            let backBar = svg.selectAll("g.backBar").data(yEntries) as any;

                            let backBarGroup = backBar.join("g")
                                .classed("backBar", true)
                                .attr("transform", (d: any) => `translate(0,${y(d)})`);

                            backBarGroup.append("rect")
                                .attr("fill", (d: any) => yColour(d))
                                .attr("x", margin.left)
                                .attr("width", width - margin.right - margin.left)
                                //.attr("y", 5)
                                .attr("height", y.bandwidth())
                                .style('opacity', 0.1);

                            backBarGroup.append("line")
                                .attr('x1', margin.left)
                                .attr('x2', width - margin.right)
                                .attr('stroke', '#aaa')
                                .style('stroke-dasharray', '3,3');
                        }

                        const lightBarData = svg.selectAll("g.lightBar")
                            .data(preparedLightData);
                        console.log(lightBarData);

                        const lightBar = (lightBarData as any).join("g")
                            .classed("lightBar", true)
                            .attr("transform", (d: GanttChartProcessedData) => `translate(0,${y(d.location)})`);


                        const lightScale = d3.scaleLinear().domain([0, 600]).range(["#000055" as any, "#ffbf00" as any]).clamp(true);

                        lightBar.append("rect")
                            .attr("fill", (d: any) => lightScale(d.measValue))
                            .attr("x", (d: GanttChartProcessedData) => x(d.startDateTime))
                            .attr("width", (d: GanttChartProcessedData) => (x(d.endDateTime) as number) - (x(d.startDateTime) as number))
                            .attr("y", (y.bandwidth() * 0.2))
                            .attr("height", y.bandwidth() * 0.6)
                            .on("mousemove", (event: any, d: any, i: number) => {
                                //console.log('move', , d3.event.pageY);

                                //tip.html(JSON.stringify(d, null, "  "))
                                /*    .style("left", 500)
                                    .style("top", 500);*/
                            });

                        /*const barData = svg.selectAll("g.bar")
                            .data(preparedData);
                        console.log(barData);*/

                        /*const bar = (barData as any).join("g")
                            .classed("bar", true)
                            .attr("transform", (d: GanttChartProcessedData) => `translate(0,${y(d.location)})`);
                            */

                        //let tooltip: d3.Selection<d3.BaseType, unknown, HTMLElement, any> | d3.Selection<HTMLDivElement, unknown, HTMLElement, any>;
                        if (firstDraw) {
                            d3.select("body")
                                .append("div")
                                .classed('graph-tooltip', true)
                                .style("position", "absolute")
                                .style("visibility", "hidden")
                                .style("padding", "5px")
                                .style("border-radius", "3px")
                                .style("background-color", '#fff')
                                .style("color", '#000')
                                .classed("shadow", true);
                            //.text("");
                        }

                        let tooltip = d3.select(".graph-tooltip");

                        /*

                        let lineFunc = d3.line()
                            .x((d: any) => { console.log(d, x(d.endDateTime), y(d.location)); return x(d.endDateTime); })
                            .y((d: any) => y(d.location) as number + y.bandwidth() / 2)
                            .curve(d3.curveStepBefore);

                        if (firstDraw) {
                            svg.append("path")
                                .classed('location-path', true)
                                .attr("fill", "none")
                                .attr("stroke", "#aaa")
                                .attr("stroke-width", 1);

                            svg.append("g")
                                .classed('axis', true)
                                .classed('x-axis', true)

                            svg.append("g")
                                .classed('axis', true)
                                .classed('y-axis', true)
                        }

                        d3.select('path.location-path')
                            .datum(preparedData)
                            .transition().duration(1000)
                            .attr("d", lineFunc(preparedData as any) as string);

                        */

                        if (firstDraw) {
                            svg.append("g")
                                .classed('axis', true)
                                .classed('x-axis', true)

                            svg.append("g")
                                .classed('axis', true)
                                .classed('y-axis', true)
                        }

                        d3.select('g.x-axis')
                            .call(xAxis);

                        d3.select('g.y-axis')
                            .call(yAxis);
                            
                        let selectionArea: d3.Selection<SVGRectElement, any, any, any>;
                        let selectionX = 0;

                        const dragstarted = function (event: any) {
                            let mouse = d3.pointer(event);
                            console.log('dragstarted');
                            selectionX = mouse[0];

                            selectionArea = svg.append("rect")
                                .attr("x", selectionX)
                                .attr("y", margin.top)
                                .attr("width", 0)
                                .attr("height", height - margin.bottom - margin.top)
                                .attr("fill", "#222222")
                                .attr("fill-opacity", 0.3);

                        }

                        const dragged = function (event: any) {
                            let mouse = d3.pointer(event);
                            let diff = mouse[0] - selectionX;

                            if (diff >= 0) {
                                selectionArea.attr("width", diff);
                            } else {
                                let absDiff = Math.abs(diff);
                                selectionArea.attr("x", selectionX - absDiff);
                                selectionArea.attr("width", absDiff);
                            }

                        }

                        const dragended = function (event: any) {
                            let mouse = d3.pointer(event);
                            selectionArea.remove();
                            console.log("dragend", selectionX, mouse[0], mouse[0] - selectionX);
                            //console.log("dragend", x.invert(selectionX), x.invert(d3.event.x), d3.event.x - selectionX);

                            if (Math.abs(mouse[0] - selectionX) > 20) {
                                setTimeFrame({
                                    startDate: moment(x.invert(selectionX)), endDate: moment(x.invert(mouse[0]))
                                });
                            }

                            /*
                            props.data = props.data ? props.data.filter(d => {
                                return (new Date(d.endDateTime) > x.invert(selectionX)) && (new Date(d.startDateTime) < x.invert(d3.event.x));
                            }) : null;
                            */
                        }
                        //if (!props.live) {
                        svg.call(d3.drag()
                            .on("start", dragstarted)
                            .on("drag", dragged)
                            .on("end", dragended) as any);
                        //}
                        setFirstDraw(false);
                    }

                })
                .catch(err => setErrors(err));
        }
        fetchData();
    }, [timeFrame]);

    let graphData = null;
    let barData = null;
    console.log('Light length', light.length, light);
    if (light.length > 0) {
        graphData = <ResponsiveLine
            data={light}
            xScale={{
                type: 'time'
            }}
            yScale={{
                type: 'linear', min: 'auto', max: 'auto'
            }}
            axisBottom={{
                legend: "Date", legendOffset: 40, tickSize: 5, tickPadding: 5, format: d => moment(d).format('HH:mm DD/MM')
            }}
            axisLeft={{ legend: "Luight (lux)", legendOffset: -30, tickSize: 5, tickPadding: 5 }}
            margin={{ top: 20, right: 120, bottom: 50, left: 100 }}
            enableSlices="x"
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
        />;

    }

    console.log(graphData);

    return (
        <div className="dashboard-toilet-frequency">
            <div className="dashboard-toilet-frequency__title"><h1>Light</h1><div><h2 style={{ display: "inline" }}>Date range </h2><div style={{ display: "inline-block" }}><TimeFrame timeFrame={timeFrame} setTimeFrame={(tf: any) => setTimeFrame(tf)} /></div></div></div>
            <div className="shadow corners" style={{ height: '600px', backgroundColor: '#fff' }}>
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                    <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                        <svg
                            className="gantt-chart"
                            viewBox="0 0 1450 450"
                            ref={d3Container}
                        />
                    </div>
                </div>
            </div>
            <div className="shadow corners" style={{ height: '600px', backgroundColor: '#fff' }}>
                <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                    <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                        {graphData}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Light;
