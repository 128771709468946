import * as React from 'react';
import './styles.css';
import {trueFalseProps} from './types'
import {GridComponent} from '../../basic/gridComponent/gridComponent';
import Tile from '../../basic/tile/tile';
import {trueFalse} from '../../const';
import IndexTile from '../../basic/indexTile/indexTile'

export function TrueFalse(props: trueFalseProps) {
    let children = [<IndexTile header={false} text={props.question} missed={props.missed} additionalInfo={props.additionalInfo}/>];
    let colorArray: string[] = []
    if (props.inverse === true) {
        
    }
    for (let i=0; i < 2; i++) {
        children.push(
            <Tile header={false} index={i} text={props.answers[i]} selection={props.selection} setSelection={props.setSelection} setMissed={props.setMissed} color={trueFalse[(props.inverse === false ? i : 1 - i)]} editable={props.editable} existingScores={props.existingScores} questionName={props.questionName}/>
        )
    }
    return ( 
        <GridComponent size={5} bottom={props.bottom}>
            {children}

        </GridComponent>
    )
}

export default TrueFalse;