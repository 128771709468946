import regression from "regression";
export function mobilityFactGenerator(moblityData, personalInformation) {
    let mobilityTileColour = null;
    let basic = null;
    let urgent = null;
    let pronoun = (personalInformation.Gender == "Male" ? 'He' : 'She');
    /*
    In order to generate sentences and drive a colour from the mobility data, the array is to be sliced in 2, and 2 individual gradients are generated
    */
    let firstHalf = [];
    let secondHalf = [];
    for (let i = 0; i < 30; i++) {
        if (i < 15) {
            firstHalf.push([i, moblityData[i]]);
        }
        else {
            secondHalf.push([i, moblityData[i]]);
        }
    }
    let first = classifyGradient(regression.linear(firstHalf).equation[0]);
    let second = classifyGradient(regression.linear(secondHalf).equation[0]);
    if (first == 1) {
        if (second == 1 || second == 0) {
            mobilityTileColour = 'green';
            basic = pronoun + ' has shown improved mobility over the last 30 days.';
        }
        else {
            mobilityTileColour = 'yellow';
            basic = pronoun + ' has shown improved mobility over a 15 day period, however has begun to show a decline.';
        }
    }
    else if (first == 0) {
        if (second == 1) {
            mobilityTileColour = 'green';
            basic = pronoun + ' has shown improved mobility over the last 30 days.';
        }
        else if (second == 0) {
            mobilityTileColour = 'green';
            basic = pronoun + ' has maintained a steady level of mobility over the last 30 days.';
        }
        else {
            mobilityTileColour = 'yellow';
            basic = pronoun + ' has recently begun to show a decline in levels of mobility.';
        }
    }
    else {
        if (second == 1) {
            mobilityTileColour = 'green';
            basic = pronoun + ' has shown improved levels of mobility over the last 15 days.';
        }
        else if (second == 0) {
            mobilityTileColour = 'yellow';
            basic = pronoun + ' has shown reduced levels of mobility over the last 30 days.';
        }
        else {
            mobilityTileColour = 'red';
            basic = pronoun + ' has shown consistently reduced levels of mobility over the last 30 days.';
        }
    }
    return { mobilityBasic: basic, mobilityUrgent: urgent, mobilityTileColour };
}
function classifyGradient(gradient) {
    let upperCentralBound = 0.2;
    let lowerCentralBound = -0.2;
    let output = 0;
    if (gradient > upperCentralBound) {
        output = 1;
    }
    else if (gradient < lowerCentralBound) {
        output = -1;
    }
    return output;
}
