export function sleepFactGenerator(eventArray, personalInformation) {
    // Only 1 event type exists for this sentence at present,
    // therefore scentneces will look like 'in the last 2 weeks he/she has not had any issues with their sleep"
    // or "in the last 2 weeks he has had less than 4 hours sleep (one time / x times)"
    let basic = '';
    let urgent = '';
    if (eventArray.length == 0) {
        basic = (personalInformation.Gender == "Male" ? "he" : "she") + ' has had no issues with their sleep in the last 2 weeks.';
    }
    else if (eventArray.length == 1) {
        basic = (personalInformation.Gender == "Male" ? "he" : "she") + ' has slept for less than 4 hours once in the last 2 weeks';
    }
    else {
        basic = (personalInformation.Gender == "Male" ? "he" : "she") + ' has slept for less than 4 hours ' + eventArray.length + ' times in the last 2 weeks.';
    }
    return { sleepBasic: basic, sleepUrgent: urgent };
}
