import * as React from 'react';
import UserTile from './UserTile';
import { ImageCircleBorder } from './ImageCircle';
import { RouteComponentProps } from "@reach/router";
import './ServiceUsers.css';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { authProvider } from '../authProvider';


export function ServiceUsers(props: RouteComponentProps) {
    const [hasError, setErrors] = useState(false);
    const [userItems, setUserItems] = useState<JSX.Element[] | null>(null);
    const [showWatchlist, setShowWatchlist] = useState(false);
    const [searchTerm, setSearchTerm] = useState(''); 
 
    useEffect(() => { 
 
        async function fetchData() {
            console.log('start fetch');
            const res = await fetch(`/api/serviceUsers`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            res
                .json()
                .then((res: any[]) => {
                    console.log(res);
                    console.log(searchTerm);

                    let mappedRes = res.filter(v => {
                        let search = v.acsis.toString().includes(searchTerm) || v.name.includes(searchTerm);
                        let watched = false;

                        console.log(search)

                        let watchStore = localStorage.getItem('star-' + v.acsis);

                        if (watchStore != null) {
                            let watch = JSON.parse(watchStore);
                            watched = watch.star;
                        }

                        return search && (!showWatchlist || watched);
                    }).map((value: any, i) => {
                        console.log(i);

                        //return <UserTile key={value.acsis} imageURL={process.env.PUBLIC_URL + "/UserImages/" + (value.gender == 'F' ? 'female-solid.svg' : 'male-solid.svg')} fullName={value.firstName.split(' ')[0] + ' ' + value.lastName} gender={(value.gender == 'F' ? 'Female' : (value.gender == 'M' ? 'Male' : 'Unspecified'))} age={moment().diff(value.dateOfBirth, 'years')} acsis={value.acsis} status={1} />
                        return <UserTile key={value.acsis} imageURL={process.env.PUBLIC_URL + "/UserImages/" + (value.gender == 'F' ? 'female-solid.svg' : 'male-solid.svg')} fullName={value.firstName + ' ' + value.lastName} gender={(value.gender == 'F' ? 'Female' : (value.gender == 'M' ? 'Male' : 'Unspecified'))} age={moment().diff(value.dateOfBirth, 'years')} acsis={value.acsis} status={1} />

                        // return <UserTile key={intVal} imageURL={process.env.PUBLIC_URL + "/UserImages/user000" + Math.floor(10 + Math.random() * 89).toString() + ".256.jpeg"} fullName="John Smith" gender={(Math.random() > 0.5 ? "Male" : "Female")} age={Math.floor(60 + Math.random() * 40)} acsis={intVal} status={Math.floor(Math.random() * 4)} />
                    });

                    setUserItems(mappedRes);
                })
                .catch(err => setErrors(err));
        }
        fetchData();


    }, [searchTerm, showWatchlist]);

    let updateSearch = (event: any) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div className="service-users">
            <div className="service-users__filters">
                <h2><span style={{ cursor: 'pointer' }} className={showWatchlist ? '' : 'selected'} onClick={() => setShowWatchlist(false)}>All</span> | <span style={{ cursor: 'pointer' }} className={showWatchlist ? 'selected' : ''} onClick={() => setShowWatchlist(true)}>Watchlist</span></h2>
                <div>
                    <h2 style={{ display: "inline" }}>Search </h2>
                    <div style={{ display: "inline-block" }}><input className="service-users__search-input shadow" value={searchTerm} onInput={updateSearch} />
                    </div>
                </div>
            </div>
            <div className="service-users__grid">
                {userItems}
            </div>
        </div>
    );
}

export default ServiceUsers;

// TODO: Look at making the search div a label