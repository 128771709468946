import * as d3 from 'd3';
import moment from 'moment'
// import {data as line1} from '../data/mobilitySIngleLine';
// import {data as line2} from '../data/mobilityLine2'
//import d3Tip from 'd3-tip';
import React, {useRef, useEffect, useState} from 'react'
import { dataInputObject } from '../dateSelectionSlider/Slider';

import { getTsBuildInfoEmitOutputFilePath } from 'typescript';
import { Console } from 'console';
import { inflate } from 'zlib';

export interface dataPoint {
    key: string,
    x: moment.Moment
    y: number
}

interface mobilityIndexgraphProps {
    data: dataPoint[]
    bounds: [number, number] | null
    children?: any,
    setLock: React.Dispatch<React.SetStateAction<boolean>>,
    startDate: moment.Moment;
    endDate: moment.Moment;
}


export function MobilityIndexGraph(props: mobilityIndexgraphProps) {
    const d3Container = useRef(null);
    const [firstDraw, setFirstDraw] = useState(true);

    const [inFlight, setInflight] = useState(0);
    let drawing = 0;

    //const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (props.data && props.bounds != null) {
            console.log(props.data)
            // setInflight(inFlight + 1);
            drawing ++
            d3.select(d3Container.current).selectAll('*').remove()

            let trendReal: dataPoint[] = [];
            let trendProjected: dataPoint[] = [];
            let varianceReal: dataPoint[] = [];
            let varianceProjected: dataPoint[] = [];

            for (let i = 0; i < props.data.length; i++) {
                props.data[i].x = moment(props.data[i].x)
                if (props.data[i].key === 'trendReal') {
                    trendReal.push(props.data[i])
                }
                if (props.data[i].key === 'trendProjected') {
                    trendProjected.push(props.data[i])
                }
                if (props.data[i].key === 'varianceReal') {
                    varianceReal.push(props.data[i])
                }
                if (props.data[i].key === 'varianceProjected') {
                    varianceProjected.push(props.data[i])
                }
            }

            const BARHEIGHT = 75;

            const MARGIN = { LEFT: 40, RIGHT: 10, TOP: 10, BOTTOM: 30 };
            const WIDTH = 1000 - MARGIN.LEFT - MARGIN.RIGHT;
            const HEIGHT = 450 - MARGIN.TOP - MARGIN.BOTTOM;


            const trimmedDays = 0;
            if (trendReal.length > trimmedDays) {
                trendReal = trendReal.slice(trimmedDays);
            }
            if (varianceReal.length > trimmedDays) {
                varianceReal = varianceReal.slice(trimmedDays);
            }
            // varianceReal = varianceReal.slice(trimmedDays);

            const dataLength = trendReal.length + trendProjected.length;

            let minY = [...trendReal, ...trendProjected, ...varianceReal, ...varianceProjected].reduce((a: number, c: dataPoint) => {
                if (a < c.y) {
                    return a;
                };
                return c.y;
            }, 0);

            let maxY = [...trendReal, ...trendProjected, ...varianceReal, ...varianceProjected].reduce((a: number, c: dataPoint) => {
                if (a > c.y) {
                    return a;
                };
                return c.y;
            }, 0);


            let trendJoin = [...trendReal, ...trendProjected];
            // var xScale = d3.scaleTime()
            //     .domain([trendJoin[0].x, trendJoin[trendJoin.length - 1].x])
            //     .range([0, WIDTH]);
            var xScale = d3.scaleTime()
                .domain([moment(props.startDate), props.endDate])
                .range([0, WIDTH]);

            // adding a scale to the yScale min value to ensure that there is a consistent gap at the bottom of the graph (15% of the total)
            let range = maxY - minY;
            let barHeight = 0.1;
            var yScale = d3.scaleLinear()
                .domain([minY - (range * barHeight), maxY])
                .range([HEIGHT, 0])
            let barSpace = (yScale(minY) - yScale(minY - (range * barHeight)))

            console.log(props.bounds);

            let colorMaxY = maxY;
            if (colorMaxY < props.bounds[1]) {
                colorMaxY = props.bounds[1]
            }
            let colorMinY = minY;
            if (colorMinY > props.bounds[0]) {
                colorMinY = props.bounds[0]
            }
            let colorRange = colorMaxY - colorMinY

            // Quantise scale is used for the colour gradient
            var colorScale = d3.scaleLinear<string>()
                // .domain([5, 0, -5])
                .domain([colorMaxY, (colorMaxY - (colorRange * 0.5)), colorMinY])
                .range(['#009900', '#FFBF00', '#BB0000'])

            const svg = d3.select(d3Container.current).append('svg')
                .attr('width', WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
                .attr('height', HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
                .append('g')
                .attr('transform', `translate(${MARGIN.LEFT}, ${MARGIN.TOP})`)
                .attr('position', 'relative')
                .attr('fill', 'blue')
                .attr('id', 'canvas')


            let areaReal: {
                key: 'areaReal',
                upper: number,
                lower: number,
                x: moment.Moment
            }[] = [];
            for (let i = 0; i < trendReal.length; i++) {
                if (trendReal[i].y > varianceReal[i].y) {
                    areaReal.push({
                        key: 'areaReal',
                        upper: trendReal[i].y,
                        lower: varianceReal[i].y,
                        x: trendReal[i].x
                    })
                } else {
                    areaReal.push({
                        key: 'areaReal',
                        upper: varianceReal[i].y,
                        lower: trendReal[i].y,
                        x: trendReal[i].x
                    })
                }
            }
            let areaProjected: {
                key: 'areaProjected',
                upper: number,
                lower: number,
                x: moment.Moment
            }[] = [];
            for (let i = 0; i < trendProjected.length; i++) {
                if (trendProjected[i].y > varianceProjected[i].y) {
                    areaProjected.push({
                        key: 'areaProjected',
                        upper: trendProjected[i].y,
                        lower: varianceProjected[i].y,
                        x: trendProjected[i].x
                    });
                } else {
                    areaProjected.push({
                        key: 'areaProjected',
                        upper: varianceProjected[i].y,
                        lower: trendProjected[i].y,
                        x: trendProjected[i].x
                    });
                }
            };

            // svg.append("path")
            //     .datum([...areaReal, ...areaProjected])
            //     .attr("fill", "#B2E0FF")
            //     .attr("stroke", "none")
            //     .attr('id', 'trendLine')
            //     .attr("d", d3.area<any>()
            //         .x(function (d: any) { return xScale(d.x) })
            //         .y0(function (d: any) { return yScale(d.lower) })
            //         .y1(function (d: any) { return yScale(d.upper) })
            //         .curve(d3.curveMonotoneX)
            //     );

            let plotProjected = false;
            if (trendReal.length > 0 && moment(trendReal[trendReal.length - 1].x).isAfter(moment().subtract(3, 'days'))) {
                plotProjected = true;
            }

            svg.append("path")
            .datum(areaReal)
            .attr("fill", "#B2E0FF")
            .attr("stroke", "none")
            .attr('id', 'trendLine')
            .attr("d", d3.area<any>()
                .x(function (d: any) { return xScale(d.x) })
                .y0(function (d: any) { return yScale(d.lower) })
                .y1(function (d: any) { return yScale(d.upper) })
                .curve(d3.curveMonotoneX)
            );
            // if (plotProjected) {
                svg.append("path")
                .datum(areaProjected)
                .attr("fill", "#B2E0FF")
                .attr("stroke", "none")
                .attr('id', 'trendLine')
                .attr("d", d3.area<any>()
                    .x(function (d: any) { return xScale(d.x) })
                    .y0(function (d: any) { return yScale(d.lower) })
                    .y1(function (d: any) { return yScale(d.upper) })
                    .curve(d3.curveMonotoneX)
                );
            // }
            

            var line = svg.append("path")
                .datum(trendReal)
                .attr("fill", "none")
                .attr("stroke", "#f7a555")
                .attr("stroke-width", 3)
                .attr("d", d3.line<any>()
                    .x(function (d: any) { return xScale(d.x) })
                    .y(function (d: any) { return yScale(d.y) })
                    .curve(d3.curveMonotoneX
                    )
                );
            // if (plotProjected) {
                svg.append("path")
                .datum(trendProjected)
                .attr("fill", "none")
                .attr("stroke", "#f7a555")
                .attr('position', 'relative')
                .style("stroke-dasharray", ("4, 4"))
                .attr("stroke-width", 3)
                .attr('id', 'trendLine')
                .attr("d", d3.line<any>()
                    .x(function (d: any) { return xScale(d.x) })
                    .y(function (d: any) { return yScale(d.y) })
                    .curve(d3.curveMonotoneX)
                );
            // }
            

            let widthUnit = (WIDTH - 2) / trendJoin.length;
            let rectangleHeight = 20;
            var rects = svg.selectAll(".rects")
                .data(trendJoin)
                .enter()
                .append("rect")
                .attr("y", 10)
                .attr("height", rectangleHeight)
                .attr("x", (d, i) => 2 + i * widthUnit)
                .attr("width", widthUnit + 1)
                .attr("fill", d => colorScale(d.y))
                .attr('transform', `translate(0, ${HEIGHT - 31})`);

            svg.append('g')
                .call(d3.axisLeft(yScale));

            svg.append('g')
                .attr("transform", "translate(0," + HEIGHT + ")")
                .call(d3.axisBottom(xScale));

            svg.append('text')
                .text(`Mobility levels relative to ${props.startDate.toDate().toDateString()}`)
                .attr('font-size', '12px')
                .attr('font-weight', '500')
                .attr('fill', 'black')
                .attr("transform", "rotate(-90)")
                .attr("y", 0 - MARGIN.LEFT - 2)
                .attr("x", 0 - (HEIGHT / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")

            svg.append("text")
                .attr('font-size', '12px')
                .attr('font-weight', '500')
                .attr('fill', 'black')
                .attr("transform",
                    "translate(" + (WIDTH / 2) + " ," +
                    (HEIGHT + MARGIN.TOP + 18) + ")")
                .style("text-anchor", "middle")
                .text("Date");

            var toolTip = svg.append('g')
                .style('visibility', 'hidden')
                .style('fill', 'red')
                .style('background-color', 'red')
                .style('position', 'relative');

            var tooltipCircle = toolTip
                .append("circle")
                .style("position", "absolute")
                .style("left", 0)
                .style("right", 0)
                .style("bottom", 0)
                .style("fill", "none")
                .style("stroke", "black")
                .style("stroke-width", 2)
                .attr("r", 5)
                .attr("cx", 0)
                .attr("cy", 0);

            var toolTipText = toolTip
                .append('text')
                .style('position', 'absolute')
                .style('transform', 'translate(5px, 20px)')
                .attr('width', 100)
                .attr('height', 100)
                .style('fill', 'black')
                .text('Hello')
                .attr('font-size', '12px')

            var toolTipLineMarker = svg
                .append('rect')
                .style('visibility', 'hidden')
                .attr('height', rectangleHeight)
                .attr('width', widthUnit)
                .attr('position', 'absolute')
                .style("fill", "none")
                .style("stroke", "black")
                .style("stroke-width", 2);


            function conversionHandler(xPos: number) {
                let xValue = xScale.invert(xPos - MARGIN.LEFT);
                if (moment(xValue) < moment(trendReal[0].x)) {
                    toolTip.style('visibility', 'hidden');
                    toolTipText.style('visibility', 'hidden');
                    toolTipLineMarker.style('visibility', 'hidden');
                    return {
                        y: 0,
                        x: 0,
                        string: xValue.toDateString(),
                        labelOrientation: 0
                    }
                }
                let joinedData = [...trendReal, ...trendProjected];
                let index = bisect(joinedData, moment(xValue.toISOString()))
                let pointPos = joinedData[index];

                if (pointPos) {
                    toolTip.style('visibility', 'visible');
                    toolTipText.style('visibility', 'visible');
                    toolTipLineMarker.style('visibility', 'visible');
                    return {
                        y: yScale(pointPos.y),
                        x: xScale(pointPos.x),
                        string: moment(pointPos.x).toDate().toDateString(),
                        labelOrientation: (index < joinedData.length * 0.7 ? 0 : 1)
                    }
                } else {
                    toolTip.style('visibility', 'hidden');
                    toolTipText.style('visibility', 'hidden');
                    toolTipLineMarker.style('visibility', 'hidden');
                    return {
                        y: -50,
                        x: -50,
                        string: xValue.toDateString(),
                        labelOrientation: 0
                    }
                }
            }
            var bisect = d3.bisector(function (d: dataPoint) { return d.x; }).left;
            d3.select(d3Container.current)
                .on("mouseover", function () { handleMouseOver() })
                .on("mousemove", function (event) { handleMouseMove(event) })
                .on("mouseout", function () { handleMouseOut(); });

            function handleMouseOver() {
                toolTip.style("visibility", "visible");
                toolTipText.style("visibility", "visible");
                toolTipLineMarker.style("visibility", "visible");
                return
            };

            function handleMouseMove(event: any) {
                let conversionOutput = conversionHandler(d3.pointer(event)[0])
                toolTip.attr('transform', `translate(${conversionOutput.x}, ${conversionOutput.y})`);
                toolTipText.text(conversionOutput.string);
                if (conversionOutput.labelOrientation === 0) {
                    toolTipText.style('transform', 'translate(5px, 20px)');
                } else {
                    toolTipText.style('transform', 'translate(-8em, 20px)');
                }
                toolTipLineMarker.attr('transform', `translate(${conversionOutput.x - 6}, ${HEIGHT - 21})`);
            };

            function handleMouseOut() {
                toolTip.style("visibility", "hidden");
                toolTipText.style("visibility", "hidden");
                toolTipLineMarker.style("visibility", "hidden");
                return
            };
            //setLoading(false) 
            setTimeout(() => {props.setLock(false)}, 500)
            setFirstDraw(false);
            // setInflight(inFlight - 1)
        }
    }, [props.data])
    
    return (
        <svg
            className="mobility-index-graph"
            viewBox={`0 0 1000 450`}
            ref={d3Container}
        />
    );
    }
        
