import {inputType} from './inputs'

export function validateInputs(inputs: inputType[]) {
    let outputObject = {};
    let validity = true;
    for (let i=0; i< inputs.length; i++) {
        let primaryValidity = validate(inputs[i].type, inputs[i].value);
        if (primaryValidity === true) {
            inputs[i].validateFunction(false);
            if (inputs[i].value === 0) {
                for (let j=0; j<inputs[i].children.length; j++) {
                    let secondaryValidity = validate(inputs[i].children[j].type, inputs[i].children[j].value);
                    if (secondaryValidity === false) {
                        inputs[i].children[j].validateFunction(true);
                        validity = false
                    } else {
                        inputs[i].children[j].validateFunction(false);
                    }
                }
            } else {
                for (let j=0; j<inputs[i].children.length; j++) {
                    inputs[i].children[j].validateFunction(false);
                }
            }
        } else {
            inputs[i].validateFunction(true);
            validity = false
        }
    }
    return validity
}

function validate(type: 'multiChoice' | 'text' | 'trueFalse', value: number | string): boolean {
    if (type === 'multiChoice' || type === 'trueFalse' ) {
        if (value !== -1) {
            return true
        }
        return false
    }
    if (value !== "") {
        return true
    }
    return false
}