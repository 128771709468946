import * as React from 'react';
import { ImageCircle, ImageCircleBorder } from './ImageCircle';
import './UserTile.css';
import { navigate } from "@reach/router"
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { useState, useEffect } from 'react';
import { Link } from "@reach/router";

export interface UserTileProps {
    imageURL: string;
    fullName: string;
    gender: string;
    age: number;
    acsis: number;
    status?: ImageCircleBorder;
}

function starUser(acsis: number, star: boolean, setStarred: Function) {
    localStorage.setItem("star-" + acsis, JSON.stringify({ star }));
    setStarred(star);
}

function isUserStarred(acsis: number) {
    let user = localStorage.getItem("star-" + acsis);
    if (user) {
        let userObj = JSON.parse(user);
        return userObj.star;
    }
    return false;
}

function UserTile(props: UserTileProps) {

    const [starred, setStarred] = useState<boolean>();

    useEffect(() => {
        setStarred(isUserStarred(props.acsis));
    });

    let imageProps;
    if (props.status) {
        imageProps = {
            border: props.status
        };
    } else {
        imageProps = {};
    }

    function handleClick(e: { preventDefault: () => void; }) {
        e.preventDefault();
        navigate(`dashboard/${props.acsis}`);
    }

    return (
            <div className="user-tile shadow" style={{ position: 'relative' }} onClick={handleClick}>
                {starred ?
                    <StarIcon style={{ cursor: 'pointer' }} onClickCapture={(e) => { e.preventDefault(); e.stopPropagation(); starUser(props.acsis, false, setStarred); }} className="user-tile__star-selected" /> :
                    <StarBorderIcon style={{ cursor: 'pointer' }} onClickCapture={(e) => { e.preventDefault(); e.stopPropagation(); starUser(props.acsis, true, setStarred); }} className="user-tile__star-unselected" />
                }

                <ImageCircle imageURL={props.imageURL} altText={props.fullName} {...imageProps}/>
                <div className="user-tile__details">
                    <h1 className="user-tile__heading">{props.fullName}</h1>
                    <p>
                        <span>{props.gender}</span><br />
                        <span>{props.age} years old</span><br /><br />
                    </p>
                    <p>
                        <span className="user-tile__acsis">{props.acsis}</span>
                    </p>
                </div>
            </div>
        
    );
}

export default UserTile;
