import * as React from 'react';
import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive'
import moment from "moment";

import { mobilityIndexDataHandler } from '../../dataHandling/mobilityIndexDataHandler';
import { MobilityIndexGraph } from '../charts/MobilityIndexGraph';
import DateSelectionSlider, { dataInputObject } from '../dateSelectionSlider/Slider';
import DateSelectorInput from '../dateSelectionSlider/DateSelectorInput';

import { dataPoint } from '../charts/MobilityIndexGraph';
import { RouteComponentProps } from "@reach/router";
import { start } from 'repl';

import './MobilityIndex.css'

import { authProvider } from '../../authProvider';

export interface MobilityIndexProps extends RouteComponentProps {
    acsisID: number;
    sliderData: dataInputObject | null,
}

export function MobilityIndex(props: MobilityIndexProps) {
    const [hasError, setErrors] = useState(false);
    const [mobIndex, setMobIndex] = useState<any>([]);
    const [yBounds, setYBounds] = useState<[number, number] | null>(null);

    const [startDate, setStartDate] = useState(moment().subtract(50, 'days'));
    const [endDate, setEndDate] = useState(moment());
    const [lowerSliderChange, setLowerSliderChange] = useState(0);
    const [upperSliderChange, setUpperSliderChange] = useState(0);
    const [isSliderLocked, setSliderLocked] = useState(true);

    const [routeTimings, setRouteTimings] = useState<any | null>(null);

    // const [timeBounds, setTimeBounds] = useState<{
    //     startDateTime: moment.Moment;
    //     endDateTime: moment.Moment;
    // }>({
    //     startDateTime: moment().subtract(50, 'days'),
    //     endDateTime: moment()
    // })

    const isPhoneOrTablet = useMediaQuery({
        query: '(max-width: 1050px)'
    });

    console.log('MobTriggered');
    useEffect(() => {
        if (startDate == null || endDate == null) {
            return;
        }

        async function fetchData() {
            setSliderLocked(true);
            console.log(`/api/RouteTimings?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&acsis=${props.acsisID}`)
            const res = await fetch(`/api/RouteTimings?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            res
                .json()
                .then((res: any) => {
                    console.log(res);
                    setRouteTimings(res);
                })
                .catch(err => setErrors(err));
            const boundaryRes = await fetch('/api/MobilityIndexBounds', {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            boundaryRes
                .json()
                .then((boundaryRes: any) => {
                    console.log(boundaryRes);
                    setYBounds([boundaryRes.lowerBound, boundaryRes.upperBound]);
                })
                .catch(err => setErrors(err));
        }
        fetchData(); 
    }, [startDate, endDate]);

    let inputData = mobilityIndexDataHandler(routeTimings) 
    return (
        <>
            <div className="dashboardMobilityIndex__header shadow corners">
                <div className="dashboardMobilityIndex__titleTextContainer">
                    <h1>Mobility Index</h1>
                </div>
                <div className="dashboardMobilityIndex__sliderContainer" id="sliderContainer">
                    {
                        isPhoneOrTablet === false ?
                            <DateSelectionSlider lowerSliderPos={startDate} setLowerSliderPos={setStartDate} upperSliderPos={endDate} setUpperSliderPos={setEndDate} dataInputObject={props.sliderData} minimumDays={40} maximumDays={200} pingLowerUpdate={lowerSliderChange} pingUpperUpdate={upperSliderChange} />
                            :
                            null
                    }
                    <div className="dashboardMobilityIndex__entryContainer">
                        <DateSelectorInput sliderPos={startDate} setSliderPos={setStartDate} label="Start DateTime" pingUpdate={setLowerSliderChange} pingCounter={lowerSliderChange} dataInputObject={props.sliderData} />
                        <DateSelectorInput sliderPos={endDate} setSliderPos={setEndDate} label="End DateTime" pingUpdate={setUpperSliderChange} pingCounter={upperSliderChange} dataInputObject={props.sliderData} />
                    </div>
                    {
                        isSliderLocked === true ?
                            <div className="dashboardMobilityIndex__lock corners" style={{ height: document.getElementById('sliderContainer')?.clientHeight }} />
                            :
                            null
                    }
                </div>
            </div>
            <div className="shadow corners" style={{ padding: "40px", backgroundColor: "#FFFFFF" }}>
                <MobilityIndexGraph data={inputData} bounds={yBounds} setLock={setSliderLocked} startDate={startDate} endDate={endDate}/>
            </div>
        </>
    );
}

export default MobilityIndex;
