import * as React from 'react';
import { RouteComponentProps } from "@reach/router";
import { useState, useEffect, useLayoutEffect } from 'react';
import moment from "moment";
import { useMediaQuery } from 'react-responsive'
import { TimeFrame } from '../TimeFrame';
import './WalkingWithPurpose.css'


import DateSelectionSlider, { dataInputObject } from '../dateSelectionSlider/Slider';
import DateSelectorInput from '../dateSelectionSlider/DateSelectorInput';
import WalkingWithPurposeLine from '../charts/WalkingWithPurposeLine';
import WalkingWithPurposePie from '../charts/WalkingWithPurposePie';
import WalkingWithPurposeKey from '../WalkingWithPurpose/WalkingWithPurposeKey'
import { authProvider } from '../../authProvider';


export interface WalkingWithPurposeProps extends RouteComponentProps {
    acsisID: number;
    sliderData: dataInputObject | null,
}

export interface dataStructure {
    startDateTime: string;
    endDateTime: string;
    data: datapoint[];
}

export interface datapoint {
    name: string;
    value: number;
}

export interface scaledDataPoint {
    name: string;
    value: number;
    widthValue: number;
}

export interface pointValue {
    name: string;
    arcScaleFactor: number;
    radiusScaleFactor: number;
    color: string;
    toolTip: string;
    header: string;
    body: string;
}

export interface pieProps {
    data: dataStructure | null;
    stateData: dataStructure | null;
    setPieSelectedSlice: React.Dispatch<React.SetStateAction<string | null>>;
    pointValues: pointValue[];
}

export interface lineProps {
    data: dataStructure[];
    setPieData: React.Dispatch<React.SetStateAction<dataStructure | null>>;
    setPieDataDefault: React.Dispatch<React.SetStateAction<dataStructure | null>>;
    pieData: dataStructure | null;
    pieSelectedSlice: string | null;
    pointValues: pointValue[];
}

export interface keyProps {
    header: string;
    body: string;
    color: string;
    selection: string | null;
    name: string;
}

export function WalkingWithPurpose(props: WalkingWithPurposeProps) {

    const [hasError, setErrors] = useState(false);
    const [walkingWithPurposeData, setWalkingWithPurposeData] = useState<dataStructure[]>([]);
    const [walkingWithPurposeKey, setWalkingWithPurposeKey] = useState<pointValue[]>([]);
    const [isLoaded, setLoaded] = useState(false);

    const [pieData, setPieData] = useState<null | dataStructure>(null);
    const [pieDataDefault, setPieDataDefault] = useState<null | dataStructure>(null);
    const [pieSelectedSlice, setPieSelectedSlice] = useState<null | string>(null);

    const [timeFrame, setTimeFrame] = useState({ startDate: moment().subtract(5, 'months'), endDate: moment() });

    const [startDate, setStartDate] = useState(moment().subtract(5, 'months'));
    const [endDate, setEndDate] = useState(moment());
    const [lowerSliderChange, setLowerSliderChange] = useState(0);
    const [upperSliderChange, setUpperSliderChange] = useState(0);
    const [isSliderLocked, setSliderLocked] = useState(false);

    useEffect(() => {
        console.log('\n\n\n\n')
        console.log(props.sliderData)
        console.log('\n\n\n\n')
    }, [props.sliderData])

    useEffect(() => {

        if (timeFrame.startDate == null || timeFrame.endDate == null) {
            return;
        }

        async function fetchData() {
            console.log('Walkign with Purpose fetch triggered');
            setSliderLocked(true);
            let successfulLoad = true;
            const resData = await fetch(`/api/walkingWithPurposeData?&acsis=${props.acsisID}&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            resData
            .json()
                .then((resData: any[]) => {
                    setWalkingWithPurposeData(resData);
                    console.log('Data fetch complete');
            })
                .catch(err => {
                    setErrors(err)
                    successfulLoad = false
                });

            const resKey = await fetch(`/api/walkingWithPurposeKey`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            resKey
                .json()
                .then((resKey: { pointValues: pointValue[]}) => {
                    setWalkingWithPurposeKey(resKey.pointValues);
                    console.log('Key fetch complete');
                })
                .catch(err => {
                    setErrors(err)
                    successfulLoad = false
                });
            if (successfulLoad) {
                setLoaded(true);
            }
            setSliderLocked(false);

        }
        fetchData();
    }, [startDate, endDate]);


    return (
        <div className="dashboard-walkingWithPurpose__container">
            <div className="dashboardWalkingWithPurpose__header shadow corners">
                <div className="dashboardWalkingWithPurpose__titleTextContainer">
                    <h1 style={{ textAlign: 'center' }}>Walking<br />with purpose</h1>
                </div>
                <div className="dashboardWalkingWithPurpose__sliderContainer" id="sliderContainer">
                    {
                        useMediaQuery({
                            query: '(max-width: 1050px)'
                        }) === false ?
                            <DateSelectionSlider lowerSliderPos={startDate} setLowerSliderPos={setStartDate} upperSliderPos={endDate} setUpperSliderPos={setEndDate} dataInputObject={props.sliderData} minimumDays={150} maximumDays={1000} pingLowerUpdate={lowerSliderChange} pingUpperUpdate={upperSliderChange} />
                            :
                            null
                    }
                    <div className="dashboardWalkingWithPurpose__entryContainer">
                        <DateSelectorInput sliderPos={startDate} setSliderPos={setStartDate} label="Start DateTime" pingUpdate={setLowerSliderChange} pingCounter={lowerSliderChange} dataInputObject={props.sliderData} />
                        <DateSelectorInput sliderPos={endDate} setSliderPos={setEndDate} label="End DateTime" pingUpdate={setUpperSliderChange} pingCounter={upperSliderChange} dataInputObject={props.sliderData} />
                    </div>
                    {
                        isSliderLocked === true ?
                            <div className="dashboardWalkingWithPurpose__lock corners" style={{ height: document.getElementById('sliderContainer')?.clientHeight }} />
                            :
                            null
                    }
                </div>
            </div>
            <div className="dashboard-walkingWithPurpose__pieContainer corners shadow" id="WalkingwithPurposePieContainer">
                <WalkingWithPurposePie data={pieDataDefault} stateData={pieData} setPieSelectedSlice={setPieSelectedSlice} pointValues={walkingWithPurposeKey}/>
            </div>
            <div className="dashboard-walkingWithPurpose__keyContainer corners shadow">
                {RenderKey(walkingWithPurposeKey, pieSelectedSlice)}
            </div>
            <div className="dashboard-walkingWithPurpose__lineContainer corners shadow" id="WalkingwithPurposeLineContainer">
                <WalkingWithPurposeLine data={walkingWithPurposeData} setPieDataDefault={setPieDataDefault} pieData={pieData} setPieData={setPieData} pieSelectedSlice={pieSelectedSlice} pointValues={walkingWithPurposeKey} />
            </div>
                
        </div>
    );
}

export default WalkingWithPurpose;


function RenderKey(walkingWithPurposeKey: pointValue[], pieSelectedSlice: string | null) {
    let key: any = [];
    if (walkingWithPurposeKey.length === 0) {
        return null;
    }
    let localKeyArray = [];
    for (let i = 0; i < walkingWithPurposeKey.length; i++) {
        localKeyArray.push(
            <WalkingWithPurposeKey
                header={walkingWithPurposeKey[i].header}
                body={walkingWithPurposeKey[i].body}
                color={walkingWithPurposeKey[i].color}
                name={walkingWithPurposeKey[i].name}
                selection={pieSelectedSlice}
            />
        );
    }
    return localKeyArray
}
