import * as React from 'react';
import { RouteComponentProps } from "@reach/router";
import { useState, useEffect } from 'react';
import { GanttChart, GanttChartData } from '../charts/GanttChart'
import moment from "moment";
import "./Mobility.css";
import "../TextSelect.css";
import { TimeFrame } from '../TimeFrame';
import Switch from '@material-ui/core/Switch';
import { HubConnectionBuilder, HubConnection } from '@aspnet/signalr';
import { NetworkGraphData } from '../charts/NetworkGraph';
import { useMediaQuery } from 'react-responsive'

import DateSelectionSlider, { dataInputObject } from '../dateSelectionSlider/Slider';
import DateSelectorInput from '../dateSelectionSlider/DateSelectorInput';

import GanttIndicator from '../charts/ActivityChart/ganttIndicator'
import GanttMain, {applianceLocRecord, motionDataPoint} from '../charts/ActivityChart/ganttMain'

import { authProvider } from '../../authProvider';


export interface MobilityProps extends RouteComponentProps {
    acsisID: number;
    sliderData: dataInputObject | null,
}

interface branchSpreadValues {
    index: number;
    branches: number;
}

interface networkType {
    index: number;
    value: string;
    links: number[]
}

export interface applianceLocationRecord {
    appliances: {
        [key: string]: {
            location: string
        }
    }
}

export function Mobility(props: MobilityProps) {
    
    const [hasError, setErrors] = useState(false);
    const [mobility, setMobility] = useState<motionDataPoint[] | null>(null);
    const [light, setLight] = useState<GanttChartData[] | null>(null);
    const [temperature, setTemperature] = useState<GanttChartData[] | null>(null);
    const [appliance, setAppliance] = useState<GanttChartData[] | null>(null);
    const [door, setDoor] = useState<GanttChartData[] | null>(null);
    const [network, setNetwork] = useState<NetworkGraphData | null>(null);
    const [toggles, setToggles] = useState<any>({});
    const [live, setLive] = useState<boolean>(false);
    const [hubConnection, setHubConnection] = useState<HubConnection>();
    const [applianceLocations, setApplianceLocations] = useState<any | null>(null);


    const [startDate, setStartDate] = useState<moment.Moment>(moment());
    const [endDate, setEndDate] = useState<moment.Moment>(moment());
    const [lowerSliderChange, setLowerSliderChange] = useState(0);
    const [upperSliderChange, setUpperSliderChange] = useState(0);
    const [isSliderLocked, setSliderLocked] = useState(false);

    const [domainArray, setDomainArray] = useState<string[] | null>(null);
    const [brushedRegion, setBrushedRegion] = useState<[Date, Date] | null>(null);
    const [applianceLoc, setApplianceLoc] = useState<applianceLocRecord[] | null>(null);

    const maximumDays = 7;

    console.log('MobTriggered');
    useEffect(() => {
        if (!live && (startDate == null || endDate == null)) {
            return;
        }
        if (endDate.diff(startDate, 'days') > maximumDays ) {
            return;
        }
        setSliderLocked(true);
        console.log('useEffect');
        async function fetchData() {

            console.log('start fetch');
            let fixedStart = moment(startDate);
            let fixedEnd = moment(endDate);

            if (live) {
                fixedStart = moment().subtract(6, 'hours');
                fixedEnd = moment();
            }

            const mobilityRes = fetch(`/api/mobility?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            const lightRes = fetch(`/api/light?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            const temperatureRes = fetch(`/api/temperature?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            const applianceRes = fetch(`/api/appliance?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            const doorRes = fetch(`/api/door?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            const networkRes = fetch(`/api/network?acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            const applianceLocRes = fetch(`/api/applianceLocation?acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});

            Promise.all([
                mobilityRes.then(res => res.json()).then((mobilityRes: motionDataPoint[]) => {
                    console.log(mobilityRes);
                    setMobility(mobilityRes);
                }).catch(err => setErrors(err)),
                lightRes.then(res => res.json()).then((lightRes: GanttChartData[]) => {
                    console.log(lightRes);
                    setLight(lightRes);
                }).catch(err => setErrors(err)),
                temperatureRes.then(res => res.json()).then((temperatureRes: GanttChartData[]) => {
                    console.log("Finished temp fetch");
                    console.log(temperatureRes);
                    setTemperature(temperatureRes);
                }).catch(err => setErrors(err)),
                applianceRes.then(res => res.json()).then((applianceRes: GanttChartData[]) => {
                    console.log("Finished appliance fetch");
                    console.log(applianceRes);
                    setAppliance(applianceRes);
                }).catch(err => setErrors(err)),
                doorRes.then(res => res.json()).then((doorRes: GanttChartData[]) => {
                    console.log("Finished door fetch");
                    console.log(doorRes);
                    setDoor(doorRes);
                }).catch(err => setErrors(err)),
                networkRes.then(res => res.json()).then((res: NetworkGraphData) => {
                    console.log(res);
                    setNetwork(res);
                }).catch(err => setErrors(err)),
                applianceLocRes.then(res => res.json()).then((res: any) => {
                    console.log(res);
                    setApplianceLocations(res);
                }).catch(err => setErrors(err))
            ]);
        }
        fetchData();
        setSliderLocked(false);

        // Live data stream
        const createHubConnection = async () => {
            const accessToken = (await authProvider.getAccessToken()).accessToken;

            const hubConnect = new HubConnectionBuilder()
                .withUrl('/event', {
                    accessTokenFactory: () => accessToken
                })
                .build();

            try {
                await hubConnect.start();
                console.log("Connected to hub");

                //await hubConnect.invoke('AddToGroup', 'raw');
                //                await hubConnect.invoke('AddToGroup', '4334943');

                await hubConnect.invoke('AddToGroup', props.acsisID.toString());

                console.log(`Joined group "${props.acsisID.toString()}"`);

                hubConnect.on('Send', (name: string, message: string) => {
                    //setFeed(m => [...m, message]);
                    console.log(message);
                    let mess = JSON.parse(message);


                    if (live && mess.RecordType == 'Motion' || mess.RecordType == 'MOTION') {

                        let key, keys = Object.keys(mess);
                        let n = keys.length;
                        let newobj: any = {};
                        while (n--) {
                            key = keys[n];
                            newobj[key.charAt(0).toLowerCase() + key.slice(1)] = mess[key];
                        }

                        setMobility(mobility => mobility ? [...mobility, newobj] : [newobj]);
                    }

                });
            }
            catch (err) {
                console.log(err);
            }

            setHubConnection(hubConnect);
        };

        createHubConnection();
    }, [startDate, endDate, live]);

    useEffect(() => {
        if (network != null) {
            let quickLinkedList: networkType[] = [];
            let net2: any = network;
            for (let i=0; i<net2.nodes.length; i++) {
                quickLinkedList.push({
                    index: i,
                    value: net2.nodes[i].id,
                    links: []
                })
            }
            for (let i=0; i<net2.links.length; i++) {
                let i1 = quickLinkedList.findIndex(x => x.value === net2.links[i].source);
                let i2 = quickLinkedList.findIndex(x => x.value === net2.links[i].target);
        
                if (i1 === -1 || i2 === -1) {
                    continue;
                }
        
                quickLinkedList[i1].links.push(i2);
                quickLinkedList[i2].links.push(i1);
            }
            // setDomainArray(colterStevens(quickLinkedList));
            console.log(colterStevens(quickLinkedList));
        }
        if (applianceLocations != null) {
            // Sort the appliance location record
            let applianceKeys = Object.keys(applianceLocations.appliances);
            let locationTally: any = {};
            let applianceLocRecord: applianceLocRecord[] = []
            for (let i=0; i<applianceKeys.length; i++) {
                console.log(applianceLocations.appliances[applianceKeys[i]])
                if (!locationTally[applianceLocations.appliances[applianceKeys[i]].location]) {
                    locationTally[applianceLocations.appliances[applianceKeys[i]].location] = {
                        location: applianceLocations.appliances[applianceKeys[i]].location,
                        number: 1,
                        appliances: [applianceKeys[i].split('|')[1]]
                    };
                } else {
                    locationTally[applianceLocations.appliances[applianceKeys[i]].location].number ++;
                    locationTally[applianceLocations.appliances[applianceKeys[i]].location].appliances.push(applianceKeys[i].split('|')[1]);
                }
                applianceLocRecord.push({
                    appliance: applianceKeys[i].split('|')[1],
                    location: applianceLocations.appliances[applianceKeys[i]].location,
                    position: locationTally[applianceLocations.appliances[applianceKeys[i]].location].number,
                    total: -1
                });
            }
            for (let i=0; i<applianceLocRecord.length; i++) {
                if (locationTally[applianceLocRecord[i].location]) {
                    applianceLocRecord[i].total = locationTally[applianceLocRecord[i].location].number;
                }
            }
            // setApplianceLoc(applianceLocRecord);
        }

    }, [network, applianceLocations])


    console.log('live', live);

    return (
        <div className="dashboardMobility__container">
            <div className="dashboardMobility__header shadow corners">
                <div className="dashboardMobility__titleTextContainer">
                    <h1 style={{ textAlign: 'center' }}>Activity</h1>
                </div>
                <div className="dashboardMobility__sliderContainer" id="sliderContainer">
                    {
                        useMediaQuery({
                            query: '(max-width: 1050px)'
                        }) === false ?
                            <DateSelectionSlider lowerSliderPos={startDate} setLowerSliderPos={setStartDate} upperSliderPos={endDate} setUpperSliderPos={setEndDate} dataInputObject={props.sliderData} minimumDays={1} maximumDays={maximumDays} pingLowerUpdate={lowerSliderChange} pingUpperUpdate={upperSliderChange} />
                            :
                            null
                    }
                    <div className="dashboardMobility__entryContainer">
                        <DateSelectorInput sliderPos={startDate} setSliderPos={setStartDate} label="Start DateTime" pingUpdate={setLowerSliderChange} pingCounter={lowerSliderChange} dataInputObject={props.sliderData} />
                        <DateSelectorInput sliderPos={endDate} setSliderPos={setEndDate} label="End DateTime" pingUpdate={setUpperSliderChange} pingCounter={upperSliderChange} dataInputObject={props.sliderData} />
                    </div>
                    {
                        isSliderLocked === true ?
                            <div className="dashboardMobility__lock corners" style={{ height: document.getElementById('sliderContainer')?.clientHeight }} />
                            :
                            null
                    }
                </div>
            </div>
            <div className="dashboardMobility__mainGantt corners shadow">
                <GanttMain motionData={mobility} domainArray={domainArray} applianceLocations={applianceLoc} applianceData={appliance} bounds={brushedRegion}/>
            </div>
            <div className="dashboardMobility__indicatorGantt corners shadow">
                <GanttIndicator domainArray={domainArray} motionData={mobility} setBrushed={setBrushedRegion}/>
            </div>

        </div>
    );
}

export default Mobility;


function colterStevens(network: networkType[]): string[] {
    let edgeNode: number = -1
    for (let i=0; i<network.length; i++) {
        if (network[i].links.length === 1) {
            edgeNode = i;
            break;
        }
    }
    if (edgeNode === -1) {
        console.log('Impossible.')
        return [];
    }
    let traversed: number[] = [];
    sourceCode2011(traversed, network, edgeNode);
    let output: string[] = [];
    traversed.forEach(x => {output.push(network[x].value)})
    return output;
}

function sourceCode2011(traversed: number[], network: networkType[], currentNode: number) {
    traversed.push(currentNode);

    let availableLinks = network[currentNode].links;

    let nextValidSteps = availableLinks.filter(x => {
        let visited = false;
        traversed.forEach(y => {
            if (x === y) {
                visited = true;
            }
        })
        if (visited === false) {
            return true
        } else {
            return false
        }
    });

    let branchesArray: branchSpreadValues[] = [];
    nextValidSteps.forEach(x => {
        let branches = 0;
        let links = network[x].links;
        links.forEach(i => {
            let visitedB = false
            traversed.forEach(y => {
                if (i === y) {
                    visitedB = true;
                }
            });
            if (visitedB === false) {
                branches ++
            }
        });
        branchesArray.push({
            index: x,
            branches
        });
    });

    let executionOrder = branchesArray.sort((a, b) => {
        if (a.branches > b.branches) {
            return 1
        }
        if (a.branches < b.branches) {
            return -1
        }
        return 0
    });

    executionOrder.forEach(x => {
        sourceCode2011(traversed, network, x.index);
    })  

}