import * as React from 'react';
import { RouteComponentProps } from "@reach/router";
import coronavirusData from "../CoronavirusData";
import { StackedAreaChart } from './charts/StackedAreaChart';

export function Coronavirus(props: RouteComponentProps) {

    const dates = Object.keys(coronavirusData.locations[0].timelines.confirmed.timeline);
    const locations = coronavirusData.locations.map(loc => loc.country + (loc.province ? ' - ' + loc.province : ''));

    const dataGroupedByDate = dates.map(date => (coronavirusData.locations.reduce((acc: any, loc) => ({
            ...acc,
            [loc.country + (loc.province ? ' - ' + loc.province : '')]: (loc.timelines.confirmed.timeline as any)[date]
        }), {date: new Date(date)})
    ));

    let dataGroupedByLocation = coronavirusData.locations.map(loc => ({
        location: loc.country,//(loc.country + (loc.province ? ' - ' + loc.province : '')),
        values: dates.map(date => (loc.timelines.confirmed.timeline as any)[date])
    }));

    return (
        <div>
            <StackedAreaChart locations={locations} dates={dates} data={dataGroupedByDate} width={1600} height={800} />
            <hr />
            <span>Has error: No</span>
        </div>
    );
}

export default Coronavirus;
