import * as React from 'react';
import { useRef, useEffect } from "react";
import * as d3 from "d3";
import { scaleTime, scaleBand, scaleOrdinal } from "d3-scale";
import { axisBottom, axisLeft } from "d3-axis";
import { Tableau10 } from "../../utils/colours";
import './GanttChart.css';
import "./CommonGraph.css";
import * as moment from 'moment';


export interface StackedAreaChartProps {
    dates: string[];
    data: any[];
    locations: any[];
    height: number;
    width: number;
}

export function StackedAreaChart(props: StackedAreaChartProps) {

    const d3Container = useRef(null);

    useEffect(() => {

        if (props.data && d3Container.current) {
            const svg = d3.select(d3Container.current);
            const margin = { left: 100, top: 10, right: 10, bottom: 20 };
            const width = 1600;
            const height = 800;

            console.log(props.data);

            let series = d3.stack().keys(props.locations)(props.data);

            

            let colour = d3.scaleOrdinal()
                .domain(props.locations)
                .range(Tableau10);

            let x = d3.scaleUtc()
                .domain(d3.extent(props.dates.map(d => new Date(d)) as Date[], d => d) as [Date, Date])
                .range([margin.left, width - margin.right]);

            let y = d3.scaleLinear()
                .domain([0, d3.max(series, d => d3.max(d, d => d[1])) as number]).nice()
                .range([height - margin.bottom, margin.top]);

            let area = d3.area()
                .x((d: any) => x(new Date(d.data.date)) as number)
                .y0(d => y(d[0]) as number)
                .y1(d => y(d[1]) as number);

            console.log(series);
            console.log(area);

            let xAxis = (g: any) => g
                .attr("transform", `translate(0,${height - margin.bottom})`)
                .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0));

            let yAxis = (g: any) => g
                .attr("transform", `translate(${margin.left},0)`)
                .call(d3.axisLeft(y))
                .call((g: any) => g.select(".domain").remove())
                .call((g: any) => g.select(".tick:last-of-type text").clone()
                    .attr("x", 3)
                    .attr("text-anchor", "start")
                    .attr("font-weight", "bold")
                    .text("Confirmed cases"));

            let tip = d3.select(".graph-tooltip");

            svg.append("g")
                .selectAll("path")
                .data(series)
                .join("path")
                .attr("fill", ({ key }) => colour(key) as any)
                .attr("d", area as any)
                .on("mouseover", (event, d) => {
                    //console.log('over');
                    //tip.style("display", "box");
                })
                .on("mousemove", (event, d) => {
                    //console.log('move', , d3.event.pageY);

                    tip.html(JSON.stringify(d, null, "  "))
                    /*    .style("left", 500)
                        .style("top", 500);*/
                })
                .on("mouseleave", (event, d) => {
                    //console.log('leave');
                    //tip.style("display", "none");
                })
                .append("title")
                .text(({ key }) => key);

            svg.append("g")
                .call(xAxis);

            svg.append("g")
                .call(yAxis);
        }

    }, [props.data, d3Container.current]);

    if (props.data == null) {
        return (
            <div className="stacked-area-chart-info">
                <p>Loading...</p>
            </div>
        );
    } else if (props.data == []) {
        return (
            <div className="stacked-area-chart-info">
                <p>No data found for the provided timeframe.</p>
            </div>
        );
    } else {
        return (
            <>
                <div className="graph-tooltip"></div>
                <svg
                    className="stacked-area-chart"
                    viewBox="0 0 1600 800"
                    ref={d3Container}
                />
            </>
        );
    }
}

export default StackedAreaChart;