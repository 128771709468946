/*
0 = no secondary fact
1 = contrasting average
2 = contrasting fact w/ date range
*/
export function generateSentences(gender, name, primaryFact, primaryTimeSpan, secondaryClass, secondaryFact, secondaryTimeSpan) {
    let malePronouns = [['he', 'his', 'him'], ['He', 'His', 'Him']];
    let femalePronouns = [['she', 'her'], ['She', 'Her']];
    let pronouns = gender == "Male" ? malePronouns : femalePronouns;
    /*
    Generate the scentence branch: 2
    [pronoun]-[fact]-[timespan]
        e.g. [He]-[has fallen 7 times]-[in the last 5 weeks]
    [timespan]-[pronoun]-[fact]
        e.g. [in the last 5 months]-[Mike]-[has fallen 5 times]
    */
    let sentenceBranch = '';
    switch (Math.floor(Math.random() * Math.floor(2))) {
        case 0:
            sentenceBranch = pronouns[1][0] + ' has ' + primaryFact + ' in the ' + (Math.floor(Math.random() * Math.floor(2)) == 0 ? 'previous' : 'last') + ' ' + primaryTimeSpan;
            break;
        case 1:
            sentenceBranch = 'In the ' + (Math.floor(Math.random() * Math.floor(2)) == 0 ? 'previous' : 'last') + ' ' + primaryTimeSpan + ' ' + pronouns[0][0] + ' has ' + primaryFact;
            break;
    }
    switch (secondaryClass) {
        case 0:
            sentenceBranch = sentenceBranch + '.';
            break;
        case 1:
            // This case is a timespan value comparison to a given average, 2 formats are given, to add variety.
            if ((Math.floor(Math.random() * Math.floor(2))) == 0) {
                sentenceBranch = sentenceBranch + ' and on average ' + pronouns[0][0] + ' ' + secondaryFact + '.';
            }
            else {
                sentenceBranch = sentenceBranch + ' and ' + pronouns[0][0] + ' ' + secondaryFact + ' on average.';
            }
            break;
        case 2:
            // This case is a contrasted, non average fact, e.g ' he has done x in the alst 2 weeks, and y in the last 5 months
            if ((Math.floor(Math.random() * Math.floor(2))) == 0) {
                sentenceBranch = sentenceBranch + ', in the ' + (Math.floor(Math.random() * Math.floor(2)) == 0 ? 'previous' : 'last') + ' ' + secondaryTimeSpan + ' ' + pronouns[0][0] + ' has ' + secondaryFact + '.';
            }
            else {
                sentenceBranch = sentenceBranch + ', ' + pronouns[0][0] + ' has ' + secondaryFact + ' in the ' + (Math.floor(Math.random() * Math.floor(2)) == 0 ? 'previous' : 'last') + ' ' + secondaryTimeSpan;
            }
            break;
    }
    return sentenceBranch;
}
