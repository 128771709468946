import * as React from 'react';
import './styles.css';

interface textInputProps {
    children?: any,
    placeholderText: string, 
    entryName: string,
    styleProp?: {
        [key: string]: string | number
    },
    value: string,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    validity: boolean,
    editable: boolean
} 

export function SingleLineWrappedTextInput(props: textInputProps) {
    let backgroundColor = '#ebebeb';
    if (props.validity === false && props.value === "") {
        backgroundColor = '#FF3300';
    }
    return (   
        
        <div className="referral__singleLineTextInputContainer" style={props.styleProp}>
            <div style={{width: '200px', alignItems: 'center', display: 'flex'}}>
                <span className="referral__singleLineTextInputText">{props.entryName}</span>
            </div>
            <input type="text" className="referral__singleLineTextInput" placeholder={props.placeholderText} style={{ backgroundColor }} value={props.value} onChange={(text) => { props.setValue(text.target.value) }} readOnly={!props.editable}/> 
        </div>

    ) 
}

export default SingleLineWrappedTextInput