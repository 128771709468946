import React, { useEffect, useRef } from 'react'
import * as d3 from 'd3';

import { scaledDataPoint, pointValue, pieProps } from '../dashboardPages/WalkingWithPurpose'

export default function PieChart(props: pieProps) {
    const d3Container = useRef(null);

    let svgWidth = 0;
    let svgHeight = 0;
    
    let MARGIN = { LEFT: 30, RIGHT: 30, TOP: 30, BOTTOM: 30 };
    let WIDTH = svgWidth - MARGIN.LEFT - MARGIN.RIGHT;
    let HEIGHT = svgHeight - MARGIN.TOP - MARGIN.BOTTOM;

    let timeout = false;

    useEffect(() => {
        let delta = 1000;
        function initDraw() {
            
            let resizeStart = new Date();
            if (timeout === false) {
                timeout = true;
                setTimeout(drawPie, delta)
            }
        }
        initDraw()
        window.addEventListener('resize', initDraw);
        return () => {
            window.removeEventListener('resize', initDraw);
        }
    }, [props.data]);

    useEffect(() => {
        drawPie()
    }, [props.stateData]);

    function drawPie() {
        timeout = false;
        const canvas: any = d3.select(d3Container.current);
        svgWidth = canvas.node().getBoundingClientRect().width;
        svgHeight = canvas.node().getBoundingClientRect().height;

        MARGIN = { LEFT: 30, RIGHT: 30, TOP: 30, BOTTOM: 30 };
        WIDTH = svgWidth - MARGIN.LEFT - MARGIN.RIGHT;
        HEIGHT = svgHeight - MARGIN.TOP - MARGIN.BOTTOM;

        // Select the most appropriate data set - use the default if there si not currently a slice hoevered 
        if (props.data == null) {
            return;
        }
        let data = props.data
        if (props.stateData != null) {
            if (props.stateData.data.length != 0) {
                data = props.stateData;
            }
        }

        // Clear any svg components from the canvas as the pie will be re-drawn multiple times as the state data changes
        canvas.selectAll("*").remove()

        // Create a fresh canvas 
        const svg = d3.select(d3Container.current)
            .attr('width', WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
            .attr('height', HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
            .append('g')
            .attr('transform', `translate(${(WIDTH + MARGIN.LEFT + MARGIN.RIGHT) / 2}, ${(HEIGHT + MARGIN.TOP + MARGIN.BOTTOM) / 2})`)
            .attr('fill', 'blue')
            .attr('id', 'canvas')

        // Allocate the proportion of the circumfrence to be allocated to each point
        const circumferenceScale = 100;
        let dataArray = data.data;
        let wedgeAllocation = circumferenceScale / dataArray.length;

        // create the scaled data array, each segment has its own scale factors, any whitespace is summed to ensure all full circumfrence is used 
        let whitespace = 0;
        let scaledDataArray: scaledDataPoint[] = [];
        for (let i = 0; i < dataArray.length; i++) {
            let localObj: any = dataArray[i];
            let scaleIndex = props.pointValues.findIndex((d => d.name === dataArray[i].name));
            let scaledValue = (wedgeAllocation / props.pointValues[scaleIndex].arcScaleFactor) * dataArray[i].value;
            if (scaledValue < 2) {
                scaledValue = 2;
            } else if (scaledValue > wedgeAllocation) {
                scaledValue = wedgeAllocation;
            }
            localObj.widthValue = scaledValue;
            scaledDataArray.push(localObj);
            whitespace += wedgeAllocation - scaledValue;
        }

        // Order the scaled data array then append the whitespace so it is always last on clock face
        scaledDataArray = [...scaledDataArray].sort(compare);
        scaledDataArray.push({
            name: 'filler',
            widthValue: whitespace,
            value: 0
        });

        // Plot the pie to the svg canvas
        var pie = d3.pie<scaledDataPoint>()
            .sort(null)
            .value((d: any) => d.widthValue)
            .padAngle(0.02)

        var arcs = pie(scaledDataArray)

        var arc = d3.arc<any>()
            .innerRadius(10)
            .outerRadius((d: any) => handleRadius(d, props.pointValues, WIDTH, HEIGHT))

        svg.append("g")
            .attr("stroke", "white")
            .attr('stroke-width', 0)
            .selectAll("path")
            .data(arcs)
            .join("path")
            .attr("fill", (d: any) => handleColor(d, props.pointValues))
            .attr("d", arc)
            .on("mouseover", function (d: any) { handleMouseOver(d, props.setPieSelectedSlice, svg) })
            .on("mouseleave", function (d: any) { handleMouseLeave(d, props.setPieSelectedSlice, svg) })
            .attr('class', (d: any) => createClassName(d))


    }

    return (
        <svg id="walkingWithPurposePie" ref={d3Container} />
    )
}

// Sorting function for the data Array to order by name
function compare(a: any, b: any) {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
}

// Scaling function to provide appropriate radii for each segment
function handleRadius(d: any, pointValues: pointValue[], WIDTH: number, HEIGHT: number) {
    if (d.data.name === 'filler') {
        return 13;
    } else {
        let scaleIndex = pointValues.findIndex((a => a.name === d.data.name));
        let scaledValue = ((Math.min(WIDTH, HEIGHT) / 2) / pointValues[scaleIndex].radiusScaleFactor) * d.data.value + 15;
        if (scaledValue > (Math.min(WIDTH, HEIGHT) / 2)) {
            scaledValue = (Math.min(WIDTH, HEIGHT) / 2);
        }
        return 15 + (scaledValue)
    }
}

// function to handle the mouse enter event of each segment
function handleMouseOver(event: any, setPieSelectedSlice: React.Dispatch<React.SetStateAction<string | null>>, svg: d3.Selection<SVGGElement, unknown, null, undefined>) {
    if (event.target.className.baseVal === 'arc_filler') {
        return;
    }
    let hoveredWedge = '.' + event.target.className.baseVal
    setPieSelectedSlice(event.target.className.baseVal.slice(4));
    svg.select('g').select(hoveredWedge)
        .attr('stroke', '#616161')
        .attr('stroke-width', 2);
}

// function to handle the mouse leave event of each segment
function handleMouseLeave(event: any, setPieSelectedSlice: React.Dispatch<React.SetStateAction<string | null>>, svg: d3.Selection<SVGGElement, unknown, null, undefined>) {
    if (event.target.className.baseVal === 'arc_filler') {
        return;
    }
    setPieSelectedSlice(null)
    let hoveredWedge = '.' + event.target.className.baseVal
    svg.select('g').select(hoveredWedge)
        .attr('stroke', 'white')
        .attr('stroke-width', 0);
}

// Cheeky class name making function 
function createClassName(data: any) {
    return 'arc_' + data.data.name;
}

function handleColor(d: any, pointValues: pointValue[]) {
    if (d.data.name === 'filler') {
        return '#7E7E7E';
    } else {
        let index = pointValues.findIndex((a => a.name === d.data.name));
        return pointValues[index].color;
    }
}