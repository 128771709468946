import * as React from 'react';
import { useState } from 'react';
import 'react-dates/initialize';
import ReactDates, { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from "moment";
import 'moment/locale/en-gb';


export interface TimeFrameProps {
    timeFrame: any;
    setTimeFrame: any;
    minNights?: number;
}

export function TimeFrame(props: TimeFrameProps) {

    const [focusedInput, setFocusedInput] = useState<ReactDates.FocusedInputShape | null>(null);

    moment.locale('en-gb');

    return (
        <div className="time-frame shadow" style={{paddingRight: "22px"}}>
            <DateRangePicker
                startDate={props.timeFrame.startDate} // momentPropTypes.momentObj or null,
                startDateId="time-frame__start-date" // PropTypes.string.isRequired,
                endDate={props.timeFrame.endDate} // momentPropTypes.momentObj or null,
                endDateId="time-frame__end-date" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => props.setTimeFrame({ startDate, endDate })} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={input => setFocusedInput(input)} // PropTypes.func.isRequired,
                minimumNights={props.minNights === undefined ? 0 : props.minNights}
                isOutsideRange={() => false}
                initialVisibleMonth={() => props.timeFrame.startDate ? props.timeFrame.startDate : moment().subtract(1, 'months')}//{props.timeFrame.startDate ? props.timeFrame.startDate : }  //initialVisibleMonth={() => moment().subtract(1, 'months')}
            />
        </div>
    );
}

export default TimeFrame;
