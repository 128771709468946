import React, { useState, useEffect } from 'react'
import './WalkingWithPurposeKey.css'
import {keyProps} from '../dashboardPages/WalkingWithPurpose';

export function WwpKeyItem(props: keyProps) {

    return (
        <div className="wwpKeyItem_container" style={{ opacity: props.name === props.selection || props.selection === null ? 1 : 0.3}}>
            <div className="wwpKey_colorSlice">
                <div className="wwpKeyItem_indicator" style={{ backgroundColor: props.color }} />
            </div>
            <div className="wwpKeyItem_TextWindow">
                <text className="wwpKeyItem_textHeader">{props.header}</text>
                <text className="wwpKeyItem_textBody">{props.body}</text>
            </div>
        </div>
    )
}

export default WwpKeyItem;