import * as React from 'react';
import { useState } from 'react';
import './styles.css';
import { toast } from 'react-toastify';

interface textInputProps {
    children?: any,
    consentText: string,
    setConsent: React.Dispatch<React.SetStateAction<boolean | null>>,
    styleProp?: {
        [key: string]: any
    },
    dependancies: string[],
    consent: boolean | null
} 

export function ConsentButton(props: textInputProps) {
    const [hover, setHover] = useState(false);
    let backgroundColor = '#d7d7d7';
    if (hover && props.consent !== true) {
        backgroundColor = '#929292';
    } else if (props.consent === true) {
        backgroundColor = '#80c300'
    }


    return (   
        
        <div className="referral__consentButtonContainer corners shadow" style={{
            marginTop: '50px',
            marginBottom: '20px',
            backgroundColor
        }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onMouseDown={() => {
                if (props.consent !== true) {
                    let dependanciesFilled = true;
                    for (let i = 0; i < props.dependancies.length; i++) {
                        if (props.dependancies[i] === "") {
                            dependanciesFilled = false
                        }
                    }
                    if (dependanciesFilled) {
                        props.setConsent(true);
                        toast.success('Consent has been provided, please fill out the remainder of the risk score form below');
                    } else {
                        toast.error('Please enter the required information before trying to submit consent');
                        props.setConsent(false);
                    }
                }

            }}
        >
            <h3 className="referral__consentButtonText">{props.consentText}</h3>
        </div> 

    ) 
}

export default ConsentButton