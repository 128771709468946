import * as React from 'react';
import { useState } from 'react';
import { RouteComponentProps, Router, navigate } from "@reach/router";
import './ReferralForm.css';

import SingleLineWrappedinput from './components/basic/singleLineWrappedTextInput/singleLineWrappedTextInput';
import ConsentButton from './components/basic/consentButton/consentButton'

export interface ServiceUserReferralProps extends RouteComponentProps {
    acsisID?: number;
}

export function ServiceUserReferral(props: ServiceUserReferralProps) {

    const [workerName, setWorkerName] = useState("");
    const [consentDate, setConsentDate] = useState("");
    const [consent, setConsent] = useState<boolean | null>(null);

    const [validity, setValidity] = useState<boolean | null>(null);

    const [dateOfReferral, setDateOfReferral] = useState("");
    const [referredBy, setReferredBy] = useState("");
    const [orgTeam, setOrgTeam] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [refererEmailAddress, setRefererEmailAddress] = useState("");

    return (
        <div className="window__referral" >
            <div className="referral__referralContainer corners shadow"> 

                <div className="referral__headlineContainer">
                    <h1>Assistive Technology Referral Form</h1>
                </div>

                <div className="referral__contentContainer">
                    <div className="referral__consentSection">
                        <div className="referral__consentDocument corners">
                            <h3 className="referral__consentDocumentText"> In order to be part of the Assistive technology study service users must have capacity to consent.</h3>
                            <h3 className="referral__consentDocumentText"> Please confirm that this service user has been given information regarding the study and an explanation on how the sensor will collect information regarding their daily lives. They need to understand that activity data will be collected and analysed as part of this study.</h3>
                        </div>
                        <SingleLineWrappedinput entryName="Full name of worker: " placeholderText="John Smith" value={workerName} setValue={setWorkerName} styleProp={{ marginBottom: '10px'}} validity={consent !== false} editable={consent !== true}/>
                        <SingleLineWrappedinput entryName="Date: " placeholderText="DD/MM/YYYY" value={consentDate} setValue={setConsentDate} validity={consent !== false} editable={consent !== true}/>

                        <ConsentButton setConsent={setConsent} dependancies={[workerName, consentDate]} consent={consent} consentText="I confirm that (Service User's Name) has capacity to consent to being part of the study and has been given information regarding the technology being provided"/>

                        <h3 className="referral__consentDocumentText" >Should the service user's capacity to the pilot change, please inform the AT team at the following email address: <br /> SampleEmail@hertfordshire.gov.uk</h3>
                    </div>

                    {consent === true ?
                        <div className="referral__formSection">
                            <div className="referral__spacerline" />

                            <h2 className="referral__consentDocumentText"> Please provide your contact information below, all fields are required</h2>
                            <div className="referral__formRow">
                                <SingleLineWrappedinput entryName="Date of Referral: " placeholderText="DD/MM/YYYY" value={referredBy} setValue={setReferredBy} styleProp={{ marginBottom: '10px', marginTop: '20px' }} validity={validity !== false} editable={consent !== true} />
                            </div>

                            <div className="referral__formRow">
                                <SingleLineWrappedinput entryName="Full name of referrer: " placeholderText="John Smith" value={referredBy} setValue={setReferredBy} styleProp={{ marginBottom: '10px' }} validity={validity !== false} editable={validity !== true} />
                                <SingleLineWrappedinput entryName="Organization/Team: " placeholderText="e.g. hertfordshire County Council" value={referredBy} setValue={setReferredBy} styleProp={{ marginBottom: '10px' }} validity={validity !== false} editable={validity !== true} />
                            </div>

                            <div className="referral__formRow">
                                <SingleLineWrappedinput entryName="Contact number: " placeholderText="Landline or Mobile" value={referredBy} setValue={setReferredBy} styleProp={{ marginBottom: '10px' }} validity={validity !== false} editable={validity !== true} />
                                <SingleLineWrappedinput entryName="Email Address: " placeholderText="ExampleEmail@hertfordshire.gov.uk" value={referredBy} setValue={setReferredBy} styleProp={{ marginBottom: '10px' }} validity={validity !== false} editable={validity !== true} />
                            </div>

                            <div className="referral__spacerline" style={{ marginTop: '40px', marginBottom:'25px' }} />

                            <h2 className="referral__consentDocumentText"> Service User details, all fields are required</h2>
                            <div className="referral__formRow">
                                <SingleLineWrappedinput entryName="Name of service user: " placeholderText="DD/MM/YYYY" value={referredBy} setValue={setReferredBy} styleProp={{ marginBottom: '10px', marginTop: '20px' }} validity={validity !== false} editable={validity !== true} />
                                <SingleLineWrappedinput entryName="Acsis reference: " placeholderText="########" value={referredBy} setValue={setReferredBy} styleProp={{ marginBottom: '10px', marginTop: '20px' }} validity={validity !== false} editable={validity !== true} />
                            </div>

                            <div className="referral__formRow">
                                <SingleLineWrappedinput entryName="Address line 1: " placeholderText="" value={referredBy} setValue={setReferredBy} styleProp={{ marginBottom: '10px', marginTop: '20px' }} validity={validity !== false} editable={validity !== true} />
                                <SingleLineWrappedinput entryName="Postcode: " placeholderText="" value={referredBy} setValue={setReferredBy} styleProp={{ marginBottom: '10px', marginTop: '20px' }} validity={validity !== false} editable={validity !== true} />
                            </div>

                            <div className="referral__formRow">
                                <SingleLineWrappedinput entryName="Address line 2: " placeholderText="" value={referredBy} setValue={setReferredBy} styleProp={{ marginBottom: '10px' }} validity={validity !== false} editable={validity !== true} />
                                <SingleLineWrappedinput entryName="County: " placeholderText="" value={referredBy} setValue={setReferredBy} styleProp={{ marginBottom: '10px' }} validity={validity !== false} editable={validity !== true} />
                            </div>

                            <div className="referral__formRow">
                                <SingleLineWrappedinput entryName="House number/name: " placeholderText="" value={referredBy} setValue={setReferredBy} styleProp={{ marginBottom: '10px' }} validity={validity !== false} editable={validity !== true} />
                            </div>

                            <div className="referral__formRow">
                                <SingleLineWrappedinput entryName="Contact number: " placeholderText="Landline or Mobile" value={referredBy} setValue={setReferredBy} styleProp={{ marginBottom: '10px', marginTop: '20px' }} validity={validity !== false} editable={validity !== true} />
                                <SingleLineWrappedinput entryName="Pets?: " placeholderText="e.g. 1 cat, 1 dog, or none" value={referredBy} setValue={setReferredBy} styleProp={{ marginBottom: '10px', marginTop: '20px' }} validity={validity !== false} editable={validity !== true} />
                            </div>

                            <div className="referral__formRow">
                                <SingleLineWrappedinput entryName="Existing community alarm provider: " placeholderText="Please provide a name or 'none'" value={referredBy} setValue={setReferredBy} styleProp={{ marginBottom: '10px', marginTop: '20px' }} validity={validity !== false} editable={validity !== true} />
                            </div>

                            <div className="referral__spacerline" style={{ marginTop: '40px', marginBottom: '25px' }} />

                            <h2 className="referral__consentDocumentText"> Next of Kin, all fields are required</h2>
                            <div className="referral__formRow">
                                <SingleLineWrappedinput entryName="Full name: " placeholderText="John Smith" value={referredBy} setValue={setReferredBy} styleProp={{ marginBottom: '10px', marginTop: '20px' }} validity={validity !== false} editable={validity !== true} />
                                <SingleLineWrappedinput entryName="Contact Number: " placeholderText="Landline or Mobile" value={referredBy} setValue={setReferredBy} styleProp={{ marginBottom: '10px', marginTop: '20px' }} validity={validity !== false} editable={validity !== true} />
                            </div>
                            <div className="referral__formRow">
                                <SingleLineWrappedinput entryName="Email address: " placeholderText="ExampleEmail@hertfordshire.gov.uk" value={referredBy} setValue={setReferredBy} styleProp={{ marginBottom: '10px' }} validity={validity !== false} editable={validity !== true} />
                            </div>
                        </div>
                    : null}
                </div>

            </div>
        </div> 

    ) 
}

export default ServiceUserReferral