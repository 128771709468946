import * as React from 'react';
import { RouteComponentProps } from "@reach/router";
import { useState, useEffect } from 'react';
import { DashboardTile } from './DashboardTile';
import './DashboardHome.css';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { ServiceUserHistory } from './ServiceUserHistory';
import { HubConnectionBuilder, HubConnection } from '@aspnet/signalr';
import { sleepFactGenerator } from '../../summaryGenerator/FactGenerators/sleepFactGenerator';
import { fallFactGenerator } from '../../summaryGenerator/FactGenerators/fallFactGenerator';
import { medicationFactGenerator } from '../../summaryGenerator/FactGenerators/medicationFactGenerator';
import { mobilityFactGenerator } from '../../summaryGenerator/FactGenerators/mobilityFactGenerator';
import { paragraphGenerator } from '../../summaryGenerator/paragraphGenerator';
import moment from 'moment';
import { authProvider } from '../../authProvider';

export interface DashboardHomeProps extends RouteComponentProps {
    acsis: number;
    name: string;
    gender: string;
}

export interface TileData {
    recordType: string;
    data30Days: TileDataSpark[]
}

export interface TileDataSpark {
    dateTime: string;
    mobilityIndex: number;
}

const tileColours: { [key: string]: { background: string, foreground: string } } = {
    "green": {
        background: "#3A7D44", // Maybe BCE784
        foreground: "#FFFFFF"
    },
    "amber": {
        background: "#ff9900", 
        //background: "#FFD439", 
        foreground: "#FFFFFF"
    },
    "orange": {
        background: "#F4743B",
        //background: "#FF7A36",
        //background: "#e76f51",
        foreground: "#FFFFFF"
    },
    "red": {
        background: "#A22C29", // Old one DD4949
        foreground: "#FFFFFF"
    },
    "grey": {
        background: "#CCCCCC",
        foreground: "#000000"
    }
};

export function DashboardHome(props: DashboardHomeProps) {

    const [hasError, setErrors] = useState(false);
    const [routeTimings, setRouteTimings] = useState<any | null>(null);
    const [tiles, setTiles] = useState<any | null>(null);
    const [hubConnection, setHubConnection] = useState<HubConnection>();

    useEffect(() => {
        console.log('useEffect');
        async function fetchData() {

            console.log('start fetch');

            const res2 = await fetch(`/api/serviceUser?acsis=${props.acsis}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            res2
                .json()
                .then((res: any) => {
                    console.log(res);
                    setTiles(res);
                })
                .catch(err => setErrors(err));

            const res = await fetch(`/api/RouteTimings?startDate=${moment().subtract(1, 'm').toISOString()}&endDate=${moment().toISOString()}&acsis=${props.acsis}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            res
                .json()
                .then((res: any) => {
                    console.log(res);
                    setRouteTimings(res);
                })
                .catch(err => setErrors(err));

            
        }
        fetchData();

        const createHubConnection = async () => {
            const accessToken = (await authProvider.getAccessToken()).accessToken;

            const hubConnect = new HubConnectionBuilder()
                .withUrl('/event', {
                    accessTokenFactory: () => accessToken
                })
                .build();

            try {
                await hubConnect.start();
                console.log("Connected to hub");

                //await hubConnect.invoke('AddToGroup', 'raw');
                //                await hubConnect.invoke('AddToGroup', '4334943');

                await hubConnect.invoke('AddToGroup', props.acsis.toString());

                console.log(`Joined group "${props.acsis.toString()}"`);

                hubConnect.on('Send', (name: string, message: string) => {
                    //setFeed(m => [...m, message]);
                    console.log(message);
                    let mess = JSON.parse(message);


                    if (mess.RecordType == 'TileDataRecord') {
                        setTiles(mess);
                    }

                });
            }
            catch (err) {
                console.log(err);
            }

            setHubConnection(hubConnect);
        };

        createHubConnection();
    }, []);

    let activityColour = 'grey' as string;
    let sleepColour = 'grey' as string;
    let sustenanceColour = 'grey' as string;
    let medicationColour = 'grey' as string;
    let mobilityColour = 'grey' as string;
    let walkingWithPurposeColour = 'grey' as string;
    let toiletFrequencyColour = 'grey' as string;
    let networkColour = 'grey' as string;
    let fallColour = 'grey' as string;
    let panicButtonColour = 'grey' as string;

    if (tiles?.tiles?.dashboard) {
        activityColour = tiles.tiles.dashboard.activity.colour;
        sleepColour = tiles.tiles.dashboard.sleep.colour;
        sustenanceColour = tiles.tiles.dashboard.sustenance.colour;
        medicationColour = tiles.tiles.dashboard.medication.colour;
        mobilityColour = tiles.tiles.dashboard.mobility.colour;
        walkingWithPurposeColour = tiles.tiles.dashboard.walkingWithPurpose.colour;
        toiletFrequencyColour = tiles.tiles.dashboard.toiletFrequency.colour;
        networkColour = tiles.tiles.dashboard.network.colour;
        fallColour = tiles.tiles.dashboard.fall.colour;
        panicButtonColour = tiles.tiles.dashboard.panicButton.colour;
    } else {

    }
    console.log(activityColour, sleepColour, sustenanceColour, medicationColour, mobilityColour, walkingWithPurposeColour, toiletFrequencyColour, networkColour, fallColour, panicButtonColour);

/* Mobility logic */
    let mobilityData:any[] = [];
    let resources = routeTimings;
    if (resources && resources.length > 0) {
        let OUTPUTARRAY: [Date, number[]][] = [];
        for (let i = 0; i <= resources.length - 1; i++) {
            OUTPUTARRAY.push([new Date(resources[i].StartDateTime), []])
        }
        let timings: number[] = [];
        let keys: string[] = [];
        const removedProperties = ['id', '_rid', '_self', '_etag', '_attachments', '_ts', 'RecordType', 'ACSIS', 'DateTime'];
        resources.map((record: any) => {
            for (let property of removedProperties) {
                delete record[property];
            };
            timings.push(record);
            for (let key of Object.keys(record)) {
                keys.push(key);
            }
        });
        let uniqueKeys = [...new Set(keys)];
        uniqueKeys.shift();
        // Outlier removal, taking the full range, remove the first set of 'Extreme Outliers' by removing the top 1 standard deviation.
        // then find the standard deviation for a second time, and remove the actual outliers.
        // this will leave the function with an 'upper cap' that can be applied to all further analysis within this key
        for (let key of uniqueKeys) {
            let keyTimings: number[] = [];
            let coreKeyArray: any[] = []
            resources.map((record: any) => {
                if (record[key]) {
                    keyTimings.push(...record[key]);
                    coreKeyArray.push(record[key]);
                } else {
                    coreKeyArray.push(undefined);
                }
            });
            // first wave of outlier removal
            const mean1: number = keyTimings.reduce(function (a, b) {
                return a + b;
            }, 0) / keyTimings.length;

            const std1: number = Math.sqrt((keyTimings.reduce(function (a, b) {
                return a + ((b - mean1) * (b - mean1));
            })) / keyTimings.length);

            let initialOutlierRange = keyTimings.filter((timing) => {
                return timing < mean1 + std1;
            });

            // second wave of outlier removal and upper boundary calculation

            const mean2: number = initialOutlierRange.reduce(function (a, b) {
                return a + b
            }, 0) / initialOutlierRange.length;

            const std2: number = Math.sqrt((initialOutlierRange.reduce(function (a, b) {
                return a + ((b - mean2) * (b - mean2));
            })) / initialOutlierRange.length);
            let keyUpperBound = std2 + mean2;

            // removal of outliers from the core key array ( the array which shall be used for any further analysis )
            // the remainig values then are averaged, and tunred into z scores to standardise the data across each signature route.
            // at the final step, all undefined numbers are turned returned to 0 (no effect on overall mobility index)
            // these values are then added tothe oputput array, item[1] = array
            coreKeyArray = coreKeyArray.map((arr) => {
                if (arr != undefined) {
                    arr = arr.filter((val: any) => {
                        return val < keyUpperBound;
                    })
                    if (arr.length == 0) {
                        arr = undefined;
                    }
                }
                return arr;
            });
            coreKeyArray = coreKeyArray.map((arr) => {
                if (arr != undefined) {
                    let output = ((arr.reduce((a:any, b:any) => {
                        return a + b
                    }, 0) / arr.length) - mean2) / std2 * -1;
                    return output
                } else {
                    return 0
                }
            });
            // append to the output array by adding to the previously defined position contining the Date object at position 0
            for (let i = 0; i <= coreKeyArray.length - 1; i++) {
                OUTPUTARRAY[i][1].push(coreKeyArray[i]);
            }
        };
        // sum the Z score array and return 
        let noNullArray = OUTPUTARRAY.map((tuple) => {
            return [tuple[0], tuple[1].reduce((a, b) => {
                return a + b
            }, 0)];
        });
        mobilityData = noNullArray.map((tuple) => {
            if (tuple[1] == 0) {
                return [tuple[0], null]
            } else {
                return tuple
            }
        })
    }

    /* Mobility logic end */

    /* Paragraph */
    /*
    Get TDR, find all tiles that are no longer in the state of grey
    This will allow for null sentences to be inserted only when relevant 
    */
    console.log(mobilityData.length)

    let summaryText = "Loading summary information...";

    let events: any[] = [];
    
    if (tiles?.tiles?.dashboard != null) {
        let tileData = tiles.tiles.dashboard;
        let tileKeys = Object.keys(tileData);
        tileKeys = tileKeys.filter(key => {
            if (tileData[key].Colour != 'grey' && key.toLowerCase() != 'battery') {
                return key;
            }
        });

        /*
        All active tiles have been found
        Group the events into their categories, as defined by the active tiles
        */
        let activityEvents: any[] = [];
        let sleepEvents: any[] = [];
        let sustenanceEvents: any[] = [];
        let medicationEvents: any[] = [];
        let mobilityEvents: any[] = [];
        let walkingWithPurposeEvents: any[] = [];
        let toiletEvents: any[] = [];
        let networkEvents: any[] = [];
        let fallEvents: any[] = [];

        events = events.concat(
            tiles.tiles.dashboard.activity.decay,
            tiles.tiles.dashboard.sleep.decay,
            tiles.tiles.dashboard.sustenance.decay,
            tiles.tiles.dashboard.medication.decay,
            tiles.tiles.dashboard.mobility.decay,
            tiles.tiles.dashboard.walkingWithPurpose.decay,
            tiles.tiles.dashboard.toiletFrequency.decay,
            tiles.tiles.dashboard.network.decay,
            tiles.tiles.dashboard.fall.decay,
            tiles.tiles.dashboard.panicButton.decay
        );

        console.log('Events');
        console.log(events);

        events.map((record: any) => {
            record.StartDateTime = record.DateTime;

            if (record.RuleID >= 1000 && record.RuleID < 2000 && activityEvents) {
                activityEvents.push(record)
            } else if (record.RuleID >= 2000 && record.RuleID < 3000 && sleepEvents) {
                sleepEvents.push(record)
            } else if (record.RuleID >= 3000 && record.RuleID < 4000 && sustenanceEvents) {
                sustenanceEvents.push(record)
            } else if (record.RuleID >= 4000 && record.RuleID < 5000 && medicationEvents) {
                medicationEvents.push(record);
            } else if (record.RuleID >= 5000 && record.RuleID < 6000 && mobilityEvents) {
                mobilityEvents.push(record)
            } else if (record.RuleID >= 6000 && record.RuleID < 7000 && walkingWithPurposeEvents) {
                walkingWithPurposeEvents.push(record)
            } else if (record.RuleID >= 7000 && record.RuleID < 8000 && toiletEvents) {
                toiletEvents.push(record);
            } else if (record.RuleID >= 8000 && record.RuleID < 9000 && networkEvents) {
                networkEvents.push(record)
            } else if (record.RuleID >= 9000 && record.RuleID < 10000 && fallEvents) {
                fallEvents.push(record);
            }
        });

        let personalInfo = {
            Name: props.name,
            Gender: props.gender
        };

        /* 
        All Events have now been grouped into their categories
        Active categories then have their data passed through fact generating functions, there is an individual function for each category.
        These functions produce scentence branches for both urgent and basic paragraphs, to be consolidated later from the basic and urgent arrays.
        */
        let basic = [];
        let urgent = [];
        if (tileKeys.findIndex(key => key == "Activity") != -1) {
            console.log('Activity tile is currently active')
        }
        if (tileKeys.findIndex(key => key == "Sleep") != -1 || true) {
            console.log('Sleep tile is currently active')
            let { sleepBasic, sleepUrgent } = sleepFactGenerator(fallEvents, personalInfo);
            basic.push(sleepBasic);
            urgent.push(sleepUrgent);
        }
        if (tileKeys.findIndex(key => key == "Sustenance") != -1) {
            console.log('Sustenance tile is currently active')
        }
        if (tileKeys.findIndex(key => key == "Medication") != -1) {
            console.log('Medication tile is currently active');
            let { medicationBasic, medicationUrgent } = medicationFactGenerator(fallEvents, personalInfo);
            basic.push(medicationBasic);
            urgent.push(medicationUrgent);
        }
        if (tileKeys.findIndex(key => key == "Mobility") != -1 || true) {
            console.log('Mobility tile is currently active')
            let { mobilityBasic, mobilityUrgent, mobilityTileColour } = mobilityFactGenerator(mobilityData, personalInfo);
            basic.push(mobilityBasic);
            urgent.push(mobilityUrgent);
        }
        if (tileKeys.findIndex(key => key == "WalkingWithPurpose") != -1) {
            console.log('Walking With Purpose tile is currently active')
        }
        if (tileKeys.findIndex(key => key == "ToiletFrequency") != -1) {
            console.log('Toilet Frequency tile is currently active')
        }
        if (tileKeys.findIndex(key => key == "Network") != -1) {
            console.log('Network tile is currently active')
        }
        if (tileKeys.findIndex(key => key == "Fall") != -1 || true) {
            console.log('Fall tile is currently active')
            let { fallBasic, fallUrgent } = fallFactGenerator(fallEvents, personalInfo);
            basic.push(fallBasic);
            urgent.push(fallUrgent);
        }
        console.log('')
        let basicParagraph = paragraphGenerator(basic, personalInfo)
        let urgentParagraph = paragraphGenerator(urgent, personalInfo)
        console.log("---URGENT---")
        console.log(urgentParagraph)
        console.log('---BASIC---') 
        console.log(basicParagraph)

        summaryText = basicParagraph;
    }

    /* Paragraph */

    return (
        <div>
            <div className="dashboard-home__title"><h1>Summary</h1></div>
            <div className="dashboard-home__headline shadow corners">{summaryText}</div>
            <div style={{ position: 'relative', marginTop: '20px' }} className="dashboard-home__history"><ServiceUserHistory acsisID={props.acsis} events={events} /></div>
            <div className="dashboard-home__tiles">
                <DashboardTile value="" measure="Activity" colours={tileColours[activityColour]} path="mobility" acsis={props.acsis} />
                <DashboardTile value="" measure="Sleep" colours={tileColours[sleepColour]} icon="moon" path="sleep" acsis={props.acsis} />
                <DashboardTile value="" measure="Sustenance" colours={tileColours[sustenanceColour]} icon="kitchen" path="sustenance" acsis={props.acsis} />
                <DashboardTile value="" measure="Medication" colours={tileColours[medicationColour]} icon="hospital" path="medication" acsis={props.acsis} />
                <DashboardTile value="" measure="Mobility" spark={mobilityData} colours={tileColours[mobilityColour]} icon="speed" path="mobilityIndex" acsis={props.acsis} />
                <DashboardTile value="" measure="Walking with Purpose" colours={tileColours[walkingWithPurposeColour]} icon="walk" path="walk" acsis={props.acsis} />
                <DashboardTile value="" measure="Toilet Frequency" colours={tileColours[toiletFrequencyColour]} icon="toilet" path="toiletFrequency" acsis={props.acsis} />
                <DashboardTile value="" measure="Network" colours={tileColours[networkColour]} icon="network" path="network" acsis={props.acsis} />
                <DashboardTile value="" measure="Falls" colours={tileColours[fallColour]} icon="fall" path="falls" acsis={props.acsis} />
                <DashboardTile value="" measure="Temperature" colours={tileColours[fallColour]} icon="temperature" path="temperature" acsis={props.acsis} />
                <DashboardTile value="" measure="Light" colours={tileColours[fallColour]} icon="light" path="light" acsis={props.acsis} />
            </div>
        </div>
    );
}

export default DashboardHome;
//TODO: Fill tiles with a grey loading thing until we get tile data, instead of just hiding it

/*
 * public class MobilityTile
    {
        public MobilityTileData[] Data30Days { get; set; }
    }

    public class MobilityTileData
    {
        public string DateTime { get; set; }
        public float MobilityIndex { get; set; }
    }
    */