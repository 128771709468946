import * as React from 'react';
import './styles.css';
import {colouredHeaderProps} from './types'
import {GridComponent} from '../../basic/gridComponent/gridComponent';
import Tile from '../../basic/tile/tile';
import {colourScale5} from '../../const';
import IndexTile from '../../basic/indexTile/indexTile'

export function ColouredHeader(props: colouredHeaderProps) {
    let children = [<IndexTile header={true} text={props.title} />];
    for (let i=0; i < props.size; i++) {
        children.push(
            <Tile header={true} index={i} color={colourScale5[i]} editable={false} existingScores={null} questionName=""/>
        )
    }
    return (
        <GridComponent size={props.size !== 0 ? props.size : 1}>
            {children}
        </GridComponent>
    )
}

export default ColouredHeader;