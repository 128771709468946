import React, { useRef, useEffect, useState } from 'react'
import * as d3 from 'd3';
import moment from 'moment'

import { dataStructure, datapoint, lineProps, pointValue } from '../dashboardPages/WalkingWithPurpose';

export default function LineChart(props: lineProps) {
    let svgWidth = 0;
    let svgHeight = 0;

    const [isHovered, setHovered] = useState(false);
    const isHoveredRef = useRef(isHovered);
    isHoveredRef.current = isHovered;

    const [isLocked, setIsLocked] = useState(false);
    const isLockedRef = useRef(isLocked);
    isLockedRef.current = isLocked;

    const d3Container = useRef(null);
    const maxValue = 100;
    const minValue = 0;

    let MARGIN = { LEFT: 50, RIGHT: 50, TOP: 30, BOTTOM: 30 };
    let WIDTH = svgWidth - MARGIN.LEFT - MARGIN.RIGHT;
    let HEIGHT = svgHeight - MARGIN.TOP - MARGIN.BOTTOM;

    let timeout = false;

    useEffect(() => {
        function handlePieHover() {

            MARGIN = { LEFT: 50, RIGHT: 50, TOP: 30, BOTTOM: 30 };
            WIDTH = svgWidth - MARGIN.LEFT - MARGIN.RIGHT;
            HEIGHT = svgHeight - MARGIN.TOP - MARGIN.BOTTOM;

            if (props.pieSelectedSlice != null) {
                let line = `.${props.pieSelectedSlice}_line`;
                d3.select(d3Container.current).select(line)
                    .attr('stroke-width', 3)

                for (let i = 0; i < props.pointValues.length; i++) {
                    if (props.pointValues[i].name === props.pieSelectedSlice) {
                        continue;
                    }
                    let line = `.${props.pointValues[i].name}_line`;
                    d3.select(d3Container.current).select(line)
                        .attr('stroke-width', 2)
                        .attr('opacity', 0.3)
                }

            } else {
                for (let i = 0; i < props.pointValues.length; i++) {
                    let line = `.${props.pointValues[i].name}_line`;
                    d3.select(d3Container.current).select(line)
                        .attr('opacity', 1)
                        .attr('stroke-width', 2)
                }
            }

        }
        handlePieHover();
    }, [props.pieSelectedSlice])

    useEffect(() => {
        let delta = 1000;
        function initDraw() {
            console.log('initial draw?')
            let resizeStart = new Date();
            if (timeout === false) {
                timeout = true;
                setTimeout(drawLine, delta)
            }
        }
        initDraw()
        window.addEventListener('resize', initDraw);
        return () => {
            window.removeEventListener('resize', initDraw);
        }
    }, [props.data, props.pointValues]);

    function drawLine() {
        timeout = false;
        console.log('Cnvas??')
        const canvas: any = d3.select(d3Container.current);
        svgWidth = canvas.node().getBoundingClientRect().width;
        svgHeight = canvas.node().getBoundingClientRect().height;

        MARGIN = { LEFT: 50, RIGHT: 50, TOP: 30, BOTTOM: 30 };
        WIDTH = svgWidth - MARGIN.LEFT - MARGIN.RIGHT;
        HEIGHT = svgHeight - MARGIN.TOP - MARGIN.BOTTOM;

        if (isLockedRef.current === true) {
            setIsLocked(false);
            props.setPieData(null);
            //setHovered(false); ???? might be needed? investigation required
        }

        if (props.data.length === 0 || props.pointValues.length === 0) {
            console.log(props.data)
            return
        }

        if (props.data != null && props.data.length > 0) {
            props.setPieDataDefault(props.data[props.data.length - 1])
        }

        // Clear any svg components from the canvas as the line will be re-drawn multiple times as the screen is resized
        canvas.selectAll('*').remove();

        const svg = d3.select(d3Container.current)
            .attr('width', WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
            .attr('height', HEIGHT + MARGIN.TOP + MARGIN.BOTTOM)
            .append('g')
            .attr('transform', `translate(${MARGIN.LEFT}, ${MARGIN.TOP})`)
            .attr('fill', 'blue')
            .attr('id', 'canvas')

        let dates: any = [];
        let dataArray = props.data.map(x => {
            dates.push(moment(x.startDateTime))
            return x.data
        });
        let mappedData: any = [];

        for (let i = 0; i < dataArray.length; i++) {
            for (let ii = 0; ii < dataArray[i].length; ii++) {
                let index = mappedData.findIndex((x: any) => x.name === dataArray[i][ii].name);
                if (index === -1) {
                    let array = new Array(i).fill(0);
                    array[i] = dataArray[i][ii].value;
                    let object = {
                        name: dataArray[i][ii].name,
                        data: array
                    }
                    mappedData.push(object)
                } else {
                    mappedData[index].data.push(dataArray[i][ii].value)
                }
            }
        }

        var x = d3.scaleTime()
            .domain([moment(dates[0]).subtract(0, 'month'), dates[dates.length - 1]])
            .range([0, WIDTH]);

        var y = d3.scaleLinear()
            .domain([minValue, maxValue])
            .range([HEIGHT, 0]);

        svg.append('g')
            .call(d3.axisLeft(y))

        svg.append('g')
            .attr("transform", "translate(0," + HEIGHT + ")")
            .call(d3.axisBottom(x).tickFormat((x: any) => moment(x).format('MMM-YYYY')).ticks(dates.length))

        for (let i = 0; i < mappedData.length; i++) {
            svg.append("path")
                .datum(mappedData[i].data)
                .attr("fill", "none")
                .attr('pointer-events', 'none')
                .attr("stroke", handleColor(mappedData[i].name, props.pointValues))
                .attr('position', 'relative')
                .attr('class', mappedData[i].name + '_line')
                .attr("stroke-width", 2)
                .attr('id', mappedData[i].name)
                .attr("d", d3.line<any>()
                    .x(function (d: any, i: number) { return x(dates[i]) })
                    .y(function (d: any) { return y(handleYValues(mappedData[i].name, d, maxValue, props.pointValues)) })
                    .curve(d3.curveMonotoneX)
                )
        }

        var toolTip = svg.append('g')
            .style('visibility', 'hidden')
            .style('position', 'relative')

        var toolTipIndicator = toolTip.append('rect')
            .attr('height', HEIGHT)
            .attr('pointer-events', 'none')
            .attr('width', 2)
            .attr('position', 'absolute')
            .style("fill", "none")
            .style("opacity", 0.7)
            .style("border-radius", 3)
            .style("stroke", "#6E6E6E")
            .style("stroke-width", 2)

        d3.select(d3Container.current)
            .on("mouseover", function (event) { handleMouseOver(event, setHovered, isLockedRef, toolTip) })
            .on("mousemove", function (event) { handleMouseMove(event, isLockedRef, toolTip, toolTipIndicator, MARGIN, WIDTH, x, dates, props.setPieData, props.pieData, mappedData) })
            .on("mouseout", function (event) { handleMouseOut(event, isLockedRef, toolTip, props.setPieData, setHovered) })
            .on('click', function (event) { handleMouseDown(event, isLockedRef, isHoveredRef, toolTipIndicator, setIsLocked) })

    };

    return (
        <svg id="walkingWithPurposeLine" ref={d3Container}/>
    )
            
}

function handleYValues(name: string, dataPoint: number, maxValue: number, pointValues: pointValue[]) {
    let index = pointValues.findIndex(x => x.name === name);
    if (index === -1) {
        console.error('Incorrect data formats ahve been provided');
        return -1
    }
    let returnValue = (maxValue / pointValues[index].radiusScaleFactor) * dataPoint;
    if (returnValue > maxValue) {
        return maxValue
    }
    return returnValue
}

function handleColor(name: string, pointValues: pointValue[]) {
    let index = pointValues.findIndex(x => x.name === name);
    if (index === -1) {
        console.error('Incorrect data formats have been provided');
        console.log(name)
        return -1
    }
    return pointValues[index].color
}

function conversionHandler(xPos: number, MARGIN: { [key: string]: number }, x: d3.ScaleTime<number, number, never>, dates: any) {
    var bisect = d3.bisector(function (d: any) { return d; }).center//.left;
    let xValue = x.invert(xPos - MARGIN.LEFT);
    let index = bisect(dates, moment(xValue.toISOString()))
    if (index < 0 || index > dates.length - 1) {
        return null
    }
    return {
        xPos: dates[index],
        index
    };
}

function choppyBoy(xCoord: number, lineName: string, MARGIN: { [key: string]: number }, x: d3.ScaleTime<number, number, never>, dates: any, mappedData: any) {
    let xValue = x.invert(xCoord - MARGIN.LEFT);
    let bisectRight = d3.bisector(function (d: any) { return d; }).right;
    let x2_index = bisectRight(dates, moment(xValue.toISOString()));
    let x1_index = x2_index - 1;
    if (x2_index === 0) {
        return null
    }
    let index = mappedData.findIndex((x: any) => x.name === lineName);
    if (index === -1) {
        console.log('Incorrect line name')
        return null
    }
    let x1 = x(dates[x1_index]);
    let x2 = x(dates[x2_index]);
    let y1 = mappedData[index].data[x1_index];
    let y2 = mappedData[index].data[x2_index];

    let gradient = (y2 - y1) / (x2 - x1);

    let changeInX = xCoord - x1;
    let changeInY = gradient * changeInX;
    let newY = y1 + changeInY;

    return newY;
}


function handleMouseOver(event: any, setHovered: React.Dispatch<React.SetStateAction<boolean>>, isLockedRef: React.MutableRefObject<boolean>, toolTip: d3.Selection<SVGGElement, unknown, null, undefined>) {
    setHovered(true);
    if (isLockedRef.current === false) {
        toolTip.style('visibility', 'visible');
    }

}

function handleMouseMove(event: any, isLockedRef: React.MutableRefObject<boolean>, toolTip: d3.Selection<SVGGElement, unknown, null, undefined>, toolTipIndicator: d3.Selection<SVGRectElement, unknown, null, undefined>, MARGIN: { [key: string]: number }, WIDTH: number, x: d3.ScaleTime<number, number, never>, dates: any, setPieData: React.Dispatch<React.SetStateAction<dataStructure | null>>, pieData: dataStructure | null, mappedData: any) {
    if (isLockedRef.current === true) {
        return;
    }
    let conversionOutput = conversionHandler(d3.pointer(event)[0], MARGIN, x, dates);
    if (conversionOutput === null) {
        toolTip.style('visibility', 'hidden');
        toolTipIndicator.style('visibility', 'hidden');
        setPieData(null);
        return
    }
    toolTip.style('visibility', 'visible')

    if (d3.pointer(event)[0] < 30 || d3.pointer(event)[0] > WIDTH + MARGIN.LEFT) {
        toolTip.style('visibility', 'hidden');
        setPieData(null);
        return;
    }

    let { xPos, index } = conversionOutput;
    let dataObject: dataStructure = {
        startDateTime: moment(xPos).toISOString(),
        endDateTime: moment(xPos).add(1, 'month').toISOString(),
        data: [],
    }

    for (let i = 0; i < mappedData.length; i++) {
        let lineName: string = mappedData[i].name
        let className = `.${lineName}_cursor`
        let lineIndex = mappedData.findIndex((x: datapoint) => x.name === lineName);
        if (lineIndex === -1) {
            console.error('Incorrect data formats have been provided');
            return
        }

        let value = choppyBoy(d3.pointer(event)[0], lineName, MARGIN, x, dates, mappedData)
        if (value != null) {
            dataObject.data.push({
                name: lineName,
                value
            })
        }

    }

    if (dataObject != pieData) {
        setPieData(dataObject)
    }

    toolTipIndicator.style('transform', `translate(${d3.pointer(event)[0] - MARGIN.LEFT}px, ${0}px)`);

}

function handleMouseOut(event: any, isLockedRef: React.MutableRefObject<boolean>, toolTip: d3.Selection<SVGGElement, unknown, null, undefined>, setPieData: React.Dispatch<React.SetStateAction<dataStructure | null>>, setHovered: React.Dispatch<React.SetStateAction<boolean>>) {
    if (isLockedRef.current === false) {
        toolTip.style('visibility', 'hidden')
        setPieData(null)
    }
    setHovered(false);
}

function handleMouseDown(event: any, isLockedRef: React.MutableRefObject<boolean>, isHoveredRef: React.MutableRefObject<boolean>, toolTipIndicator: d3.Selection<SVGRectElement, unknown, null, undefined>, setIsLocked: React.Dispatch<React.SetStateAction<boolean>>) {
    if (isHoveredRef.current === true && isLockedRef.current === false) {
        toolTipIndicator.style('stroke', 'black');
        setIsLocked(true);
    } else if (isHoveredRef.current === true && isLockedRef.current === true) {
        toolTipIndicator.style('stroke', "#6E6E6E");
        setIsLocked(false);
    }

}