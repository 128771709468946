import * as React from 'react';
import { useState, useRef, useEffect } from "react";
import * as d3 from "d3";
import { scaleTime, scaleBand, scaleOrdinal } from "d3-scale";
import { axisBottom, axisLeft } from "d3-axis";
import { Tableau10 } from "../../utils/colours";
import { MedicationData, MedicationWindowData } from "../dashboardPages/Medication";
// import { ContextModal } from './ContextModal';
import './GanttChart.css';
import "./CommonGraph.css";
import moment from 'moment';
import Modal from 'react-modal';
import { GanttChart, GanttChartData } from './GanttChart'
import { NetworkGraphData } from './NetworkGraph';
import { authProvider } from '../../authProvider';


export interface MedicationChartProps {
    data: MedicationData[] | null;
    windowData: MedicationWindowData[] | null;
    height: number;
    width: number;
    acsis: number;
}

export interface ContextModalProps {
    target: any;
    setTarget: Function;
    acsis: number;
}

export function GanttWrapper(props: { target: any; acsisID: number }) {

    const [hasError, setErrors] = useState(false);
    const [mobility, setMobility] = useState<GanttChartData[] | null>(null);
    const [light, setLight] = useState<GanttChartData[] | null>(null);
    const [temperature, setTemperature] = useState<GanttChartData[] | null>(null);
    const [appliance, setAppliance] = useState<GanttChartData[] | null>(null);
    const [door, setDoor] = useState<GanttChartData[] | null>(null);
    const [network, setNetwork] = useState<NetworkGraphData | null>(null);
    const [toggles, setToggles] = useState<any>({});
    const [applianceLocations, setApplianceLocations] = useState<any | null>(null);



    //const [timeFrame, setTimeFrame] = useState({ startDate: moment().subtract(1, 'days'), endDate: moment() });

    const [timeFrame, setTimeFrame] = useState({ startDate: moment(props.target.startDateTime).subtract(6, 'hours'), endDate: moment(props.target.startDateTime).add(6, 'hours') });

    console.log('MobTriggered');
    useEffect(() => {
        console.log('useEffect');
        async function fetchData() {
            let fixedStart = moment(timeFrame.startDate);
            let fixedEnd = moment(timeFrame.endDate);

            const mobilityRes = fetch(`/api/mobility?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            const lightRes = fetch(`/api/light?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            const temperatureRes = fetch(`/api/temperature?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            const applianceRes = fetch(`/api/appliance?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            const doorRes = fetch(`/api/door?startDate=${fixedStart.toISOString()}&endDate=${fixedEnd.toISOString()}&acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            const networkRes = fetch(`/api/network?acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});
            const applianceLocRes = fetch(`/api/applianceLocation?acsis=${props.acsisID}`, {headers: {Authorization: 'Bearer ' + (await authProvider.getAccessToken()).accessToken}});

            Promise.all([
                mobilityRes.then(res => res.json()).then((mobilityRes: GanttChartData[]) => {
                    console.log(mobilityRes);
                    setMobility(mobilityRes);
                }).catch(err => setErrors(err)),
                lightRes.then(res => res.json()).then((lightRes: GanttChartData[]) => {
                    console.log(lightRes);
                    setLight(lightRes);
                }).catch(err => setErrors(err)),
                temperatureRes.then(res => res.json()).then((temperatureRes: GanttChartData[]) => {
                    console.log("Finished temp fetch");
                    console.log(temperatureRes);
                    setTemperature(temperatureRes);
                }).catch(err => setErrors(err)),
                applianceRes.then(res => res.json()).then((applianceRes: GanttChartData[]) => {
                    console.log("Finished appliance fetch");
                    console.log(applianceRes);
                    setAppliance(applianceRes);
                }).catch(err => setErrors(err)),
                doorRes.then(res => res.json()).then((doorRes: GanttChartData[]) => {
                    console.log("Finished door fetch");
                    console.log(doorRes);
                    setDoor(doorRes);
                }).catch(err => setErrors(err)),
                networkRes.then(res => res.json()).then((res: NetworkGraphData) => {
                    console.log(res);
                    setNetwork(res);
                }).catch(err => setErrors(err)),
                applianceLocRes.then(res => res.json()).then((res: any) => {
                    console.log(res);
                    setApplianceLocations(res);
                }).catch(err => setErrors(err))
            ]);
        }
        fetchData();
    }, [timeFrame]);

    return <GanttChart events={[props.target.startDateTime, props.target.inWindow]} live={false} setLive={() => { }} toggles={toggles} timeFrame={timeFrame} setTimeFrame={setTimeFrame} data={mobility} lightData={light} temperatureData={temperature} applianceData={appliance} doorData={door} network={network} applianceLocations={applianceLocations} width={1600} height={800} />
}

export function ContextModal(props: ContextModalProps) {
    return (
        <Modal style={{ overlay: { backgroundColor: 'rgba(200, 200, 200, 0.75)' }, content: { inset: '100px', border: 'none', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px inset' } }} isOpen={props.target != null} contentLabel="Event context" onRequestClose={() => { props.setTarget(null) }}>
            <h1>Medication accessed {props.target?.inWindow ? 'within window' : 'outside window'} - {moment(props.target?.startDateTime).format('hh:mm DD/MM/YY')}</h1>
            <h2>Surrounding activity</h2>
            {props.target != null && <GanttWrapper acsisID={props.acsis} target={props.target} />}
        </Modal>
    );
}

export function MedicationChart(props: MedicationChartProps) {

    const [modalTarget, setModalTarget] = useState<any | null>(null);

    const d3Container = useRef(null);

    useEffect(() => {

        if (props.data) {
            const svg = d3.select(d3Container.current);
            const margin = {left: 100, top: 10, right: 10, bottom: 20};
            const width = 1600;
            const height = 800;

            // TODO: Replace this with a better update method
            svg.selectAll('*').remove();

            let mainGroup = svg
                .append('g')
                .attr('transform', 'translate(200, 200)')

            let arc = d3.arc();

            let labelData = [24, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]
            let labels = mainGroup.selectAll('text.labels')
                .data(labelData)
                .enter()
                .append('text')
                .classed('labels', true)
                .attr('stroke', '#777777')
                .attr('x', (d, i) => Math.cos((Math.PI * -0.5) + (Math.PI * 2) * (i / 24)) * 180)
                .attr('y', (d, i) => Math.sin((Math.PI * -0.5) + (Math.PI * 2) * (i / 24)) * 180)
                .attr('text-anchor', 'middle')
                .text(d => d)
                .style("opacity", 0.8);

            if (props.windowData) {
                let windows = props.windowData.map(d => {

                    // Get the start and end times as a percentage of the day
                    let start = moment.duration(d.startTime).asSeconds() / 86400;
                    let end = moment.duration(d.endTime).asSeconds() / 86400;

                    return arc({
                        innerRadius: 120,
                        outerRadius: 160,
                        startAngle: (Math.PI * 2) * start,
                        endAngle: (Math.PI * 2) * end
                    });

                });

                mainGroup.append('circle')
                    .attr('r', '120')
                    .style('stroke-dasharray', '5, 3')
                    .style('fill', 'none')
                    .style('stroke', '#777777')

                mainGroup.append('circle')
                    .attr('r', '160')
                    .style('stroke-dasharray', '5, 3')
                    .style('fill', 'none')
                    .style('stroke', '#777777')
                    //.style('stroke-width', '2px');

                let windowPaths = mainGroup.selectAll('path.window')
                    .data(windows)
                    .enter()
                    .append('path')
                    .classed('window', true)
                    .attr('d', d => d)
                    .attr('fill', '#80b918')
                    .attr('stroke', '#777777')
                    .style("opacity", 0.5);

                
            }

            if (props.data) {
                let tooltip = d3.select("body")
                    .append("div")
                    .style("position", "absolute")
                    .style("visibility", "hidden")
                    .style("padding", "5px")
                    .style("border-radius", "3px")
                    .style("background-color", '#fff')
                    .classed("shadow", true)
                    .text("");

                let medicationCircles = mainGroup.selectAll('circle.data-point')
                    .data(props.data)
                    .enter()
                    .append('circle')
                    .classed('data-point', true)
                    .attr('r', '10')
                    .attr('fill', d => d.inWindow ? '#80b918' : '#faa307')
                    .attr('stroke', '#777777')
                    .attr('cx', d => Math.cos((Math.PI * -0.5) + (Math.PI * 2) * (moment.duration(d.time).asSeconds() / 86400)) * 140)
                    .attr('cy', d => Math.sin((Math.PI * -0.5) + (Math.PI * 2) * (moment.duration(d.time).asSeconds() / 86400)) * 140)
                    .style("opacity", 0.8)
                    .on("mouseover", function (event, d) { tooltip.style("visibility", "visible"); })
                    .on("mousemove", function (event, d) {
                        tooltip.style("left", (event.pageX + 20) + "px")
                            .style("top", (event.pageY + 20) + "px")
                            .text(`Medication box opened ${d.inWindow ? 'within' : 'outside'} window at ${ d.time } on ${ d.date }`);
                    })
                    .on("mouseout", function (event, d) { tooltip.style("visibility", "hidden"); })
                    .on("click", function (event, d) { setModalTarget(d); });
                    
            }
        }

    }, [props.data, d3Container.current]);

    if (props.data == null) {
        return (
            <div className="gantt-chart-info">
                <p>Loading...</p>
            </div>
        );
    } else if (props.data == []) {
        return (
            <div className="gantt-chart-info">
                <p>No data found for the provided timeframe.</p>
            </div>
        );
    } else {
        return (
            <>
                <svg
                    className="gantt-chart"
                    width="400"
                    height="400"
                    ref={d3Container}
                />
                <ContextModal setTarget={setModalTarget} target={modalTarget} acsis={props.acsis}/>
            </>
        );
    }
}

export default MedicationChart;